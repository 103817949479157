import React, {useEffect, useState} from 'react';
import {Row, Table} from 'react-bootstrap';
import {Education_Level_Enum, Job_Rating_Reason_Enum, useDislikeReasonQuery} from '../GraphQL/GeneratedGraphQLQueries';

export interface IUserInfo {
  id: number;
  name: string;
  surname: string;
  email: string;
  education?: Education_Level_Enum | null;
  required_salary?: number | null;
}

interface IUserTableProps {
  header: string;
  users: IUserInfo[],
  disliked?: boolean,
  jobID?: number,
}


const reasonTrans = (reason: Job_Rating_Reason_Enum) => {
  switch (reason) {
  case Job_Rating_Reason_Enum.Job:
    return 'Job type';
  case Job_Rating_Reason_Enum.Location:
    return 'Job location';
  case Job_Rating_Reason_Enum.Salary:
    return 'Salary';
  }
  return 'Error: Unknown enum value';
};

export const UserTable = ({users, header, disliked, jobID}: IUserTableProps) => {
  const [reasons, setReasons] = useState<Map<number, string>>(new Map());
  const {data: reasonsData, loading: reasonsLoading, error: reasonsError} = useDislikeReasonQuery({variables: {jobID: jobID, profileIDs: users.map(user => user.id)}});

  useEffect(() => {
    const reasonsMap = new Map();
    for (const {profileID, reason} of reasonsData?.job_rating ?? []) {
      if (reasonsMap.has(profileID)) {
        reasonsMap.set(profileID, `${reasonsMap.get(profileID)}, ${reasonTrans(reason)}`);
      } else {
        reasonsMap.set(profileID, reasonTrans(reason));
      }
    }
    setReasons(reasonsMap);
  }, [reasonsData]);

  return (
    <Row className="mt-5">
      <Row>
        <h2>{header + ` (${users.length})`}</h2>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Education</th>
            <th>Required salary</th>
            {disliked &&
              <th>Reason</th>
            }
          </tr>
        </thead>
        <tbody>
          {users.map((user) =>
            <tr key={user.email}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.surname}</td>
              <td>{user.email}</td>
              <td>{user.education}</td>
              <td>{user.required_salary}</td>
              {disliked &&
                <td>{reasons.get(user.id) || 'No feedback gathered'}</td>
              }
            </tr>)}
        </tbody>
      </Table>
    </Row>
  );
};
