import React, {useEffect} from 'react';
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  useGetCvsQuery
} from '../GraphQL/GeneratedGraphQLQueries';
import {Button, Spinner} from 'react-bootstrap';
import {saveAs} from 'file-saver';
import axios from 'axios';
import {AccessTokenKey, RefreshTokenKey} from '../Constants/StorageKeys';
import apolloClient from '../GraphQL/apolloSetup';

interface IDownloadButtonProps {
    id: number;
}

export const DownloadButton = (props: IDownloadButtonProps) => {
  const {data, loading, error} = useGetCvsQuery({variables: {
    id: props.id
  }});

  useEffect(() => {
    if(error){
      console.error(error);
      alert(error);
    }
  }, [error]);

  async function downloadCVZip() {
    try{
      const { data } = await getCVs();
      const blob = new Blob([data], { type: 'application/zip' });
      saveAs(blob, 'cvs.zip');
    } catch (e) {
      console.error(e);
      alert(e);
    }
  }

  async function getCVs() {
    const response = await apolloClient
      .mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
        mutation: RefreshTokenDocument,
        variables: {
          refreshToken: localStorage.getItem(RefreshTokenKey) ?? ''
        }
      });
    if (response.data == null || response.data?.refreshToken?.accessToken == null) {
      localStorage.removeItem(RefreshTokenKey);
      window.location.href = '/login';
    } else {
      localStorage.setItem(AccessTokenKey, response?.data?.refreshToken?.accessToken ?? '');
    }

    return axios.post(process.env.REACT_APP_STORAGE_GQL_API!, {
      keys: data?.user_job_relation.map(item => ({
        key: item.Profile.LatestCv?.file_uri ?? '',
        name: [item.Profile.surname, item.Profile.name, item.Profile.id].filter(_item => !!_item).join('_')
      })).filter(item => !!item.key) ?? [],
    },{
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem(AccessTokenKey)}`
      },
      responseType: 'arraybuffer',
    });
  }

  return (
    <Button disabled={
      loading ||
      data?.user_job_relation.map(item => item.Profile.LatestCv?.file_uri).filter(item => !!item).length === 0
    } onClick={downloadCVZip}>{loading ? <Spinner animation={'border'}/> : 'Download CVs'}</Button>
  );
};