import React, {createContext, useEffect} from 'react';
import {I18nProvider} from '@lingui/react';
import {i18n} from '@lingui/core';
import catalogEn from '../../locales/en/messages';
import catalogCs from '../../locales/cs/messages';

i18n.load({
  en: catalogEn.messages,
  cs: catalogCs.messages
});
i18n.activate('en');

export type SupportedLanguagesType = 'cs' | 'en'

interface ILanguageContext {
  setLanguage: (language: SupportedLanguagesType) => void
}

export const LanguageContext = createContext<ILanguageContext>({
  setLanguage: () => null
});

interface ILanguageProviderProps {
  children?: React.ReactNode
}

export const LanguageContextProvider: React.FC = (props: ILanguageProviderProps) => {
  const LanguageKey = 'CurrentLanguage';

  useEffect(() => {
    const language = localStorage.getItem(LanguageKey);
    if (language){
      i18n.activate(language);
    }
  }, []);

  return (
    <LanguageContext.Provider value={
      {
        setLanguage: (language: SupportedLanguagesType) => {
          i18n.activate(language);
          localStorage.setItem(LanguageKey, language);
        }
      }
    }>
      <I18nProvider i18n={i18n}>
        {props.children}
      </I18nProvider>
    </LanguageContext.Provider>
  );
};
