import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Alert, Button, Form, Spinner} from 'react-bootstrap';
import {useHistory, useLocation, Redirect} from 'react-router-dom';
import {AuthContext} from '../Providers/AuthContextProvider';

export const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  const authContext = useContext(AuthContext);

  const history = useHistory();
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    // TODO extract cases to enum or something
    switch (params.get('reason')) {
    case 'unauthorized':
      setDisplayAlert(true);
      break;
    }
  }, [params]);

  if (authContext?.loggedIn) {
    return <Redirect to="/auth/offers"/>;
  }

  const onLoginClick = async () => {
    try {
      setLoading(true);
      await authContext?.login(email, password);
      history.push('/auth/offers');
    } catch (e) {
      // TODO determine if cause of error really were invalid credentials
      setInvalidCredentials(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center login_card_container" >
      {displayAlert &&
            <Alert variant="danger">
                Please log in to access this!
            </Alert>}
      <div className={'p-3 border border-2 rounded-3 vh-30'}>
        <Form noValidate>
          <Form.Group controlId="formBasicEmail" className={'p-1'}>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              placeholder="Enter email"
              onChange={(e) => {
                setInvalidCredentials(false);
                setEmail(e.target.value);
              }}
              isInvalid={invalidCredentials}
            />
            <Form.Control.Feedback type="invalid">
                        Invalid username or password
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className={'p-1'}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setInvalidCredentials(false);
                setPassword(e.target.value);
              }}
              isInvalid={invalidCredentials}
            />
            <Form.Control.Feedback type="invalid">
                        Invalid username or password
            </Form.Control.Feedback>
          </Form.Group>
          <div className={'d-flex justify-content-center p-3 pb-1'}>
            <Button variant="primary" type="button" onClick={onLoginClick} disabled={loading}>
              {loading ? <Spinner className="mx-3" animation="border" variant="light" size="sm"/> : 'Login'}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
