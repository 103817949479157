import {ApolloClient, ApolloLink, fromPromise, HttpLink, InMemoryCache, NormalizedCacheObject} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {RefreshTokenDocument, RefreshTokenMutation, RefreshTokenMutationVariables} from './GeneratedGraphQLQueries';
import {LogMessage} from '../Utils/Logger';
import {AccessTokenKey, RefreshTokenKey} from '../Constants/StorageKeys';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

let isRefreshing = false;
let pendingRequests: Array<() => void> = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const getNewToken = () => {
  //delete now invalid token
  localStorage.removeItem(AccessTokenKey);
  if (apolloClient == null) {
    throw new Error('ApolloClient uninitialized!');
  }
  return apolloClient
    .mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
      mutation: RefreshTokenDocument,
      variables: {
        refreshToken: localStorage.getItem(RefreshTokenKey) ?? ''
      }
    })
    .then((response) => {
      // extract your accessToken from your response data and return it
      //TODO this is not that great error handling
      if (response.data == null || response.data?.refreshToken?.accessToken == null) {
        //todo extract to constant or better compute at runtime
        //todo do something reasonable add query param or smthing so it would show up on login page as "session expired relog or smthing"
        localStorage.removeItem(RefreshTokenKey);
        window.location.href = '/login';
      } else {
        localStorage.setItem(AccessTokenKey, response?.data?.refreshToken?.accessToken ?? '');
        return response.data?.refreshToken?.accessToken ?? null;
      }
    });
};

const errorLink = onError(
  ({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      let forward$;
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) { //Authentication Failure: Your must be signed in and <something> not found in query root indicate at auth failure
        case 'invalid-jwt':
          forward$ = null;
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              getNewToken()
                .then(accessToken => {
                  resolvePendingRequests();
                  return accessToken;
                })
                .catch(e => {
                  console.log('Couldnt refresh token ', e);
                  pendingRequests = [];
                  //todo merge code
                  //todo do something reasonable add query param or smthing so it would show up on login page as "session expired relog or smthing"
                  localStorage.removeItem(RefreshTokenKey);
                  // todo will this prevent finaly from executing?
                  window.location.href = '/login';
                  return;
                })
                .finally(() => {
                  isRefreshing = false;
                })
            ).filter(value => Boolean(value));
          }
          forward$ = fromPromise(
            new Promise<void>(resolve => {
              pendingRequests.push(() => resolve());
            })
          );


          return forward$.flatMap(() => forward(operation));
        }
      }
    }
  }
);

const roundTripLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  operation.setContext({ start: new Date() });

  return forward(operation).map((data) => {
    // Called after server responds
    const time = +new Date() - operation.getContext().start;
    LogMessage(`Operation ${operation.operationName} took ${time/1000}s to complete`);
    return data;
  });
});

const authLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  // TODO IN WHOLE FILE OPTIMIZE STORAGE ACCESS - save localstorage val in variable
  if (localStorage.getItem(AccessTokenKey) !== null && localStorage.getItem(AccessTokenKey) !== '') {
    const oldHeaders = operation.getContext().headers;
    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: `Bearer ${localStorage.getItem(AccessTokenKey)}`
      }
    });
  }

  return forward(operation);
});

const httpLink = new HttpLink({uri: process.env.REACT_APP_GQL_API});

apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, roundTripLink ,authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default apolloClient as ApolloClient<NormalizedCacheObject>;
