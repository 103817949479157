import React from 'react';
import {Button, Spinner} from 'react-bootstrap';
import {Education_Level_Enum, useGetUsersQuery} from '../GraphQL/GeneratedGraphQLQueries';
import * as XLSX from 'xlsx';
import moment from 'moment';
import {RegionsMap} from '../Constants/RegionsMap';

export const Users = () => {
  const {data, loading, error} = useGetUsersQuery();

  const mapEducation = (education: Education_Level_Enum) => {
    switch (education) {
    case Education_Level_Enum.Zakladna:
      return 'Základná škola';
    case Education_Level_Enum.Stredna:
      return 'Stredná škola';
    case Education_Level_Enum.VysokaPrviStupen:
      return 'Vysoká škola, Prvý stupeň';
    case Education_Level_Enum.VysokaDruhyStupen:
      return 'Vysoká škola, Druhý stupeň';
    }
  };

  const downloadXlsx = () => {
    const sheetData = data?.profile.map(dataEntry => {
      return {
        'Meno': dataEntry.name,
        'Priezvisko': dataEntry.surname,
        'Mail': dataEntry.email,
        'Dátum registrácie': moment(dataEntry.created_at).format('D.M.YYYY'),
        'Požadovaný plat': dataEntry.required_salary,
        'Pracovné oblasti': dataEntry.JobPreferences.reduce((acc, curr, currNumber) => {
          return acc + (currNumber === 0 ? '' : ', ') + curr.JobType.job_field;
        }, ''),
        'Okresy': dataEntry.JobLocations.reduce((acc, curr, currNumber) => {
          return acc + (currNumber === 0 ? '' : ', ') + RegionsMap.get(curr.location);
        }, ''),
        'Vzdelanie': mapEducation(dataEntry.education),
        'Má CV': (dataEntry.LatestCv?.original_file_name ? 'Áno' : 'Nie')
      };
    }) ?? [];

    const workbook = XLSX.utils.book_new();
    const filename = 'Users';
    const dataSheet = XLSX.utils.json_to_sheet(sheetData, {header: ['Meno', 'Priezvisko', 'Mail', 'Dátum registrácie', 'Požadovaný plat', 'Pracovné oblasti', 'Okresy', 'Vzdelanie', 'Má CV']});
    XLSX.utils.book_append_sheet(workbook, dataSheet, filename.replace('/', ''));
    XLSX.writeFile(workbook,  'users.xlsx');
  };

  return <div>
    <Button className={'m-5'} onClick={downloadXlsx}>{loading ? <Spinner animation={'border'}/> : <div>Download user data</div>}</Button>
  </div>;
};
