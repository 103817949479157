import React, {useEffect, useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables, SignUpDocument,
  SignUpMutation, SignUpMutationVariables
} from '../../GraphQL/GeneratedGraphQLQueries';
import {AccessTokenKey, RefreshTokenKey} from '../../Constants/StorageKeys';

export type AuthContextType = {
    loggedIn: boolean
    login: (email: string, password: string) => Promise<void>
    register: (email: string, password: string) => Promise<void>
    logout: () => Promise<void>
} | null

export const AuthContext = React.createContext<AuthContextType>(null);

interface IAuthContextProviderProps {
    children?: React.ReactNode;
}

export const AuthContextProvider: React.FC = ({children}: IAuthContextProviderProps) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [initializing, setInitializing] = useState<boolean>(true);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const refreshToken = localStorage.getItem(RefreshTokenKey);
    if (refreshToken) {
      setLoggedIn(true);
    }
    setInitializing(false);
  }, []);

  // TODO maybe add spinner but this should never take more than a few milliseconds
  if (initializing){
    return null;
  }


  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        login: async (email: string, password: string) => {
          const response = await apolloClient?.mutate<LoginMutation, LoginMutationVariables>({
            mutation: LoginDocument,
            variables: {
              email,
              password
            }
          });
          if (!response.data?.login) {
            throw Error('No data');
          }
          localStorage.setItem(RefreshTokenKey, response.data.login.refreshToken);
          localStorage.setItem(AccessTokenKey, response.data.login.accessToken);
          setLoggedIn(true);
        },
        register: async (email: string, password: string) => {
          const response = await apolloClient?.mutate<SignUpMutation, SignUpMutationVariables>({
            mutation: SignUpDocument,
            variables: {
              email,
              password
            }
          });
          if (!response.data?.signUp) {
            throw Error('No data');
          }
          // TODO something is wrong with  refreshToken type here
          localStorage.setItem(RefreshTokenKey, response.data.signUp.refreshToken);
          localStorage.setItem(AccessTokenKey, response.data.signUp.accessToken);
          // TODO something is wrong with the token type here
          setLoggedIn(true);
        },
        logout: async () => {
          localStorage.removeItem(RefreshTokenKey);
          localStorage.removeItem(AccessTokenKey);
          window.location.href = '/login';
        }
      }}>
      {children}
    </AuthContext.Provider>);
};
