import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ApolloProvider} from '@apollo/client';
import apolloClient from './GraphQL/apolloSetup';
import {AuthContextProvider} from './Components/Providers/AuthContextProvider';
import {LanguageContextProvider} from './Components/Providers/LanguageContextProvider';
import './styles.css';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <LanguageContextProvider>
          <App/>
        </LanguageContextProvider>
      </AuthContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
