import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type GetAllUserDataOutput = {
  __typename?: 'GetAllUserDataOutput';
  email: Scalars['String'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type SampleActionOutput = {
  __typename?: 'SampleActionOutput';
  result: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type AuthResponse = {
  __typename?: 'authResponse';
  accessToken: Scalars['String'];
  id: Scalars['Int'];
  refreshToken: Scalars['String'];
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "cvs" */
export type Cvs = {
  __typename?: 'cvs';
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "cvs" */
export type Cvs_Aggregate = {
  __typename?: 'cvs_aggregate';
  aggregate?: Maybe<Cvs_Aggregate_Fields>;
  nodes: Array<Cvs>;
};

/** aggregate fields of "cvs" */
export type Cvs_Aggregate_Fields = {
  __typename?: 'cvs_aggregate_fields';
  avg?: Maybe<Cvs_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cvs_Max_Fields>;
  min?: Maybe<Cvs_Min_Fields>;
  stddev?: Maybe<Cvs_Stddev_Fields>;
  stddev_pop?: Maybe<Cvs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cvs_Stddev_Samp_Fields>;
  sum?: Maybe<Cvs_Sum_Fields>;
  var_pop?: Maybe<Cvs_Var_Pop_Fields>;
  var_samp?: Maybe<Cvs_Var_Samp_Fields>;
  variance?: Maybe<Cvs_Variance_Fields>;
};


/** aggregate fields of "cvs" */
export type Cvs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cvs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cvs" */
export type Cvs_Aggregate_Order_By = {
  avg?: Maybe<Cvs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cvs_Max_Order_By>;
  min?: Maybe<Cvs_Min_Order_By>;
  stddev?: Maybe<Cvs_Stddev_Order_By>;
  stddev_pop?: Maybe<Cvs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cvs_Stddev_Samp_Order_By>;
  sum?: Maybe<Cvs_Sum_Order_By>;
  var_pop?: Maybe<Cvs_Var_Pop_Order_By>;
  var_samp?: Maybe<Cvs_Var_Samp_Order_By>;
  variance?: Maybe<Cvs_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cvs_Avg_Fields = {
  __typename?: 'cvs_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cvs" */
export type Cvs_Avg_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cvs". All fields are combined with a logical 'AND'. */
export type Cvs_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cvs_Bool_Exp>>>;
  _not?: Maybe<Cvs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cvs_Bool_Exp>>>;
  file_uri?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  original_file_name?: Maybe<String_Comparison_Exp>;
  profileID?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cvs_Max_Fields = {
  __typename?: 'cvs_max_fields';
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "cvs" */
export type Cvs_Max_Order_By = {
  file_uri?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_file_name?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cvs_Min_Fields = {
  __typename?: 'cvs_min_fields';
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "cvs" */
export type Cvs_Min_Order_By = {
  file_uri?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_file_name?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** ordering options when selecting data from "cvs" */
export type Cvs_Order_By = {
  file_uri?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_file_name?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** select columns of table "cvs" */
export enum Cvs_Select_Column {
  /** column name */
  FileUri = 'file_uri',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalFileName = 'original_file_name',
  /** column name */
  ProfileId = 'profileID'
}

/** aggregate stddev on columns */
export type Cvs_Stddev_Fields = {
  __typename?: 'cvs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cvs" */
export type Cvs_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cvs_Stddev_Pop_Fields = {
  __typename?: 'cvs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cvs" */
export type Cvs_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cvs_Stddev_Samp_Fields = {
  __typename?: 'cvs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cvs" */
export type Cvs_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cvs_Sum_Fields = {
  __typename?: 'cvs_sum_fields';
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cvs" */
export type Cvs_Sum_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cvs_Var_Pop_Fields = {
  __typename?: 'cvs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cvs" */
export type Cvs_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cvs_Var_Samp_Fields = {
  __typename?: 'cvs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cvs" */
export type Cvs_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cvs_Variance_Fields = {
  __typename?: 'cvs_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cvs" */
export type Cvs_Variance_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** columns and relationships of "education_level" */
export type Education_Level = {
  __typename?: 'education_level';
  value: Scalars['String'];
};

/** aggregated selection of "education_level" */
export type Education_Level_Aggregate = {
  __typename?: 'education_level_aggregate';
  aggregate?: Maybe<Education_Level_Aggregate_Fields>;
  nodes: Array<Education_Level>;
};

/** aggregate fields of "education_level" */
export type Education_Level_Aggregate_Fields = {
  __typename?: 'education_level_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Education_Level_Max_Fields>;
  min?: Maybe<Education_Level_Min_Fields>;
};


/** aggregate fields of "education_level" */
export type Education_Level_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Education_Level_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "education_level" */
export type Education_Level_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Education_Level_Max_Order_By>;
  min?: Maybe<Education_Level_Min_Order_By>;
};

/** input type for inserting array relation for remote table "education_level" */
export type Education_Level_Arr_Rel_Insert_Input = {
  data: Array<Education_Level_Insert_Input>;
  on_conflict?: Maybe<Education_Level_On_Conflict>;
};

/** Boolean expression to filter rows from the table "education_level". All fields are combined with a logical 'AND'. */
export type Education_Level_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Education_Level_Bool_Exp>>>;
  _not?: Maybe<Education_Level_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Education_Level_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "education_level" */
export enum Education_Level_Constraint {
  /** unique or primary key constraint */
  EducationLevelPkey = 'education_level_pkey'
}

export enum Education_Level_Enum {
  Stredna = 'STREDNA',
  VysokaDruhyStupen = 'VYSOKA_DRUHY_STUPEN',
  VysokaPrviStupen = 'VYSOKA_PRVI_STUPEN',
  Zakladna = 'ZAKLADNA'
}

/** expression to compare columns of type education_level_enum. All fields are combined with logical 'AND'. */
export type Education_Level_Enum_Comparison_Exp = {
  _eq?: Maybe<Education_Level_Enum>;
  _in?: Maybe<Array<Education_Level_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Education_Level_Enum>;
  _nin?: Maybe<Array<Education_Level_Enum>>;
};

/** input type for inserting data into table "education_level" */
export type Education_Level_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Education_Level_Max_Fields = {
  __typename?: 'education_level_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "education_level" */
export type Education_Level_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Education_Level_Min_Fields = {
  __typename?: 'education_level_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "education_level" */
export type Education_Level_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "education_level" */
export type Education_Level_Mutation_Response = {
  __typename?: 'education_level_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Education_Level>;
};

/** input type for inserting object relation for remote table "education_level" */
export type Education_Level_Obj_Rel_Insert_Input = {
  data: Education_Level_Insert_Input;
  on_conflict?: Maybe<Education_Level_On_Conflict>;
};

/** on conflict condition type for table "education_level" */
export type Education_Level_On_Conflict = {
  constraint: Education_Level_Constraint;
  update_columns: Array<Education_Level_Update_Column>;
  where?: Maybe<Education_Level_Bool_Exp>;
};

/** ordering options when selecting data from "education_level" */
export type Education_Level_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "education_level" */
export type Education_Level_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "education_level" */
export enum Education_Level_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "education_level" */
export type Education_Level_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "education_level" */
export enum Education_Level_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "employer" */
export type Employer = {
  __typename?: 'employer';
  /** An object relationship */
  EmployerCounts?: Maybe<Employer_Active_Counts>;
  /** An array relationship */
  Jobs: Array<Job_Offer>;
  /** An aggregated array relationship */
  Jobs_aggregate: Job_Offer_Aggregate;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  ico: Scalars['bigint'];
  id: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
};


/** columns and relationships of "employer" */
export type EmployerJobsArgs = {
  distinct_on?: Maybe<Array<Job_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_Order_By>>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_Order_By>>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};

/** columns and relationships of "employer_active_counts" */
export type Employer_Active_Counts = {
  __typename?: 'employer_active_counts';
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "employer_active_counts" */
export type Employer_Active_Counts_Aggregate = {
  __typename?: 'employer_active_counts_aggregate';
  aggregate?: Maybe<Employer_Active_Counts_Aggregate_Fields>;
  nodes: Array<Employer_Active_Counts>;
};

/** aggregate fields of "employer_active_counts" */
export type Employer_Active_Counts_Aggregate_Fields = {
  __typename?: 'employer_active_counts_aggregate_fields';
  avg?: Maybe<Employer_Active_Counts_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Employer_Active_Counts_Max_Fields>;
  min?: Maybe<Employer_Active_Counts_Min_Fields>;
  stddev?: Maybe<Employer_Active_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Employer_Active_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employer_Active_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Employer_Active_Counts_Sum_Fields>;
  var_pop?: Maybe<Employer_Active_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Employer_Active_Counts_Var_Samp_Fields>;
  variance?: Maybe<Employer_Active_Counts_Variance_Fields>;
};


/** aggregate fields of "employer_active_counts" */
export type Employer_Active_Counts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Employer_Active_Counts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employer_active_counts" */
export type Employer_Active_Counts_Aggregate_Order_By = {
  avg?: Maybe<Employer_Active_Counts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Employer_Active_Counts_Max_Order_By>;
  min?: Maybe<Employer_Active_Counts_Min_Order_By>;
  stddev?: Maybe<Employer_Active_Counts_Stddev_Order_By>;
  stddev_pop?: Maybe<Employer_Active_Counts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Employer_Active_Counts_Stddev_Samp_Order_By>;
  sum?: Maybe<Employer_Active_Counts_Sum_Order_By>;
  var_pop?: Maybe<Employer_Active_Counts_Var_Pop_Order_By>;
  var_samp?: Maybe<Employer_Active_Counts_Var_Samp_Order_By>;
  variance?: Maybe<Employer_Active_Counts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employer_active_counts" */
export type Employer_Active_Counts_Arr_Rel_Insert_Input = {
  data: Array<Employer_Active_Counts_Insert_Input>;
};

/** aggregate avg on columns */
export type Employer_Active_Counts_Avg_Fields = {
  __typename?: 'employer_active_counts_avg_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Avg_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employer_active_counts". All fields are combined with a logical 'AND'. */
export type Employer_Active_Counts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Employer_Active_Counts_Bool_Exp>>>;
  _not?: Maybe<Employer_Active_Counts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Employer_Active_Counts_Bool_Exp>>>;
  active?: Maybe<Bigint_Comparison_Exp>;
  archived?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing integer column in table "employer_active_counts" */
export type Employer_Active_Counts_Inc_Input = {
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "employer_active_counts" */
export type Employer_Active_Counts_Insert_Input = {
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Employer_Active_Counts_Max_Fields = {
  __typename?: 'employer_active_counts_max_fields';
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Max_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Employer_Active_Counts_Min_Fields = {
  __typename?: 'employer_active_counts_min_fields';
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Min_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "employer_active_counts" */
export type Employer_Active_Counts_Mutation_Response = {
  __typename?: 'employer_active_counts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Employer_Active_Counts>;
};

/** input type for inserting object relation for remote table "employer_active_counts" */
export type Employer_Active_Counts_Obj_Rel_Insert_Input = {
  data: Employer_Active_Counts_Insert_Input;
};

/** ordering options when selecting data from "employer_active_counts" */
export type Employer_Active_Counts_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "employer_active_counts" */
export enum Employer_Active_Counts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Archived = 'archived',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "employer_active_counts" */
export type Employer_Active_Counts_Set_Input = {
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Employer_Active_Counts_Stddev_Fields = {
  __typename?: 'employer_active_counts_stddev_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Stddev_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employer_Active_Counts_Stddev_Pop_Fields = {
  __typename?: 'employer_active_counts_stddev_pop_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Stddev_Pop_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employer_Active_Counts_Stddev_Samp_Fields = {
  __typename?: 'employer_active_counts_stddev_samp_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Stddev_Samp_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Employer_Active_Counts_Sum_Fields = {
  __typename?: 'employer_active_counts_sum_fields';
  active?: Maybe<Scalars['bigint']>;
  archived?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Sum_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Employer_Active_Counts_Var_Pop_Fields = {
  __typename?: 'employer_active_counts_var_pop_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Var_Pop_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employer_Active_Counts_Var_Samp_Fields = {
  __typename?: 'employer_active_counts_var_samp_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Var_Samp_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Employer_Active_Counts_Variance_Fields = {
  __typename?: 'employer_active_counts_variance_fields';
  active?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employer_active_counts" */
export type Employer_Active_Counts_Variance_Order_By = {
  active?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregated selection of "employer" */
export type Employer_Aggregate = {
  __typename?: 'employer_aggregate';
  aggregate?: Maybe<Employer_Aggregate_Fields>;
  nodes: Array<Employer>;
};

/** aggregate fields of "employer" */
export type Employer_Aggregate_Fields = {
  __typename?: 'employer_aggregate_fields';
  avg?: Maybe<Employer_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Employer_Max_Fields>;
  min?: Maybe<Employer_Min_Fields>;
  stddev?: Maybe<Employer_Stddev_Fields>;
  stddev_pop?: Maybe<Employer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employer_Stddev_Samp_Fields>;
  sum?: Maybe<Employer_Sum_Fields>;
  var_pop?: Maybe<Employer_Var_Pop_Fields>;
  var_samp?: Maybe<Employer_Var_Samp_Fields>;
  variance?: Maybe<Employer_Variance_Fields>;
};


/** aggregate fields of "employer" */
export type Employer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Employer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employer" */
export type Employer_Aggregate_Order_By = {
  avg?: Maybe<Employer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Employer_Max_Order_By>;
  min?: Maybe<Employer_Min_Order_By>;
  stddev?: Maybe<Employer_Stddev_Order_By>;
  stddev_pop?: Maybe<Employer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Employer_Stddev_Samp_Order_By>;
  sum?: Maybe<Employer_Sum_Order_By>;
  var_pop?: Maybe<Employer_Var_Pop_Order_By>;
  var_samp?: Maybe<Employer_Var_Samp_Order_By>;
  variance?: Maybe<Employer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employer" */
export type Employer_Arr_Rel_Insert_Input = {
  data: Array<Employer_Insert_Input>;
  on_conflict?: Maybe<Employer_On_Conflict>;
};

/** aggregate avg on columns */
export type Employer_Avg_Fields = {
  __typename?: 'employer_avg_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employer" */
export type Employer_Avg_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employer". All fields are combined with a logical 'AND'. */
export type Employer_Bool_Exp = {
  EmployerCounts?: Maybe<Employer_Active_Counts_Bool_Exp>;
  Jobs?: Maybe<Job_Offer_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Employer_Bool_Exp>>>;
  _not?: Maybe<Employer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Employer_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  ico?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "employer" */
export enum Employer_Constraint {
  /** unique or primary key constraint */
  EmployerPkey = 'employer_pkey'
}

/** input type for incrementing integer column in table "employer" */
export type Employer_Inc_Input = {
  ico?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "employer" */
export type Employer_Insert_Input = {
  EmployerCounts?: Maybe<Employer_Active_Counts_Obj_Rel_Insert_Input>;
  Jobs?: Maybe<Job_Offer_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Employer_Max_Fields = {
  __typename?: 'employer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "employer" */
export type Employer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Employer_Min_Fields = {
  __typename?: 'employer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "employer" */
export type Employer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
};

/** response of any mutation on the table "employer" */
export type Employer_Mutation_Response = {
  __typename?: 'employer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Employer>;
};

/** input type for inserting object relation for remote table "employer" */
export type Employer_Obj_Rel_Insert_Input = {
  data: Employer_Insert_Input;
  on_conflict?: Maybe<Employer_On_Conflict>;
};

/** on conflict condition type for table "employer" */
export type Employer_On_Conflict = {
  constraint: Employer_Constraint;
  update_columns: Array<Employer_Update_Column>;
  where?: Maybe<Employer_Bool_Exp>;
};

/** ordering options when selecting data from "employer" */
export type Employer_Order_By = {
  EmployerCounts?: Maybe<Employer_Active_Counts_Order_By>;
  Jobs_aggregate?: Maybe<Job_Offer_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
};

/** primary key columns input for table: "employer" */
export type Employer_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "employer" */
export enum Employer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Ico = 'ico',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone'
}

/** input type for updating data in table "employer" */
export type Employer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Employer_Stddev_Fields = {
  __typename?: 'employer_stddev_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employer" */
export type Employer_Stddev_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employer_Stddev_Pop_Fields = {
  __typename?: 'employer_stddev_pop_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employer" */
export type Employer_Stddev_Pop_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employer_Stddev_Samp_Fields = {
  __typename?: 'employer_stddev_samp_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employer" */
export type Employer_Stddev_Samp_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Employer_Sum_Fields = {
  __typename?: 'employer_sum_fields';
  ico?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "employer" */
export type Employer_Sum_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "employer" */
export enum Employer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Ico = 'ico',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone'
}

/** aggregate var_pop on columns */
export type Employer_Var_Pop_Fields = {
  __typename?: 'employer_var_pop_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employer" */
export type Employer_Var_Pop_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employer_Var_Samp_Fields = {
  __typename?: 'employer_var_samp_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employer" */
export type Employer_Var_Samp_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Employer_Variance_Fields = {
  __typename?: 'employer_variance_fields';
  ico?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employer" */
export type Employer_Variance_Order_By = {
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "error_logs" */
export type Error_Logs = {
  __typename?: 'error_logs';
  buildnumber: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  message: Scalars['String'];
  model: Scalars['String'];
  os: Scalars['String'];
};

/** aggregated selection of "error_logs" */
export type Error_Logs_Aggregate = {
  __typename?: 'error_logs_aggregate';
  aggregate?: Maybe<Error_Logs_Aggregate_Fields>;
  nodes: Array<Error_Logs>;
};

/** aggregate fields of "error_logs" */
export type Error_Logs_Aggregate_Fields = {
  __typename?: 'error_logs_aggregate_fields';
  avg?: Maybe<Error_Logs_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Error_Logs_Max_Fields>;
  min?: Maybe<Error_Logs_Min_Fields>;
  stddev?: Maybe<Error_Logs_Stddev_Fields>;
  stddev_pop?: Maybe<Error_Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Error_Logs_Stddev_Samp_Fields>;
  sum?: Maybe<Error_Logs_Sum_Fields>;
  var_pop?: Maybe<Error_Logs_Var_Pop_Fields>;
  var_samp?: Maybe<Error_Logs_Var_Samp_Fields>;
  variance?: Maybe<Error_Logs_Variance_Fields>;
};


/** aggregate fields of "error_logs" */
export type Error_Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Error_Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "error_logs" */
export type Error_Logs_Aggregate_Order_By = {
  avg?: Maybe<Error_Logs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Error_Logs_Max_Order_By>;
  min?: Maybe<Error_Logs_Min_Order_By>;
  stddev?: Maybe<Error_Logs_Stddev_Order_By>;
  stddev_pop?: Maybe<Error_Logs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Error_Logs_Stddev_Samp_Order_By>;
  sum?: Maybe<Error_Logs_Sum_Order_By>;
  var_pop?: Maybe<Error_Logs_Var_Pop_Order_By>;
  var_samp?: Maybe<Error_Logs_Var_Samp_Order_By>;
  variance?: Maybe<Error_Logs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "error_logs" */
export type Error_Logs_Arr_Rel_Insert_Input = {
  data: Array<Error_Logs_Insert_Input>;
  on_conflict?: Maybe<Error_Logs_On_Conflict>;
};

/** aggregate avg on columns */
export type Error_Logs_Avg_Fields = {
  __typename?: 'error_logs_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "error_logs" */
export type Error_Logs_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "error_logs". All fields are combined with a logical 'AND'. */
export type Error_Logs_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Error_Logs_Bool_Exp>>>;
  _not?: Maybe<Error_Logs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Error_Logs_Bool_Exp>>>;
  buildnumber?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  os?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "error_logs" */
export enum Error_Logs_Constraint {
  /** unique or primary key constraint */
  ErrorLogsPkey = 'error_logs_pkey'
}

/** input type for incrementing integer column in table "error_logs" */
export type Error_Logs_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "error_logs" */
export type Error_Logs_Insert_Input = {
  buildnumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Error_Logs_Max_Fields = {
  __typename?: 'error_logs_max_fields';
  buildnumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "error_logs" */
export type Error_Logs_Max_Order_By = {
  buildnumber?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  os?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Error_Logs_Min_Fields = {
  __typename?: 'error_logs_min_fields';
  buildnumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "error_logs" */
export type Error_Logs_Min_Order_By = {
  buildnumber?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  os?: Maybe<Order_By>;
};

/** response of any mutation on the table "error_logs" */
export type Error_Logs_Mutation_Response = {
  __typename?: 'error_logs_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Error_Logs>;
};

/** input type for inserting object relation for remote table "error_logs" */
export type Error_Logs_Obj_Rel_Insert_Input = {
  data: Error_Logs_Insert_Input;
  on_conflict?: Maybe<Error_Logs_On_Conflict>;
};

/** on conflict condition type for table "error_logs" */
export type Error_Logs_On_Conflict = {
  constraint: Error_Logs_Constraint;
  update_columns: Array<Error_Logs_Update_Column>;
  where?: Maybe<Error_Logs_Bool_Exp>;
};

/** ordering options when selecting data from "error_logs" */
export type Error_Logs_Order_By = {
  buildnumber?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  os?: Maybe<Order_By>;
};

/** primary key columns input for table: "error_logs" */
export type Error_Logs_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "error_logs" */
export enum Error_Logs_Select_Column {
  /** column name */
  Buildnumber = 'buildnumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Model = 'model',
  /** column name */
  Os = 'os'
}

/** input type for updating data in table "error_logs" */
export type Error_Logs_Set_Input = {
  buildnumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Error_Logs_Stddev_Fields = {
  __typename?: 'error_logs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "error_logs" */
export type Error_Logs_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Error_Logs_Stddev_Pop_Fields = {
  __typename?: 'error_logs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "error_logs" */
export type Error_Logs_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Error_Logs_Stddev_Samp_Fields = {
  __typename?: 'error_logs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "error_logs" */
export type Error_Logs_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Error_Logs_Sum_Fields = {
  __typename?: 'error_logs_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "error_logs" */
export type Error_Logs_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "error_logs" */
export enum Error_Logs_Update_Column {
  /** column name */
  Buildnumber = 'buildnumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Model = 'model',
  /** column name */
  Os = 'os'
}

/** aggregate var_pop on columns */
export type Error_Logs_Var_Pop_Fields = {
  __typename?: 'error_logs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "error_logs" */
export type Error_Logs_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Error_Logs_Var_Samp_Fields = {
  __typename?: 'error_logs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "error_logs" */
export type Error_Logs_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Error_Logs_Variance_Fields = {
  __typename?: 'error_logs_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "error_logs" */
export type Error_Logs_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "fcm_token" */
export type Fcm_Token = {
  __typename?: 'fcm_token';
  fcm_token: Scalars['String'];
  fcm_token_id: Scalars['Int'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['Int'];
};

/** aggregated selection of "fcm_token" */
export type Fcm_Token_Aggregate = {
  __typename?: 'fcm_token_aggregate';
  aggregate?: Maybe<Fcm_Token_Aggregate_Fields>;
  nodes: Array<Fcm_Token>;
};

/** aggregate fields of "fcm_token" */
export type Fcm_Token_Aggregate_Fields = {
  __typename?: 'fcm_token_aggregate_fields';
  avg?: Maybe<Fcm_Token_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Fcm_Token_Max_Fields>;
  min?: Maybe<Fcm_Token_Min_Fields>;
  stddev?: Maybe<Fcm_Token_Stddev_Fields>;
  stddev_pop?: Maybe<Fcm_Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fcm_Token_Stddev_Samp_Fields>;
  sum?: Maybe<Fcm_Token_Sum_Fields>;
  var_pop?: Maybe<Fcm_Token_Var_Pop_Fields>;
  var_samp?: Maybe<Fcm_Token_Var_Samp_Fields>;
  variance?: Maybe<Fcm_Token_Variance_Fields>;
};


/** aggregate fields of "fcm_token" */
export type Fcm_Token_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fcm_Token_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fcm_token" */
export type Fcm_Token_Aggregate_Order_By = {
  avg?: Maybe<Fcm_Token_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fcm_Token_Max_Order_By>;
  min?: Maybe<Fcm_Token_Min_Order_By>;
  stddev?: Maybe<Fcm_Token_Stddev_Order_By>;
  stddev_pop?: Maybe<Fcm_Token_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fcm_Token_Stddev_Samp_Order_By>;
  sum?: Maybe<Fcm_Token_Sum_Order_By>;
  var_pop?: Maybe<Fcm_Token_Var_Pop_Order_By>;
  var_samp?: Maybe<Fcm_Token_Var_Samp_Order_By>;
  variance?: Maybe<Fcm_Token_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "fcm_token" */
export type Fcm_Token_Arr_Rel_Insert_Input = {
  data: Array<Fcm_Token_Insert_Input>;
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};

/** aggregate avg on columns */
export type Fcm_Token_Avg_Fields = {
  __typename?: 'fcm_token_avg_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fcm_token" */
export type Fcm_Token_Avg_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fcm_token". All fields are combined with a logical 'AND'. */
export type Fcm_Token_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Fcm_Token_Bool_Exp>>>;
  _not?: Maybe<Fcm_Token_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Fcm_Token_Bool_Exp>>>;
  fcm_token?: Maybe<String_Comparison_Exp>;
  fcm_token_id?: Maybe<Int_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "fcm_token" */
export enum Fcm_Token_Constraint {
  /** unique or primary key constraint */
  FcmTokenPkey = 'fcm_token_pkey',
  /** unique or primary key constraint */
  FcmTokenProfileIdFcmTokenKey = 'fcm_token_profile_id_fcm_token_key'
}

/** input type for incrementing integer column in table "fcm_token" */
export type Fcm_Token_Inc_Input = {
  fcm_token_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "fcm_token" */
export type Fcm_Token_Insert_Input = {
  fcm_token?: Maybe<Scalars['String']>;
  fcm_token_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Fcm_Token_Max_Fields = {
  __typename?: 'fcm_token_max_fields';
  fcm_token?: Maybe<Scalars['String']>;
  fcm_token_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "fcm_token" */
export type Fcm_Token_Max_Order_By = {
  fcm_token?: Maybe<Order_By>;
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fcm_Token_Min_Fields = {
  __typename?: 'fcm_token_min_fields';
  fcm_token?: Maybe<Scalars['String']>;
  fcm_token_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "fcm_token" */
export type Fcm_Token_Min_Order_By = {
  fcm_token?: Maybe<Order_By>;
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "fcm_token" */
export type Fcm_Token_Mutation_Response = {
  __typename?: 'fcm_token_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Fcm_Token>;
};

/** input type for inserting object relation for remote table "fcm_token" */
export type Fcm_Token_Obj_Rel_Insert_Input = {
  data: Fcm_Token_Insert_Input;
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};

/** on conflict condition type for table "fcm_token" */
export type Fcm_Token_On_Conflict = {
  constraint: Fcm_Token_Constraint;
  update_columns: Array<Fcm_Token_Update_Column>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};

/** ordering options when selecting data from "fcm_token" */
export type Fcm_Token_Order_By = {
  fcm_token?: Maybe<Order_By>;
  fcm_token_id?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "fcm_token" */
export type Fcm_Token_Pk_Columns_Input = {
  fcm_token_id: Scalars['Int'];
};

/** select columns of table "fcm_token" */
export enum Fcm_Token_Select_Column {
  /** column name */
  FcmToken = 'fcm_token',
  /** column name */
  FcmTokenId = 'fcm_token_id',
  /** column name */
  ProfileId = 'profile_id'
}

/** input type for updating data in table "fcm_token" */
export type Fcm_Token_Set_Input = {
  fcm_token?: Maybe<Scalars['String']>;
  fcm_token_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Fcm_Token_Stddev_Fields = {
  __typename?: 'fcm_token_stddev_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fcm_token" */
export type Fcm_Token_Stddev_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fcm_Token_Stddev_Pop_Fields = {
  __typename?: 'fcm_token_stddev_pop_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fcm_token" */
export type Fcm_Token_Stddev_Pop_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fcm_Token_Stddev_Samp_Fields = {
  __typename?: 'fcm_token_stddev_samp_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fcm_token" */
export type Fcm_Token_Stddev_Samp_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Fcm_Token_Sum_Fields = {
  __typename?: 'fcm_token_sum_fields';
  fcm_token_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "fcm_token" */
export type Fcm_Token_Sum_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "fcm_token" */
export enum Fcm_Token_Update_Column {
  /** column name */
  FcmToken = 'fcm_token',
  /** column name */
  FcmTokenId = 'fcm_token_id',
  /** column name */
  ProfileId = 'profile_id'
}

/** aggregate var_pop on columns */
export type Fcm_Token_Var_Pop_Fields = {
  __typename?: 'fcm_token_var_pop_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fcm_token" */
export type Fcm_Token_Var_Pop_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fcm_Token_Var_Samp_Fields = {
  __typename?: 'fcm_token_var_samp_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fcm_token" */
export type Fcm_Token_Var_Samp_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fcm_Token_Variance_Fields = {
  __typename?: 'fcm_token_variance_fields';
  fcm_token_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fcm_token" */
export type Fcm_Token_Variance_Order_By = {
  fcm_token_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** columns and relationships of "job_field" */
export type Job_Field = {
  __typename?: 'job_field';
  /** An object relationship */
  JobType: Job_Type;
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};

/** aggregated selection of "job_field" */
export type Job_Field_Aggregate = {
  __typename?: 'job_field_aggregate';
  aggregate?: Maybe<Job_Field_Aggregate_Fields>;
  nodes: Array<Job_Field>;
};

/** aggregate fields of "job_field" */
export type Job_Field_Aggregate_Fields = {
  __typename?: 'job_field_aggregate_fields';
  avg?: Maybe<Job_Field_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Field_Max_Fields>;
  min?: Maybe<Job_Field_Min_Fields>;
  stddev?: Maybe<Job_Field_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Field_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Field_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Field_Sum_Fields>;
  var_pop?: Maybe<Job_Field_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Field_Var_Samp_Fields>;
  variance?: Maybe<Job_Field_Variance_Fields>;
};


/** aggregate fields of "job_field" */
export type Job_Field_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Field_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_field" */
export type Job_Field_Aggregate_Order_By = {
  avg?: Maybe<Job_Field_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Field_Max_Order_By>;
  min?: Maybe<Job_Field_Min_Order_By>;
  stddev?: Maybe<Job_Field_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Field_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Field_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Field_Sum_Order_By>;
  var_pop?: Maybe<Job_Field_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Field_Var_Samp_Order_By>;
  variance?: Maybe<Job_Field_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_field" */
export type Job_Field_Arr_Rel_Insert_Input = {
  data: Array<Job_Field_Insert_Input>;
  on_conflict?: Maybe<Job_Field_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Field_Avg_Fields = {
  __typename?: 'job_field_avg_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_field" */
export type Job_Field_Avg_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_field". All fields are combined with a logical 'AND'. */
export type Job_Field_Bool_Exp = {
  JobType?: Maybe<Job_Type_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Job_Field_Bool_Exp>>>;
  _not?: Maybe<Job_Field_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Field_Bool_Exp>>>;
  jobID?: Maybe<Int_Comparison_Exp>;
  profileID?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_field" */
export enum Job_Field_Constraint {
  /** unique or primary key constraint */
  JobFieldPkey = 'job_field_pkey'
}

/** input type for incrementing integer column in table "job_field" */
export type Job_Field_Inc_Input = {
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_field" */
export type Job_Field_Insert_Input = {
  JobType?: Maybe<Job_Type_Obj_Rel_Insert_Input>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Job_Field_Max_Fields = {
  __typename?: 'job_field_max_fields';
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "job_field" */
export type Job_Field_Max_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Field_Min_Fields = {
  __typename?: 'job_field_min_fields';
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "job_field" */
export type Job_Field_Min_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_field" */
export type Job_Field_Mutation_Response = {
  __typename?: 'job_field_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job_Field>;
};

/** input type for inserting object relation for remote table "job_field" */
export type Job_Field_Obj_Rel_Insert_Input = {
  data: Job_Field_Insert_Input;
  on_conflict?: Maybe<Job_Field_On_Conflict>;
};

/** on conflict condition type for table "job_field" */
export type Job_Field_On_Conflict = {
  constraint: Job_Field_Constraint;
  update_columns: Array<Job_Field_Update_Column>;
  where?: Maybe<Job_Field_Bool_Exp>;
};

/** ordering options when selecting data from "job_field" */
export type Job_Field_Order_By = {
  JobType?: Maybe<Job_Type_Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** primary key columns input for table: "job_field" */
export type Job_Field_Pk_Columns_Input = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};

/** select columns of table "job_field" */
export enum Job_Field_Select_Column {
  /** column name */
  JobId = 'jobID',
  /** column name */
  ProfileId = 'profileID'
}

/** input type for updating data in table "job_field" */
export type Job_Field_Set_Input = {
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Job_Field_Stddev_Fields = {
  __typename?: 'job_field_stddev_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_field" */
export type Job_Field_Stddev_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Field_Stddev_Pop_Fields = {
  __typename?: 'job_field_stddev_pop_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_field" */
export type Job_Field_Stddev_Pop_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Field_Stddev_Samp_Fields = {
  __typename?: 'job_field_stddev_samp_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_field" */
export type Job_Field_Stddev_Samp_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Field_Sum_Fields = {
  __typename?: 'job_field_sum_fields';
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_field" */
export type Job_Field_Sum_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** update columns of table "job_field" */
export enum Job_Field_Update_Column {
  /** column name */
  JobId = 'jobID',
  /** column name */
  ProfileId = 'profileID'
}

/** aggregate var_pop on columns */
export type Job_Field_Var_Pop_Fields = {
  __typename?: 'job_field_var_pop_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_field" */
export type Job_Field_Var_Pop_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Field_Var_Samp_Fields = {
  __typename?: 'job_field_var_samp_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_field" */
export type Job_Field_Var_Samp_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Field_Variance_Fields = {
  __typename?: 'job_field_variance_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_field" */
export type Job_Field_Variance_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** columns and relationships of "job_location" */
export type Job_Location = {
  __typename?: 'job_location';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  location: Location_Name_Enum;
  /** An object relationship */
  profile: Profile;
  profileID: Scalars['Int'];
};

/** aggregated selection of "job_location" */
export type Job_Location_Aggregate = {
  __typename?: 'job_location_aggregate';
  aggregate?: Maybe<Job_Location_Aggregate_Fields>;
  nodes: Array<Job_Location>;
};

/** aggregate fields of "job_location" */
export type Job_Location_Aggregate_Fields = {
  __typename?: 'job_location_aggregate_fields';
  avg?: Maybe<Job_Location_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Location_Max_Fields>;
  min?: Maybe<Job_Location_Min_Fields>;
  stddev?: Maybe<Job_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Location_Sum_Fields>;
  var_pop?: Maybe<Job_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Location_Var_Samp_Fields>;
  variance?: Maybe<Job_Location_Variance_Fields>;
};


/** aggregate fields of "job_location" */
export type Job_Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Location_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_location" */
export type Job_Location_Aggregate_Order_By = {
  avg?: Maybe<Job_Location_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Location_Max_Order_By>;
  min?: Maybe<Job_Location_Min_Order_By>;
  stddev?: Maybe<Job_Location_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Location_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Location_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Location_Sum_Order_By>;
  var_pop?: Maybe<Job_Location_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Location_Var_Samp_Order_By>;
  variance?: Maybe<Job_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_location" */
export type Job_Location_Arr_Rel_Insert_Input = {
  data: Array<Job_Location_Insert_Input>;
  on_conflict?: Maybe<Job_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Location_Avg_Fields = {
  __typename?: 'job_location_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_location" */
export type Job_Location_Avg_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_location". All fields are combined with a logical 'AND'. */
export type Job_Location_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Job_Location_Bool_Exp>>>;
  _not?: Maybe<Job_Location_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Location_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Location_Name_Enum_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profileID?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_location" */
export enum Job_Location_Constraint {
  /** unique or primary key constraint */
  JobLocationPkey = 'job_location_pkey'
}

/** input type for incrementing integer column in table "job_location" */
export type Job_Location_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_location" */
export type Job_Location_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Location_Name_Enum>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Job_Location_Max_Fields = {
  __typename?: 'job_location_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "job_location" */
export type Job_Location_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Location_Min_Fields = {
  __typename?: 'job_location_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "job_location" */
export type Job_Location_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_location" */
export type Job_Location_Mutation_Response = {
  __typename?: 'job_location_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job_Location>;
};

/** input type for inserting object relation for remote table "job_location" */
export type Job_Location_Obj_Rel_Insert_Input = {
  data: Job_Location_Insert_Input;
  on_conflict?: Maybe<Job_Location_On_Conflict>;
};

/** on conflict condition type for table "job_location" */
export type Job_Location_On_Conflict = {
  constraint: Job_Location_Constraint;
  update_columns: Array<Job_Location_Update_Column>;
  where?: Maybe<Job_Location_Bool_Exp>;
};

/** ordering options when selecting data from "job_location" */
export type Job_Location_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profileID?: Maybe<Order_By>;
};

/** primary key columns input for table: "job_location" */
export type Job_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_location" */
export enum Job_Location_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ProfileId = 'profileID'
}

/** input type for updating data in table "job_location" */
export type Job_Location_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Location_Name_Enum>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Job_Location_Stddev_Fields = {
  __typename?: 'job_location_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_location" */
export type Job_Location_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Location_Stddev_Pop_Fields = {
  __typename?: 'job_location_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_location" */
export type Job_Location_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Location_Stddev_Samp_Fields = {
  __typename?: 'job_location_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_location" */
export type Job_Location_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Location_Sum_Fields = {
  __typename?: 'job_location_sum_fields';
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_location" */
export type Job_Location_Sum_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** update columns of table "job_location" */
export enum Job_Location_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ProfileId = 'profileID'
}

/** aggregate var_pop on columns */
export type Job_Location_Var_Pop_Fields = {
  __typename?: 'job_location_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_location" */
export type Job_Location_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Location_Var_Samp_Fields = {
  __typename?: 'job_location_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_location" */
export type Job_Location_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Location_Variance_Fields = {
  __typename?: 'job_location_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_location" */
export type Job_Location_Variance_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** columns and relationships of "job_offer" */
export type Job_Offer = {
  __typename?: 'job_offer';
  /** An object relationship */
  Employer: Employer;
  /** An object relationship */
  JobRelationStates?: Maybe<Job_Relation_States>;
  /** An array relationship */
  JobTypes: Array<Offered_Job>;
  /** An aggregated array relationship */
  JobTypes_aggregate: Offered_Job_Aggregate;
  /** An array relationship */
  ProfileRelations: Array<User_Job_Relation>;
  /** An aggregated array relationship */
  ProfileRelations_aggregate: User_Job_Relation_Aggregate;
  /** An array relationship */
  Ratings: Array<Job_Rating>;
  /** An aggregated array relationship */
  Ratings_aggregate: Job_Rating_Aggregate;
  additional_job_data: Scalars['jsonb'];
  city: Scalars['String'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  education: Education_Level_Enum;
  employerID: Scalars['Int'];
  employer_company_data?: Maybe<Scalars['jsonb']>;
  hidden: Scalars['Boolean'];
  id: Scalars['Int'];
  job_offer_details?: Maybe<Scalars['jsonb']>;
  location: Location_Name_Enum;
  original_offer_url: Scalars['String'];
  salary: Scalars['Int'];
  should_ask_for_feedback: Scalars['Boolean'];
  title: Scalars['String'];
};


/** columns and relationships of "job_offer" */
export type Job_OfferJobTypesArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferJobTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferProfileRelationsArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferProfileRelations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferRatingsArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferRatings_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferAdditional_Job_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferEmployer_Company_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "job_offer" */
export type Job_OfferJob_Offer_DetailsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "job_offer" */
export type Job_Offer_Aggregate = {
  __typename?: 'job_offer_aggregate';
  aggregate?: Maybe<Job_Offer_Aggregate_Fields>;
  nodes: Array<Job_Offer>;
};

/** aggregate fields of "job_offer" */
export type Job_Offer_Aggregate_Fields = {
  __typename?: 'job_offer_aggregate_fields';
  avg?: Maybe<Job_Offer_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Offer_Max_Fields>;
  min?: Maybe<Job_Offer_Min_Fields>;
  stddev?: Maybe<Job_Offer_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Offer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Offer_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Offer_Sum_Fields>;
  var_pop?: Maybe<Job_Offer_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Offer_Var_Samp_Fields>;
  variance?: Maybe<Job_Offer_Variance_Fields>;
};


/** aggregate fields of "job_offer" */
export type Job_Offer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Offer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_offer" */
export type Job_Offer_Aggregate_Order_By = {
  avg?: Maybe<Job_Offer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Offer_Max_Order_By>;
  min?: Maybe<Job_Offer_Min_Order_By>;
  stddev?: Maybe<Job_Offer_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Offer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Offer_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Offer_Sum_Order_By>;
  var_pop?: Maybe<Job_Offer_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Offer_Var_Samp_Order_By>;
  variance?: Maybe<Job_Offer_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Job_Offer_Append_Input = {
  additional_job_data?: Maybe<Scalars['jsonb']>;
  employer_company_data?: Maybe<Scalars['jsonb']>;
  job_offer_details?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "job_offer" */
export type Job_Offer_Arr_Rel_Insert_Input = {
  data: Array<Job_Offer_Insert_Input>;
  on_conflict?: Maybe<Job_Offer_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Offer_Avg_Fields = {
  __typename?: 'job_offer_avg_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_offer" */
export type Job_Offer_Avg_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_offer". All fields are combined with a logical 'AND'. */
export type Job_Offer_Bool_Exp = {
  Employer?: Maybe<Employer_Bool_Exp>;
  JobRelationStates?: Maybe<Job_Relation_States_Bool_Exp>;
  JobTypes?: Maybe<Offered_Job_Bool_Exp>;
  ProfileRelations?: Maybe<User_Job_Relation_Bool_Exp>;
  Ratings?: Maybe<Job_Rating_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Job_Offer_Bool_Exp>>>;
  _not?: Maybe<Job_Offer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Offer_Bool_Exp>>>;
  additional_job_data?: Maybe<Jsonb_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  education?: Maybe<Education_Level_Enum_Comparison_Exp>;
  employerID?: Maybe<Int_Comparison_Exp>;
  employer_company_data?: Maybe<Jsonb_Comparison_Exp>;
  hidden?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  job_offer_details?: Maybe<Jsonb_Comparison_Exp>;
  location?: Maybe<Location_Name_Enum_Comparison_Exp>;
  original_offer_url?: Maybe<String_Comparison_Exp>;
  salary?: Maybe<Int_Comparison_Exp>;
  should_ask_for_feedback?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_offer" */
export enum Job_Offer_Constraint {
  /** unique or primary key constraint */
  JobOfferOriginalOfferUrlKey = 'job_offer_original_offer_url_key',
  /** unique or primary key constraint */
  JobOfferPkey = 'job_offer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Job_Offer_Delete_At_Path_Input = {
  additional_job_data?: Maybe<Array<Maybe<Scalars['String']>>>;
  employer_company_data?: Maybe<Array<Maybe<Scalars['String']>>>;
  job_offer_details?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Job_Offer_Delete_Elem_Input = {
  additional_job_data?: Maybe<Scalars['Int']>;
  employer_company_data?: Maybe<Scalars['Int']>;
  job_offer_details?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Job_Offer_Delete_Key_Input = {
  additional_job_data?: Maybe<Scalars['String']>;
  employer_company_data?: Maybe<Scalars['String']>;
  job_offer_details?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "job_offer" */
export type Job_Offer_Inc_Input = {
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_offer" */
export type Job_Offer_Insert_Input = {
  Employer?: Maybe<Employer_Obj_Rel_Insert_Input>;
  JobTypes?: Maybe<Offered_Job_Arr_Rel_Insert_Input>;
  ProfileRelations?: Maybe<User_Job_Relation_Arr_Rel_Insert_Input>;
  Ratings?: Maybe<Job_Rating_Arr_Rel_Insert_Input>;
  additional_job_data?: Maybe<Scalars['jsonb']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Education_Level_Enum>;
  employerID?: Maybe<Scalars['Int']>;
  employer_company_data?: Maybe<Scalars['jsonb']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  job_offer_details?: Maybe<Scalars['jsonb']>;
  location?: Maybe<Location_Name_Enum>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  should_ask_for_feedback?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Offer_Max_Fields = {
  __typename?: 'job_offer_max_fields';
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job_offer" */
export type Job_Offer_Max_Order_By = {
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_offer_url?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Offer_Min_Fields = {
  __typename?: 'job_offer_min_fields';
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job_offer" */
export type Job_Offer_Min_Order_By = {
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_offer_url?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_offer" */
export type Job_Offer_Mutation_Response = {
  __typename?: 'job_offer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job_Offer>;
};

/** input type for inserting object relation for remote table "job_offer" */
export type Job_Offer_Obj_Rel_Insert_Input = {
  data: Job_Offer_Insert_Input;
  on_conflict?: Maybe<Job_Offer_On_Conflict>;
};

/** on conflict condition type for table "job_offer" */
export type Job_Offer_On_Conflict = {
  constraint: Job_Offer_Constraint;
  update_columns: Array<Job_Offer_Update_Column>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};

/** ordering options when selecting data from "job_offer" */
export type Job_Offer_Order_By = {
  Employer?: Maybe<Employer_Order_By>;
  JobRelationStates?: Maybe<Job_Relation_States_Order_By>;
  JobTypes_aggregate?: Maybe<Offered_Job_Aggregate_Order_By>;
  ProfileRelations_aggregate?: Maybe<User_Job_Relation_Aggregate_Order_By>;
  Ratings_aggregate?: Maybe<Job_Rating_Aggregate_Order_By>;
  additional_job_data?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  education?: Maybe<Order_By>;
  employerID?: Maybe<Order_By>;
  employer_company_data?: Maybe<Order_By>;
  hidden?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_offer_details?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  original_offer_url?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
  should_ask_for_feedback?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "job_offer" */
export type Job_Offer_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Job_Offer_Prepend_Input = {
  additional_job_data?: Maybe<Scalars['jsonb']>;
  employer_company_data?: Maybe<Scalars['jsonb']>;
  job_offer_details?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "job_offer" */
export enum Job_Offer_Select_Column {
  /** column name */
  AdditionalJobData = 'additional_job_data',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Education = 'education',
  /** column name */
  EmployerId = 'employerID',
  /** column name */
  EmployerCompanyData = 'employer_company_data',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  JobOfferDetails = 'job_offer_details',
  /** column name */
  Location = 'location',
  /** column name */
  OriginalOfferUrl = 'original_offer_url',
  /** column name */
  Salary = 'salary',
  /** column name */
  ShouldAskForFeedback = 'should_ask_for_feedback',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "job_offer" */
export type Job_Offer_Set_Input = {
  additional_job_data?: Maybe<Scalars['jsonb']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Education_Level_Enum>;
  employerID?: Maybe<Scalars['Int']>;
  employer_company_data?: Maybe<Scalars['jsonb']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  job_offer_details?: Maybe<Scalars['jsonb']>;
  location?: Maybe<Location_Name_Enum>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  should_ask_for_feedback?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Job_Offer_Stddev_Fields = {
  __typename?: 'job_offer_stddev_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_offer" */
export type Job_Offer_Stddev_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Offer_Stddev_Pop_Fields = {
  __typename?: 'job_offer_stddev_pop_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_offer" */
export type Job_Offer_Stddev_Pop_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Offer_Stddev_Samp_Fields = {
  __typename?: 'job_offer_stddev_samp_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_offer" */
export type Job_Offer_Stddev_Samp_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Offer_Sum_Fields = {
  __typename?: 'job_offer_sum_fields';
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_offer" */
export type Job_Offer_Sum_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** update columns of table "job_offer" */
export enum Job_Offer_Update_Column {
  /** column name */
  AdditionalJobData = 'additional_job_data',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Education = 'education',
  /** column name */
  EmployerId = 'employerID',
  /** column name */
  EmployerCompanyData = 'employer_company_data',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  JobOfferDetails = 'job_offer_details',
  /** column name */
  Location = 'location',
  /** column name */
  OriginalOfferUrl = 'original_offer_url',
  /** column name */
  Salary = 'salary',
  /** column name */
  ShouldAskForFeedback = 'should_ask_for_feedback',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Job_Offer_Var_Pop_Fields = {
  __typename?: 'job_offer_var_pop_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_offer" */
export type Job_Offer_Var_Pop_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Offer_Var_Samp_Fields = {
  __typename?: 'job_offer_var_samp_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_offer" */
export type Job_Offer_Var_Samp_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Offer_Variance_Fields = {
  __typename?: 'job_offer_variance_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_offer" */
export type Job_Offer_Variance_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** columns and relationships of "job_offer_view" */
export type Job_Offer_View = {
  __typename?: 'job_offer_view';
  /** An object relationship */
  Employer?: Maybe<Employer>;
  /** An array relationship */
  JobTypes: Array<Offered_Job>;
  /** An aggregated array relationship */
  JobTypes_aggregate: Offered_Job_Aggregate;
  /** An array relationship */
  ProfileRelations: Array<User_Job_Relation>;
  /** An aggregated array relationship */
  ProfileRelations_aggregate: User_Job_Relation_Aggregate;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  employerID?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  job_offer_details?: Maybe<Scalars['json']>;
  location?: Maybe<Scalars['String']>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  should_ask_for_feedback?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "job_offer_view" */
export type Job_Offer_ViewJobTypesArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** columns and relationships of "job_offer_view" */
export type Job_Offer_ViewJobTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** columns and relationships of "job_offer_view" */
export type Job_Offer_ViewProfileRelationsArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** columns and relationships of "job_offer_view" */
export type Job_Offer_ViewProfileRelations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** columns and relationships of "job_offer_view" */
export type Job_Offer_ViewJob_Offer_DetailsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "job_offer_view" */
export type Job_Offer_View_Aggregate = {
  __typename?: 'job_offer_view_aggregate';
  aggregate?: Maybe<Job_Offer_View_Aggregate_Fields>;
  nodes: Array<Job_Offer_View>;
};

/** aggregate fields of "job_offer_view" */
export type Job_Offer_View_Aggregate_Fields = {
  __typename?: 'job_offer_view_aggregate_fields';
  avg?: Maybe<Job_Offer_View_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Offer_View_Max_Fields>;
  min?: Maybe<Job_Offer_View_Min_Fields>;
  stddev?: Maybe<Job_Offer_View_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Offer_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Offer_View_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Offer_View_Sum_Fields>;
  var_pop?: Maybe<Job_Offer_View_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Offer_View_Var_Samp_Fields>;
  variance?: Maybe<Job_Offer_View_Variance_Fields>;
};


/** aggregate fields of "job_offer_view" */
export type Job_Offer_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Offer_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_offer_view" */
export type Job_Offer_View_Aggregate_Order_By = {
  avg?: Maybe<Job_Offer_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Offer_View_Max_Order_By>;
  min?: Maybe<Job_Offer_View_Min_Order_By>;
  stddev?: Maybe<Job_Offer_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Offer_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Offer_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Offer_View_Sum_Order_By>;
  var_pop?: Maybe<Job_Offer_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Offer_View_Var_Samp_Order_By>;
  variance?: Maybe<Job_Offer_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Job_Offer_View_Avg_Fields = {
  __typename?: 'job_offer_view_avg_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_offer_view" */
export type Job_Offer_View_Avg_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_offer_view". All fields are combined with a logical 'AND'. */
export type Job_Offer_View_Bool_Exp = {
  Employer?: Maybe<Employer_Bool_Exp>;
  JobTypes?: Maybe<Offered_Job_Bool_Exp>;
  ProfileRelations?: Maybe<User_Job_Relation_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Job_Offer_View_Bool_Exp>>>;
  _not?: Maybe<Job_Offer_View_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Offer_View_Bool_Exp>>>;
  city?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  education?: Maybe<String_Comparison_Exp>;
  employerID?: Maybe<Int_Comparison_Exp>;
  hidden?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  job_offer_details?: Maybe<Json_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  original_offer_url?: Maybe<String_Comparison_Exp>;
  salary?: Maybe<Int_Comparison_Exp>;
  should_ask_for_feedback?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Job_Offer_View_Max_Fields = {
  __typename?: 'job_offer_view_max_fields';
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job_offer_view" */
export type Job_Offer_View_Max_Order_By = {
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  education?: Maybe<Order_By>;
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  original_offer_url?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Offer_View_Min_Fields = {
  __typename?: 'job_offer_view_min_fields';
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  original_offer_url?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job_offer_view" */
export type Job_Offer_View_Min_Order_By = {
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  education?: Maybe<Order_By>;
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  original_offer_url?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** ordering options when selecting data from "job_offer_view" */
export type Job_Offer_View_Order_By = {
  Employer?: Maybe<Employer_Order_By>;
  JobTypes_aggregate?: Maybe<Offered_Job_Aggregate_Order_By>;
  ProfileRelations_aggregate?: Maybe<User_Job_Relation_Aggregate_Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  education?: Maybe<Order_By>;
  employerID?: Maybe<Order_By>;
  hidden?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_offer_details?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  original_offer_url?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
  should_ask_for_feedback?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "job_offer_view" */
export enum Job_Offer_View_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Education = 'education',
  /** column name */
  EmployerId = 'employerID',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  JobOfferDetails = 'job_offer_details',
  /** column name */
  Location = 'location',
  /** column name */
  OriginalOfferUrl = 'original_offer_url',
  /** column name */
  Salary = 'salary',
  /** column name */
  ShouldAskForFeedback = 'should_ask_for_feedback',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Job_Offer_View_Stddev_Fields = {
  __typename?: 'job_offer_view_stddev_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_offer_view" */
export type Job_Offer_View_Stddev_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Offer_View_Stddev_Pop_Fields = {
  __typename?: 'job_offer_view_stddev_pop_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_offer_view" */
export type Job_Offer_View_Stddev_Pop_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Offer_View_Stddev_Samp_Fields = {
  __typename?: 'job_offer_view_stddev_samp_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_offer_view" */
export type Job_Offer_View_Stddev_Samp_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Offer_View_Sum_Fields = {
  __typename?: 'job_offer_view_sum_fields';
  employerID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_offer_view" */
export type Job_Offer_View_Sum_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Job_Offer_View_Var_Pop_Fields = {
  __typename?: 'job_offer_view_var_pop_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_offer_view" */
export type Job_Offer_View_Var_Pop_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Offer_View_Var_Samp_Fields = {
  __typename?: 'job_offer_view_var_samp_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_offer_view" */
export type Job_Offer_View_Var_Samp_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Offer_View_Variance_Fields = {
  __typename?: 'job_offer_view_variance_fields';
  employerID?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_offer_view" */
export type Job_Offer_View_Variance_Order_By = {
  employerID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary?: Maybe<Order_By>;
};

/** columns and relationships of "job_rating" */
export type Job_Rating = {
  __typename?: 'job_rating';
  /** An object relationship */
  JobOffer: Job_Offer;
  /** An object relationship */
  Profile: Profile;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  jobID: Scalars['Int'];
  positive: Scalars['Boolean'];
  profileID: Scalars['Int'];
  reason: Job_Rating_Reason_Enum;
};

/** aggregated selection of "job_rating" */
export type Job_Rating_Aggregate = {
  __typename?: 'job_rating_aggregate';
  aggregate?: Maybe<Job_Rating_Aggregate_Fields>;
  nodes: Array<Job_Rating>;
};

/** aggregate fields of "job_rating" */
export type Job_Rating_Aggregate_Fields = {
  __typename?: 'job_rating_aggregate_fields';
  avg?: Maybe<Job_Rating_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Rating_Max_Fields>;
  min?: Maybe<Job_Rating_Min_Fields>;
  stddev?: Maybe<Job_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Rating_Sum_Fields>;
  var_pop?: Maybe<Job_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Rating_Var_Samp_Fields>;
  variance?: Maybe<Job_Rating_Variance_Fields>;
};


/** aggregate fields of "job_rating" */
export type Job_Rating_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Rating_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_rating" */
export type Job_Rating_Aggregate_Order_By = {
  avg?: Maybe<Job_Rating_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Rating_Max_Order_By>;
  min?: Maybe<Job_Rating_Min_Order_By>;
  stddev?: Maybe<Job_Rating_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Rating_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Rating_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Rating_Sum_Order_By>;
  var_pop?: Maybe<Job_Rating_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Rating_Var_Samp_Order_By>;
  variance?: Maybe<Job_Rating_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_rating" */
export type Job_Rating_Arr_Rel_Insert_Input = {
  data: Array<Job_Rating_Insert_Input>;
  on_conflict?: Maybe<Job_Rating_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Rating_Avg_Fields = {
  __typename?: 'job_rating_avg_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_rating" */
export type Job_Rating_Avg_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_rating". All fields are combined with a logical 'AND'. */
export type Job_Rating_Bool_Exp = {
  JobOffer?: Maybe<Job_Offer_Bool_Exp>;
  Profile?: Maybe<Profile_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Job_Rating_Bool_Exp>>>;
  _not?: Maybe<Job_Rating_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Rating_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  jobID?: Maybe<Int_Comparison_Exp>;
  positive?: Maybe<Boolean_Comparison_Exp>;
  profileID?: Maybe<Int_Comparison_Exp>;
  reason?: Maybe<Job_Rating_Reason_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_rating" */
export enum Job_Rating_Constraint {
  /** unique or primary key constraint */
  JobRatingPkey = 'job_rating_pkey'
}

/** columns and relationships of "job_rating_counts" */
export type Job_Rating_Counts = {
  __typename?: 'job_rating_counts';
  dislikedJob?: Maybe<Scalars['bigint']>;
  dislikedLocation?: Maybe<Scalars['bigint']>;
  dislikedSalary?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  likedJob?: Maybe<Scalars['bigint']>;
  likedLocation?: Maybe<Scalars['bigint']>;
  likedSalary?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "job_rating_counts" */
export type Job_Rating_Counts_Aggregate = {
  __typename?: 'job_rating_counts_aggregate';
  aggregate?: Maybe<Job_Rating_Counts_Aggregate_Fields>;
  nodes: Array<Job_Rating_Counts>;
};

/** aggregate fields of "job_rating_counts" */
export type Job_Rating_Counts_Aggregate_Fields = {
  __typename?: 'job_rating_counts_aggregate_fields';
  avg?: Maybe<Job_Rating_Counts_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Rating_Counts_Max_Fields>;
  min?: Maybe<Job_Rating_Counts_Min_Fields>;
  stddev?: Maybe<Job_Rating_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Rating_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Rating_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Rating_Counts_Sum_Fields>;
  var_pop?: Maybe<Job_Rating_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Rating_Counts_Var_Samp_Fields>;
  variance?: Maybe<Job_Rating_Counts_Variance_Fields>;
};


/** aggregate fields of "job_rating_counts" */
export type Job_Rating_Counts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Rating_Counts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_rating_counts" */
export type Job_Rating_Counts_Aggregate_Order_By = {
  avg?: Maybe<Job_Rating_Counts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Rating_Counts_Max_Order_By>;
  min?: Maybe<Job_Rating_Counts_Min_Order_By>;
  stddev?: Maybe<Job_Rating_Counts_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Rating_Counts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Rating_Counts_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Rating_Counts_Sum_Order_By>;
  var_pop?: Maybe<Job_Rating_Counts_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Rating_Counts_Var_Samp_Order_By>;
  variance?: Maybe<Job_Rating_Counts_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Job_Rating_Counts_Avg_Fields = {
  __typename?: 'job_rating_counts_avg_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Avg_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_rating_counts". All fields are combined with a logical 'AND'. */
export type Job_Rating_Counts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Job_Rating_Counts_Bool_Exp>>>;
  _not?: Maybe<Job_Rating_Counts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Rating_Counts_Bool_Exp>>>;
  dislikedJob?: Maybe<Bigint_Comparison_Exp>;
  dislikedLocation?: Maybe<Bigint_Comparison_Exp>;
  dislikedSalary?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  likedJob?: Maybe<Bigint_Comparison_Exp>;
  likedLocation?: Maybe<Bigint_Comparison_Exp>;
  likedSalary?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Job_Rating_Counts_Max_Fields = {
  __typename?: 'job_rating_counts_max_fields';
  dislikedJob?: Maybe<Scalars['bigint']>;
  dislikedLocation?: Maybe<Scalars['bigint']>;
  dislikedSalary?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  likedJob?: Maybe<Scalars['bigint']>;
  likedLocation?: Maybe<Scalars['bigint']>;
  likedSalary?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Max_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Rating_Counts_Min_Fields = {
  __typename?: 'job_rating_counts_min_fields';
  dislikedJob?: Maybe<Scalars['bigint']>;
  dislikedLocation?: Maybe<Scalars['bigint']>;
  dislikedSalary?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  likedJob?: Maybe<Scalars['bigint']>;
  likedLocation?: Maybe<Scalars['bigint']>;
  likedSalary?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Min_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** ordering options when selecting data from "job_rating_counts" */
export type Job_Rating_Counts_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** select columns of table "job_rating_counts" */
export enum Job_Rating_Counts_Select_Column {
  /** column name */
  DislikedJob = 'dislikedJob',
  /** column name */
  DislikedLocation = 'dislikedLocation',
  /** column name */
  DislikedSalary = 'dislikedSalary',
  /** column name */
  Id = 'id',
  /** column name */
  LikedJob = 'likedJob',
  /** column name */
  LikedLocation = 'likedLocation',
  /** column name */
  LikedSalary = 'likedSalary'
}

/** aggregate stddev on columns */
export type Job_Rating_Counts_Stddev_Fields = {
  __typename?: 'job_rating_counts_stddev_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Stddev_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Rating_Counts_Stddev_Pop_Fields = {
  __typename?: 'job_rating_counts_stddev_pop_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Stddev_Pop_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Rating_Counts_Stddev_Samp_Fields = {
  __typename?: 'job_rating_counts_stddev_samp_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Stddev_Samp_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Rating_Counts_Sum_Fields = {
  __typename?: 'job_rating_counts_sum_fields';
  dislikedJob?: Maybe<Scalars['bigint']>;
  dislikedLocation?: Maybe<Scalars['bigint']>;
  dislikedSalary?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  likedJob?: Maybe<Scalars['bigint']>;
  likedLocation?: Maybe<Scalars['bigint']>;
  likedSalary?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Sum_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Job_Rating_Counts_Var_Pop_Fields = {
  __typename?: 'job_rating_counts_var_pop_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Var_Pop_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Rating_Counts_Var_Samp_Fields = {
  __typename?: 'job_rating_counts_var_samp_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Var_Samp_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Rating_Counts_Variance_Fields = {
  __typename?: 'job_rating_counts_variance_fields';
  dislikedJob?: Maybe<Scalars['Float']>;
  dislikedLocation?: Maybe<Scalars['Float']>;
  dislikedSalary?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  likedJob?: Maybe<Scalars['Float']>;
  likedLocation?: Maybe<Scalars['Float']>;
  likedSalary?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_rating_counts" */
export type Job_Rating_Counts_Variance_Order_By = {
  dislikedJob?: Maybe<Order_By>;
  dislikedLocation?: Maybe<Order_By>;
  dislikedSalary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  likedJob?: Maybe<Order_By>;
  likedLocation?: Maybe<Order_By>;
  likedSalary?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "job_rating" */
export type Job_Rating_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_rating" */
export type Job_Rating_Insert_Input = {
  JobOffer?: Maybe<Job_Offer_Obj_Rel_Insert_Input>;
  Profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  jobID?: Maybe<Scalars['Int']>;
  positive?: Maybe<Scalars['Boolean']>;
  profileID?: Maybe<Scalars['Int']>;
  reason?: Maybe<Job_Rating_Reason_Enum>;
};

/** aggregate max on columns */
export type Job_Rating_Max_Fields = {
  __typename?: 'job_rating_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "job_rating" */
export type Job_Rating_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Rating_Min_Fields = {
  __typename?: 'job_rating_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "job_rating" */
export type Job_Rating_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_rating" */
export type Job_Rating_Mutation_Response = {
  __typename?: 'job_rating_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job_Rating>;
};

/** input type for inserting object relation for remote table "job_rating" */
export type Job_Rating_Obj_Rel_Insert_Input = {
  data: Job_Rating_Insert_Input;
  on_conflict?: Maybe<Job_Rating_On_Conflict>;
};

/** on conflict condition type for table "job_rating" */
export type Job_Rating_On_Conflict = {
  constraint: Job_Rating_Constraint;
  update_columns: Array<Job_Rating_Update_Column>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};

/** ordering options when selecting data from "job_rating" */
export type Job_Rating_Order_By = {
  JobOffer?: Maybe<Job_Offer_Order_By>;
  Profile?: Maybe<Profile_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  positive?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
};

/** primary key columns input for table: "job_rating" */
export type Job_Rating_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "job_rating_reason" */
export type Job_Rating_Reason = {
  __typename?: 'job_rating_reason';
  value: Scalars['String'];
};

/** aggregated selection of "job_rating_reason" */
export type Job_Rating_Reason_Aggregate = {
  __typename?: 'job_rating_reason_aggregate';
  aggregate?: Maybe<Job_Rating_Reason_Aggregate_Fields>;
  nodes: Array<Job_Rating_Reason>;
};

/** aggregate fields of "job_rating_reason" */
export type Job_Rating_Reason_Aggregate_Fields = {
  __typename?: 'job_rating_reason_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Rating_Reason_Max_Fields>;
  min?: Maybe<Job_Rating_Reason_Min_Fields>;
};


/** aggregate fields of "job_rating_reason" */
export type Job_Rating_Reason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Rating_Reason_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_rating_reason" */
export type Job_Rating_Reason_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Rating_Reason_Max_Order_By>;
  min?: Maybe<Job_Rating_Reason_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job_rating_reason" */
export type Job_Rating_Reason_Arr_Rel_Insert_Input = {
  data: Array<Job_Rating_Reason_Insert_Input>;
  on_conflict?: Maybe<Job_Rating_Reason_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_rating_reason". All fields are combined with a logical 'AND'. */
export type Job_Rating_Reason_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Job_Rating_Reason_Bool_Exp>>>;
  _not?: Maybe<Job_Rating_Reason_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Rating_Reason_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_rating_reason" */
export enum Job_Rating_Reason_Constraint {
  /** unique or primary key constraint */
  JobRatingReasonPkey = 'job_rating_reason_pkey'
}

export enum Job_Rating_Reason_Enum {
  Job = 'JOB',
  Location = 'LOCATION',
  Salary = 'SALARY'
}

/** expression to compare columns of type job_rating_reason_enum. All fields are combined with logical 'AND'. */
export type Job_Rating_Reason_Enum_Comparison_Exp = {
  _eq?: Maybe<Job_Rating_Reason_Enum>;
  _in?: Maybe<Array<Job_Rating_Reason_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Job_Rating_Reason_Enum>;
  _nin?: Maybe<Array<Job_Rating_Reason_Enum>>;
};

/** input type for inserting data into table "job_rating_reason" */
export type Job_Rating_Reason_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Rating_Reason_Max_Fields = {
  __typename?: 'job_rating_reason_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job_rating_reason" */
export type Job_Rating_Reason_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Rating_Reason_Min_Fields = {
  __typename?: 'job_rating_reason_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job_rating_reason" */
export type Job_Rating_Reason_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_rating_reason" */
export type Job_Rating_Reason_Mutation_Response = {
  __typename?: 'job_rating_reason_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job_Rating_Reason>;
};

/** input type for inserting object relation for remote table "job_rating_reason" */
export type Job_Rating_Reason_Obj_Rel_Insert_Input = {
  data: Job_Rating_Reason_Insert_Input;
  on_conflict?: Maybe<Job_Rating_Reason_On_Conflict>;
};

/** on conflict condition type for table "job_rating_reason" */
export type Job_Rating_Reason_On_Conflict = {
  constraint: Job_Rating_Reason_Constraint;
  update_columns: Array<Job_Rating_Reason_Update_Column>;
  where?: Maybe<Job_Rating_Reason_Bool_Exp>;
};

/** ordering options when selecting data from "job_rating_reason" */
export type Job_Rating_Reason_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "job_rating_reason" */
export type Job_Rating_Reason_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_rating_reason" */
export enum Job_Rating_Reason_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "job_rating_reason" */
export type Job_Rating_Reason_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "job_rating_reason" */
export enum Job_Rating_Reason_Update_Column {
  /** column name */
  Value = 'value'
}

/** select columns of table "job_rating" */
export enum Job_Rating_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobID',
  /** column name */
  Positive = 'positive',
  /** column name */
  ProfileId = 'profileID',
  /** column name */
  Reason = 'reason'
}

/** input type for updating data in table "job_rating" */
export type Job_Rating_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  jobID?: Maybe<Scalars['Int']>;
  positive?: Maybe<Scalars['Boolean']>;
  profileID?: Maybe<Scalars['Int']>;
  reason?: Maybe<Job_Rating_Reason_Enum>;
};

/** aggregate stddev on columns */
export type Job_Rating_Stddev_Fields = {
  __typename?: 'job_rating_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_rating" */
export type Job_Rating_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Rating_Stddev_Pop_Fields = {
  __typename?: 'job_rating_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_rating" */
export type Job_Rating_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Rating_Stddev_Samp_Fields = {
  __typename?: 'job_rating_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_rating" */
export type Job_Rating_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Rating_Sum_Fields = {
  __typename?: 'job_rating_sum_fields';
  id?: Maybe<Scalars['Int']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_rating" */
export type Job_Rating_Sum_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** update columns of table "job_rating" */
export enum Job_Rating_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobID',
  /** column name */
  Positive = 'positive',
  /** column name */
  ProfileId = 'profileID',
  /** column name */
  Reason = 'reason'
}

/** aggregate var_pop on columns */
export type Job_Rating_Var_Pop_Fields = {
  __typename?: 'job_rating_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_rating" */
export type Job_Rating_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Rating_Var_Samp_Fields = {
  __typename?: 'job_rating_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_rating" */
export type Job_Rating_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Rating_Variance_Fields = {
  __typename?: 'job_rating_variance_fields';
  id?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_rating" */
export type Job_Rating_Variance_Order_By = {
  id?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** columns and relationships of "job_relation_states" */
export type Job_Relation_States = {
  __typename?: 'job_relation_states';
  /** An object relationship */
  Job?: Maybe<Job_Offer>;
  dislikes?: Maybe<Scalars['bigint']>;
  jobID?: Maybe<Scalars['Int']>;
  laters?: Maybe<Scalars['bigint']>;
  likes?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "job_relation_states" */
export type Job_Relation_States_Aggregate = {
  __typename?: 'job_relation_states_aggregate';
  aggregate?: Maybe<Job_Relation_States_Aggregate_Fields>;
  nodes: Array<Job_Relation_States>;
};

/** aggregate fields of "job_relation_states" */
export type Job_Relation_States_Aggregate_Fields = {
  __typename?: 'job_relation_states_aggregate_fields';
  avg?: Maybe<Job_Relation_States_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Relation_States_Max_Fields>;
  min?: Maybe<Job_Relation_States_Min_Fields>;
  stddev?: Maybe<Job_Relation_States_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Relation_States_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Relation_States_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Relation_States_Sum_Fields>;
  var_pop?: Maybe<Job_Relation_States_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Relation_States_Var_Samp_Fields>;
  variance?: Maybe<Job_Relation_States_Variance_Fields>;
};


/** aggregate fields of "job_relation_states" */
export type Job_Relation_States_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Relation_States_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_relation_states" */
export type Job_Relation_States_Aggregate_Order_By = {
  avg?: Maybe<Job_Relation_States_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Relation_States_Max_Order_By>;
  min?: Maybe<Job_Relation_States_Min_Order_By>;
  stddev?: Maybe<Job_Relation_States_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Relation_States_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Relation_States_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Relation_States_Sum_Order_By>;
  var_pop?: Maybe<Job_Relation_States_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Relation_States_Var_Samp_Order_By>;
  variance?: Maybe<Job_Relation_States_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Job_Relation_States_Avg_Fields = {
  __typename?: 'job_relation_states_avg_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_relation_states" */
export type Job_Relation_States_Avg_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_relation_states". All fields are combined with a logical 'AND'. */
export type Job_Relation_States_Bool_Exp = {
  Job?: Maybe<Job_Offer_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Job_Relation_States_Bool_Exp>>>;
  _not?: Maybe<Job_Relation_States_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Relation_States_Bool_Exp>>>;
  dislikes?: Maybe<Bigint_Comparison_Exp>;
  jobID?: Maybe<Int_Comparison_Exp>;
  laters?: Maybe<Bigint_Comparison_Exp>;
  likes?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Job_Relation_States_Max_Fields = {
  __typename?: 'job_relation_states_max_fields';
  dislikes?: Maybe<Scalars['bigint']>;
  jobID?: Maybe<Scalars['Int']>;
  laters?: Maybe<Scalars['bigint']>;
  likes?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "job_relation_states" */
export type Job_Relation_States_Max_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Relation_States_Min_Fields = {
  __typename?: 'job_relation_states_min_fields';
  dislikes?: Maybe<Scalars['bigint']>;
  jobID?: Maybe<Scalars['Int']>;
  laters?: Maybe<Scalars['bigint']>;
  likes?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "job_relation_states" */
export type Job_Relation_States_Min_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** ordering options when selecting data from "job_relation_states" */
export type Job_Relation_States_Order_By = {
  Job?: Maybe<Job_Offer_Order_By>;
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** select columns of table "job_relation_states" */
export enum Job_Relation_States_Select_Column {
  /** column name */
  Dislikes = 'dislikes',
  /** column name */
  JobId = 'jobID',
  /** column name */
  Laters = 'laters',
  /** column name */
  Likes = 'likes'
}

/** aggregate stddev on columns */
export type Job_Relation_States_Stddev_Fields = {
  __typename?: 'job_relation_states_stddev_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_relation_states" */
export type Job_Relation_States_Stddev_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Relation_States_Stddev_Pop_Fields = {
  __typename?: 'job_relation_states_stddev_pop_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_relation_states" */
export type Job_Relation_States_Stddev_Pop_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Relation_States_Stddev_Samp_Fields = {
  __typename?: 'job_relation_states_stddev_samp_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_relation_states" */
export type Job_Relation_States_Stddev_Samp_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Relation_States_Sum_Fields = {
  __typename?: 'job_relation_states_sum_fields';
  dislikes?: Maybe<Scalars['bigint']>;
  jobID?: Maybe<Scalars['Int']>;
  laters?: Maybe<Scalars['bigint']>;
  likes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "job_relation_states" */
export type Job_Relation_States_Sum_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Job_Relation_States_Var_Pop_Fields = {
  __typename?: 'job_relation_states_var_pop_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_relation_states" */
export type Job_Relation_States_Var_Pop_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Relation_States_Var_Samp_Fields = {
  __typename?: 'job_relation_states_var_samp_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_relation_states" */
export type Job_Relation_States_Var_Samp_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Relation_States_Variance_Fields = {
  __typename?: 'job_relation_states_variance_fields';
  dislikes?: Maybe<Scalars['Float']>;
  jobID?: Maybe<Scalars['Float']>;
  laters?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_relation_states" */
export type Job_Relation_States_Variance_Order_By = {
  dislikes?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
};

/** columns and relationships of "job_type" */
export type Job_Type = {
  __typename?: 'job_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  job_field: Scalars['String'];
};

/** aggregated selection of "job_type" */
export type Job_Type_Aggregate = {
  __typename?: 'job_type_aggregate';
  aggregate?: Maybe<Job_Type_Aggregate_Fields>;
  nodes: Array<Job_Type>;
};

/** aggregate fields of "job_type" */
export type Job_Type_Aggregate_Fields = {
  __typename?: 'job_type_aggregate_fields';
  avg?: Maybe<Job_Type_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Type_Max_Fields>;
  min?: Maybe<Job_Type_Min_Fields>;
  stddev?: Maybe<Job_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Type_Sum_Fields>;
  var_pop?: Maybe<Job_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Type_Var_Samp_Fields>;
  variance?: Maybe<Job_Type_Variance_Fields>;
};


/** aggregate fields of "job_type" */
export type Job_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_type" */
export type Job_Type_Aggregate_Order_By = {
  avg?: Maybe<Job_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Type_Max_Order_By>;
  min?: Maybe<Job_Type_Min_Order_By>;
  stddev?: Maybe<Job_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Job_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Job_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Job_Type_Sum_Order_By>;
  var_pop?: Maybe<Job_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Job_Type_Var_Samp_Order_By>;
  variance?: Maybe<Job_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_type" */
export type Job_Type_Arr_Rel_Insert_Input = {
  data: Array<Job_Type_Insert_Input>;
  on_conflict?: Maybe<Job_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Type_Avg_Fields = {
  __typename?: 'job_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_type" */
export type Job_Type_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_type". All fields are combined with a logical 'AND'. */
export type Job_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Job_Type_Bool_Exp>>>;
  _not?: Maybe<Job_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Type_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  job_field?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_type" */
export enum Job_Type_Constraint {
  /** unique or primary key constraint */
  JobTypeJobFieldKey = 'job_type_job_field_key',
  /** unique or primary key constraint */
  JobTypePkey = 'job_type_pkey'
}

/** input type for incrementing integer column in table "job_type" */
export type Job_Type_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_type" */
export type Job_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  job_field?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Type_Max_Fields = {
  __typename?: 'job_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  job_field?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job_type" */
export type Job_Type_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_field?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Type_Min_Fields = {
  __typename?: 'job_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  job_field?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job_type" */
export type Job_Type_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_field?: Maybe<Order_By>;
};

/** response of any mutation on the table "job_type" */
export type Job_Type_Mutation_Response = {
  __typename?: 'job_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job_Type>;
};

/** input type for inserting object relation for remote table "job_type" */
export type Job_Type_Obj_Rel_Insert_Input = {
  data: Job_Type_Insert_Input;
  on_conflict?: Maybe<Job_Type_On_Conflict>;
};

/** on conflict condition type for table "job_type" */
export type Job_Type_On_Conflict = {
  constraint: Job_Type_Constraint;
  update_columns: Array<Job_Type_Update_Column>;
  where?: Maybe<Job_Type_Bool_Exp>;
};

/** ordering options when selecting data from "job_type" */
export type Job_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_field?: Maybe<Order_By>;
};

/** primary key columns input for table: "job_type" */
export type Job_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_type" */
export enum Job_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobField = 'job_field'
}

/** input type for updating data in table "job_type" */
export type Job_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  job_field?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Job_Type_Stddev_Fields = {
  __typename?: 'job_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_type" */
export type Job_Type_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Type_Stddev_Pop_Fields = {
  __typename?: 'job_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_type" */
export type Job_Type_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Type_Stddev_Samp_Fields = {
  __typename?: 'job_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_type" */
export type Job_Type_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Type_Sum_Fields = {
  __typename?: 'job_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_type" */
export type Job_Type_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "job_type" */
export enum Job_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobField = 'job_field'
}

/** aggregate var_pop on columns */
export type Job_Type_Var_Pop_Fields = {
  __typename?: 'job_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_type" */
export type Job_Type_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Type_Var_Samp_Fields = {
  __typename?: 'job_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_type" */
export type Job_Type_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Type_Variance_Fields = {
  __typename?: 'job_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_type" */
export type Job_Type_Variance_Order_By = {
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "location_name" */
export type Location_Name = {
  __typename?: 'location_name';
  value: Scalars['String'];
};

/** aggregated selection of "location_name" */
export type Location_Name_Aggregate = {
  __typename?: 'location_name_aggregate';
  aggregate?: Maybe<Location_Name_Aggregate_Fields>;
  nodes: Array<Location_Name>;
};

/** aggregate fields of "location_name" */
export type Location_Name_Aggregate_Fields = {
  __typename?: 'location_name_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Location_Name_Max_Fields>;
  min?: Maybe<Location_Name_Min_Fields>;
};


/** aggregate fields of "location_name" */
export type Location_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Location_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "location_name" */
export type Location_Name_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Location_Name_Max_Order_By>;
  min?: Maybe<Location_Name_Min_Order_By>;
};

/** input type for inserting array relation for remote table "location_name" */
export type Location_Name_Arr_Rel_Insert_Input = {
  data: Array<Location_Name_Insert_Input>;
  on_conflict?: Maybe<Location_Name_On_Conflict>;
};

/** Boolean expression to filter rows from the table "location_name". All fields are combined with a logical 'AND'. */
export type Location_Name_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Location_Name_Bool_Exp>>>;
  _not?: Maybe<Location_Name_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Location_Name_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "location_name" */
export enum Location_Name_Constraint {
  /** unique or primary key constraint */
  LocationNamePkey = 'location_name_pkey'
}

export enum Location_Name_Enum {
  BanovceNadBebravou = 'BANOVCE_NAD_BEBRAVOU',
  BanskaBystrica = 'BANSKA_BYSTRICA',
  BanskaStiavnica = 'BANSKA_STIAVNICA',
  Bardejov = 'BARDEJOV',
  Bratislava = 'BRATISLAVA',
  Brezno = 'BREZNO',
  Bytca = 'BYTCA',
  Cadca = 'CADCA',
  Default = 'DEFAULT',
  Detva = 'DETVA',
  DolnyKubin = 'DOLNY_KUBIN',
  DunajskaStreda = 'DUNAJSKA_STREDA',
  Galanta = 'GALANTA',
  Gelnica = 'GELNICA',
  Hlohovec = 'HLOHOVEC',
  Humenne = 'HUMENNE',
  Ilava = 'ILAVA',
  Kezmarok = 'KEZMAROK',
  Komarno = 'KOMARNO',
  Kosice = 'KOSICE',
  KosiceOkolie = 'KOSICE_OKOLIE',
  Krupina = 'KRUPINA',
  KysuckeNoveMesto = 'KYSUCKE_NOVE_MESTO',
  Levice = 'LEVICE',
  Levoca = 'LEVOCA',
  LiptovskyMikulas = 'LIPTOVSKY_MIKULAS',
  Lucenec = 'LUCENEC',
  Malacky = 'MALACKY',
  Martin = 'MARTIN',
  Medzilaborce = 'MEDZILABORCE',
  Michalovce = 'MICHALOVCE',
  Myjava = 'MYJAVA',
  Namestovo = 'NAMESTOVO',
  Nitra = 'NITRA',
  NoveMestoNadVahom = 'NOVE_MESTO_NAD_VAHOM',
  NoveZamky = 'NOVE_ZAMKY',
  Partizanske = 'PARTIZANSKE',
  Pezinok = 'PEZINOK',
  Piestany = 'PIESTANY',
  Poltar = 'POLTAR',
  Poprad = 'POPRAD',
  PovazskaBystrica = 'POVAZSKA_BYSTRICA',
  Presov = 'PRESOV',
  Prievidza = 'PRIEVIDZA',
  Puchov = 'PUCHOV',
  Revuca = 'REVUCA',
  RimavskaSobota = 'RIMAVSKA_SOBOTA',
  Roznava = 'ROZNAVA',
  Ruzomberok = 'RUZOMBEROK',
  Sabinov = 'SABINOV',
  Sala = 'SALA',
  Senec = 'SENEC',
  Senica = 'SENICA',
  Skalica = 'SKALICA',
  Snina = 'SNINA',
  Sobrance = 'SOBRANCE',
  SpisskaNovaVes = 'SPISSKA_NOVA_VES',
  StaraLubovna = 'STARA_LUBOVNA',
  Stropkov = 'STROPKOV',
  Svidnik = 'SVIDNIK',
  Topolcany = 'TOPOLCANY',
  Trebisov = 'TREBISOV',
  Trencin = 'TRENCIN',
  Trnava = 'TRNAVA',
  TurcianskeTeplice = 'TURCIANSKE_TEPLICE',
  Tvrdosin = 'TVRDOSIN',
  VelkyKrtis = 'VELKY_KRTIS',
  VranovNadToplou = 'VRANOV_NAD_TOPLOU',
  Zarnovica = 'ZARNOVICA',
  ZiarNadHronom = 'ZIAR_NAD_HRONOM',
  Zilina = 'ZILINA',
  ZlateMoravce = 'ZLATE_MORAVCE',
  Zvolen = 'ZVOLEN'
}

/** expression to compare columns of type location_name_enum. All fields are combined with logical 'AND'. */
export type Location_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Location_Name_Enum>;
  _in?: Maybe<Array<Location_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Location_Name_Enum>;
  _nin?: Maybe<Array<Location_Name_Enum>>;
};

/** input type for inserting data into table "location_name" */
export type Location_Name_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Location_Name_Max_Fields = {
  __typename?: 'location_name_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "location_name" */
export type Location_Name_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Location_Name_Min_Fields = {
  __typename?: 'location_name_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "location_name" */
export type Location_Name_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "location_name" */
export type Location_Name_Mutation_Response = {
  __typename?: 'location_name_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Location_Name>;
};

/** input type for inserting object relation for remote table "location_name" */
export type Location_Name_Obj_Rel_Insert_Input = {
  data: Location_Name_Insert_Input;
  on_conflict?: Maybe<Location_Name_On_Conflict>;
};

/** on conflict condition type for table "location_name" */
export type Location_Name_On_Conflict = {
  constraint: Location_Name_Constraint;
  update_columns: Array<Location_Name_Update_Column>;
  where?: Maybe<Location_Name_Bool_Exp>;
};

/** ordering options when selecting data from "location_name" */
export type Location_Name_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "location_name" */
export type Location_Name_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "location_name" */
export enum Location_Name_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "location_name" */
export type Location_Name_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "location_name" */
export enum Location_Name_Update_Column {
  /** column name */
  Value = 'value'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "education_level" */
  delete_education_level?: Maybe<Education_Level_Mutation_Response>;
  /** delete single row from the table: "education_level" */
  delete_education_level_by_pk?: Maybe<Education_Level>;
  /** delete data from the table: "employer" */
  delete_employer?: Maybe<Employer_Mutation_Response>;
  /** delete data from the table: "employer_active_counts" */
  delete_employer_active_counts?: Maybe<Employer_Active_Counts_Mutation_Response>;
  /** delete single row from the table: "employer" */
  delete_employer_by_pk?: Maybe<Employer>;
  /** delete data from the table: "error_logs" */
  delete_error_logs?: Maybe<Error_Logs_Mutation_Response>;
  /** delete single row from the table: "error_logs" */
  delete_error_logs_by_pk?: Maybe<Error_Logs>;
  /** delete data from the table: "fcm_token" */
  delete_fcm_token?: Maybe<Fcm_Token_Mutation_Response>;
  /** delete single row from the table: "fcm_token" */
  delete_fcm_token_by_pk?: Maybe<Fcm_Token>;
  /** delete data from the table: "job_field" */
  delete_job_field?: Maybe<Job_Field_Mutation_Response>;
  /** delete single row from the table: "job_field" */
  delete_job_field_by_pk?: Maybe<Job_Field>;
  /** delete data from the table: "job_location" */
  delete_job_location?: Maybe<Job_Location_Mutation_Response>;
  /** delete single row from the table: "job_location" */
  delete_job_location_by_pk?: Maybe<Job_Location>;
  /** delete data from the table: "job_offer" */
  delete_job_offer?: Maybe<Job_Offer_Mutation_Response>;
  /** delete single row from the table: "job_offer" */
  delete_job_offer_by_pk?: Maybe<Job_Offer>;
  /** delete data from the table: "job_rating" */
  delete_job_rating?: Maybe<Job_Rating_Mutation_Response>;
  /** delete single row from the table: "job_rating" */
  delete_job_rating_by_pk?: Maybe<Job_Rating>;
  /** delete data from the table: "job_rating_reason" */
  delete_job_rating_reason?: Maybe<Job_Rating_Reason_Mutation_Response>;
  /** delete single row from the table: "job_rating_reason" */
  delete_job_rating_reason_by_pk?: Maybe<Job_Rating_Reason>;
  /** delete data from the table: "job_type" */
  delete_job_type?: Maybe<Job_Type_Mutation_Response>;
  /** delete single row from the table: "job_type" */
  delete_job_type_by_pk?: Maybe<Job_Type>;
  /** delete data from the table: "location_name" */
  delete_location_name?: Maybe<Location_Name_Mutation_Response>;
  /** delete single row from the table: "location_name" */
  delete_location_name_by_pk?: Maybe<Location_Name>;
  /** delete data from the table: "offered_job" */
  delete_offered_job?: Maybe<Offered_Job_Mutation_Response>;
  /** delete single row from the table: "offered_job" */
  delete_offered_job_by_pk?: Maybe<Offered_Job>;
  /** delete data from the table: "profile" */
  delete_profile?: Maybe<Profile_Mutation_Response>;
  /** delete single row from the table: "profile" */
  delete_profile_by_pk?: Maybe<Profile>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "user_file" */
  delete_user_file?: Maybe<User_File_Mutation_Response>;
  /** delete single row from the table: "user_file" */
  delete_user_file_by_pk?: Maybe<User_File>;
  /** delete data from the table: "user_job_relation" */
  delete_user_job_relation?: Maybe<User_Job_Relation_Mutation_Response>;
  /** delete single row from the table: "user_job_relation" */
  delete_user_job_relation_by_pk?: Maybe<User_Job_Relation>;
  /** delete data from the table: "user_job_relation_state" */
  delete_user_job_relation_state?: Maybe<User_Job_Relation_State_Mutation_Response>;
  /** delete single row from the table: "user_job_relation_state" */
  delete_user_job_relation_state_by_pk?: Maybe<User_Job_Relation_State>;
  /** insert data into the table: "education_level" */
  insert_education_level?: Maybe<Education_Level_Mutation_Response>;
  /** insert a single row into the table: "education_level" */
  insert_education_level_one?: Maybe<Education_Level>;
  /** insert data into the table: "employer" */
  insert_employer?: Maybe<Employer_Mutation_Response>;
  /** insert data into the table: "employer_active_counts" */
  insert_employer_active_counts?: Maybe<Employer_Active_Counts_Mutation_Response>;
  /** insert a single row into the table: "employer_active_counts" */
  insert_employer_active_counts_one?: Maybe<Employer_Active_Counts>;
  /** insert a single row into the table: "employer" */
  insert_employer_one?: Maybe<Employer>;
  /** insert data into the table: "error_logs" */
  insert_error_logs?: Maybe<Error_Logs_Mutation_Response>;
  /** insert a single row into the table: "error_logs" */
  insert_error_logs_one?: Maybe<Error_Logs>;
  /** insert data into the table: "fcm_token" */
  insert_fcm_token?: Maybe<Fcm_Token_Mutation_Response>;
  /** insert a single row into the table: "fcm_token" */
  insert_fcm_token_one?: Maybe<Fcm_Token>;
  /** insert data into the table: "job_field" */
  insert_job_field?: Maybe<Job_Field_Mutation_Response>;
  /** insert a single row into the table: "job_field" */
  insert_job_field_one?: Maybe<Job_Field>;
  /** insert data into the table: "job_location" */
  insert_job_location?: Maybe<Job_Location_Mutation_Response>;
  /** insert a single row into the table: "job_location" */
  insert_job_location_one?: Maybe<Job_Location>;
  /** insert data into the table: "job_offer" */
  insert_job_offer?: Maybe<Job_Offer_Mutation_Response>;
  /** insert a single row into the table: "job_offer" */
  insert_job_offer_one?: Maybe<Job_Offer>;
  /** insert data into the table: "job_rating" */
  insert_job_rating?: Maybe<Job_Rating_Mutation_Response>;
  /** insert a single row into the table: "job_rating" */
  insert_job_rating_one?: Maybe<Job_Rating>;
  /** insert data into the table: "job_rating_reason" */
  insert_job_rating_reason?: Maybe<Job_Rating_Reason_Mutation_Response>;
  /** insert a single row into the table: "job_rating_reason" */
  insert_job_rating_reason_one?: Maybe<Job_Rating_Reason>;
  /** insert data into the table: "job_type" */
  insert_job_type?: Maybe<Job_Type_Mutation_Response>;
  /** insert a single row into the table: "job_type" */
  insert_job_type_one?: Maybe<Job_Type>;
  /** insert data into the table: "location_name" */
  insert_location_name?: Maybe<Location_Name_Mutation_Response>;
  /** insert a single row into the table: "location_name" */
  insert_location_name_one?: Maybe<Location_Name>;
  /** insert data into the table: "offered_job" */
  insert_offered_job?: Maybe<Offered_Job_Mutation_Response>;
  /** insert a single row into the table: "offered_job" */
  insert_offered_job_one?: Maybe<Offered_Job>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "user_file" */
  insert_user_file?: Maybe<User_File_Mutation_Response>;
  /** insert a single row into the table: "user_file" */
  insert_user_file_one?: Maybe<User_File>;
  /** insert data into the table: "user_job_relation" */
  insert_user_job_relation?: Maybe<User_Job_Relation_Mutation_Response>;
  /** insert a single row into the table: "user_job_relation" */
  insert_user_job_relation_one?: Maybe<User_Job_Relation>;
  /** insert data into the table: "user_job_relation_state" */
  insert_user_job_relation_state?: Maybe<User_Job_Relation_State_Mutation_Response>;
  /** insert a single row into the table: "user_job_relation_state" */
  insert_user_job_relation_state_one?: Maybe<User_Job_Relation_State>;
  /** perform the action: "login" */
  login?: Maybe<AuthResponse>;
  /** perform the action: "refreshToken" */
  refreshToken?: Maybe<TokenOutput>;
  /** perform the action: "signUp" */
  signUp?: Maybe<AuthResponse>;
  /** update data of the table: "education_level" */
  update_education_level?: Maybe<Education_Level_Mutation_Response>;
  /** update single row of the table: "education_level" */
  update_education_level_by_pk?: Maybe<Education_Level>;
  /** update data of the table: "employer" */
  update_employer?: Maybe<Employer_Mutation_Response>;
  /** update data of the table: "employer_active_counts" */
  update_employer_active_counts?: Maybe<Employer_Active_Counts_Mutation_Response>;
  /** update single row of the table: "employer" */
  update_employer_by_pk?: Maybe<Employer>;
  /** update data of the table: "error_logs" */
  update_error_logs?: Maybe<Error_Logs_Mutation_Response>;
  /** update single row of the table: "error_logs" */
  update_error_logs_by_pk?: Maybe<Error_Logs>;
  /** update data of the table: "fcm_token" */
  update_fcm_token?: Maybe<Fcm_Token_Mutation_Response>;
  /** update single row of the table: "fcm_token" */
  update_fcm_token_by_pk?: Maybe<Fcm_Token>;
  /** update data of the table: "job_field" */
  update_job_field?: Maybe<Job_Field_Mutation_Response>;
  /** update single row of the table: "job_field" */
  update_job_field_by_pk?: Maybe<Job_Field>;
  /** update data of the table: "job_location" */
  update_job_location?: Maybe<Job_Location_Mutation_Response>;
  /** update single row of the table: "job_location" */
  update_job_location_by_pk?: Maybe<Job_Location>;
  /** update data of the table: "job_offer" */
  update_job_offer?: Maybe<Job_Offer_Mutation_Response>;
  /** update single row of the table: "job_offer" */
  update_job_offer_by_pk?: Maybe<Job_Offer>;
  /** update data of the table: "job_rating" */
  update_job_rating?: Maybe<Job_Rating_Mutation_Response>;
  /** update single row of the table: "job_rating" */
  update_job_rating_by_pk?: Maybe<Job_Rating>;
  /** update data of the table: "job_rating_reason" */
  update_job_rating_reason?: Maybe<Job_Rating_Reason_Mutation_Response>;
  /** update single row of the table: "job_rating_reason" */
  update_job_rating_reason_by_pk?: Maybe<Job_Rating_Reason>;
  /** update data of the table: "job_type" */
  update_job_type?: Maybe<Job_Type_Mutation_Response>;
  /** update single row of the table: "job_type" */
  update_job_type_by_pk?: Maybe<Job_Type>;
  /** update data of the table: "location_name" */
  update_location_name?: Maybe<Location_Name_Mutation_Response>;
  /** update single row of the table: "location_name" */
  update_location_name_by_pk?: Maybe<Location_Name>;
  /** update data of the table: "offered_job" */
  update_offered_job?: Maybe<Offered_Job_Mutation_Response>;
  /** update single row of the table: "offered_job" */
  update_offered_job_by_pk?: Maybe<Offered_Job>;
  /** update data of the table: "profile" */
  update_profile?: Maybe<Profile_Mutation_Response>;
  /** update single row of the table: "profile" */
  update_profile_by_pk?: Maybe<Profile>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update data of the table: "user_file" */
  update_user_file?: Maybe<User_File_Mutation_Response>;
  /** update single row of the table: "user_file" */
  update_user_file_by_pk?: Maybe<User_File>;
  /** update data of the table: "user_job_relation" */
  update_user_job_relation?: Maybe<User_Job_Relation_Mutation_Response>;
  /** update single row of the table: "user_job_relation" */
  update_user_job_relation_by_pk?: Maybe<User_Job_Relation>;
  /** update data of the table: "user_job_relation_state" */
  update_user_job_relation_state?: Maybe<User_Job_Relation_State_Mutation_Response>;
  /** update single row of the table: "user_job_relation_state" */
  update_user_job_relation_state_by_pk?: Maybe<User_Job_Relation_State>;
};


/** mutation root */
export type Mutation_RootDelete_Education_LevelArgs = {
  where: Education_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Education_Level_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmployerArgs = {
  where: Employer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employer_Active_CountsArgs = {
  where: Employer_Active_Counts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employer_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Error_LogsArgs = {
  where: Error_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Error_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Fcm_TokenArgs = {
  where: Fcm_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fcm_Token_By_PkArgs = {
  fcm_token_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Job_FieldArgs = {
  where: Job_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Field_By_PkArgs = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Job_LocationArgs = {
  where: Job_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Location_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Job_OfferArgs = {
  where: Job_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Offer_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Job_RatingArgs = {
  where: Job_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Rating_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Job_Rating_ReasonArgs = {
  where: Job_Rating_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Rating_Reason_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Job_TypeArgs = {
  where: Job_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Location_NameArgs = {
  where: Location_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Location_Name_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Offered_JobArgs = {
  where: Offered_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Offered_Job_By_PkArgs = {
  jobID: Scalars['Int'];
  offerID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_FileArgs = {
  where: User_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Job_RelationArgs = {
  where: User_Job_Relation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Job_Relation_By_PkArgs = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Job_Relation_StateArgs = {
  where: User_Job_Relation_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Job_Relation_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Education_LevelArgs = {
  objects: Array<Education_Level_Insert_Input>;
  on_conflict?: Maybe<Education_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Education_Level_OneArgs = {
  object: Education_Level_Insert_Input;
  on_conflict?: Maybe<Education_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployerArgs = {
  objects: Array<Employer_Insert_Input>;
  on_conflict?: Maybe<Employer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employer_Active_CountsArgs = {
  objects: Array<Employer_Active_Counts_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Employer_Active_Counts_OneArgs = {
  object: Employer_Active_Counts_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Employer_OneArgs = {
  object: Employer_Insert_Input;
  on_conflict?: Maybe<Employer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Error_LogsArgs = {
  objects: Array<Error_Logs_Insert_Input>;
  on_conflict?: Maybe<Error_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Error_Logs_OneArgs = {
  object: Error_Logs_Insert_Input;
  on_conflict?: Maybe<Error_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fcm_TokenArgs = {
  objects: Array<Fcm_Token_Insert_Input>;
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fcm_Token_OneArgs = {
  object: Fcm_Token_Insert_Input;
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_FieldArgs = {
  objects: Array<Job_Field_Insert_Input>;
  on_conflict?: Maybe<Job_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Field_OneArgs = {
  object: Job_Field_Insert_Input;
  on_conflict?: Maybe<Job_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_LocationArgs = {
  objects: Array<Job_Location_Insert_Input>;
  on_conflict?: Maybe<Job_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Location_OneArgs = {
  object: Job_Location_Insert_Input;
  on_conflict?: Maybe<Job_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_OfferArgs = {
  objects: Array<Job_Offer_Insert_Input>;
  on_conflict?: Maybe<Job_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Offer_OneArgs = {
  object: Job_Offer_Insert_Input;
  on_conflict?: Maybe<Job_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_RatingArgs = {
  objects: Array<Job_Rating_Insert_Input>;
  on_conflict?: Maybe<Job_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Rating_OneArgs = {
  object: Job_Rating_Insert_Input;
  on_conflict?: Maybe<Job_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Rating_ReasonArgs = {
  objects: Array<Job_Rating_Reason_Insert_Input>;
  on_conflict?: Maybe<Job_Rating_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Rating_Reason_OneArgs = {
  object: Job_Rating_Reason_Insert_Input;
  on_conflict?: Maybe<Job_Rating_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_TypeArgs = {
  objects: Array<Job_Type_Insert_Input>;
  on_conflict?: Maybe<Job_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Type_OneArgs = {
  object: Job_Type_Insert_Input;
  on_conflict?: Maybe<Job_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_NameArgs = {
  objects: Array<Location_Name_Insert_Input>;
  on_conflict?: Maybe<Location_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_Name_OneArgs = {
  object: Location_Name_Insert_Input;
  on_conflict?: Maybe<Location_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Offered_JobArgs = {
  objects: Array<Offered_Job_Insert_Input>;
  on_conflict?: Maybe<Offered_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Offered_Job_OneArgs = {
  object: Offered_Job_Insert_Input;
  on_conflict?: Maybe<Offered_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: Maybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: Maybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: Maybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: Maybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_FileArgs = {
  objects: Array<User_File_Insert_Input>;
  on_conflict?: Maybe<User_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_File_OneArgs = {
  object: User_File_Insert_Input;
  on_conflict?: Maybe<User_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Job_RelationArgs = {
  objects: Array<User_Job_Relation_Insert_Input>;
  on_conflict?: Maybe<User_Job_Relation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Job_Relation_OneArgs = {
  object: User_Job_Relation_Insert_Input;
  on_conflict?: Maybe<User_Job_Relation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Job_Relation_StateArgs = {
  objects: Array<User_Job_Relation_State_Insert_Input>;
  on_conflict?: Maybe<User_Job_Relation_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Job_Relation_State_OneArgs = {
  object: User_Job_Relation_State_Insert_Input;
  on_conflict?: Maybe<User_Job_Relation_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Education_LevelArgs = {
  _set?: Maybe<Education_Level_Set_Input>;
  where: Education_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Education_Level_By_PkArgs = {
  _set?: Maybe<Education_Level_Set_Input>;
  pk_columns: Education_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmployerArgs = {
  _inc?: Maybe<Employer_Inc_Input>;
  _set?: Maybe<Employer_Set_Input>;
  where: Employer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employer_Active_CountsArgs = {
  _inc?: Maybe<Employer_Active_Counts_Inc_Input>;
  _set?: Maybe<Employer_Active_Counts_Set_Input>;
  where: Employer_Active_Counts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employer_By_PkArgs = {
  _inc?: Maybe<Employer_Inc_Input>;
  _set?: Maybe<Employer_Set_Input>;
  pk_columns: Employer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Error_LogsArgs = {
  _inc?: Maybe<Error_Logs_Inc_Input>;
  _set?: Maybe<Error_Logs_Set_Input>;
  where: Error_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Error_Logs_By_PkArgs = {
  _inc?: Maybe<Error_Logs_Inc_Input>;
  _set?: Maybe<Error_Logs_Set_Input>;
  pk_columns: Error_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fcm_TokenArgs = {
  _inc?: Maybe<Fcm_Token_Inc_Input>;
  _set?: Maybe<Fcm_Token_Set_Input>;
  where: Fcm_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fcm_Token_By_PkArgs = {
  _inc?: Maybe<Fcm_Token_Inc_Input>;
  _set?: Maybe<Fcm_Token_Set_Input>;
  pk_columns: Fcm_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_FieldArgs = {
  _inc?: Maybe<Job_Field_Inc_Input>;
  _set?: Maybe<Job_Field_Set_Input>;
  where: Job_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Field_By_PkArgs = {
  _inc?: Maybe<Job_Field_Inc_Input>;
  _set?: Maybe<Job_Field_Set_Input>;
  pk_columns: Job_Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_LocationArgs = {
  _inc?: Maybe<Job_Location_Inc_Input>;
  _set?: Maybe<Job_Location_Set_Input>;
  where: Job_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Location_By_PkArgs = {
  _inc?: Maybe<Job_Location_Inc_Input>;
  _set?: Maybe<Job_Location_Set_Input>;
  pk_columns: Job_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_OfferArgs = {
  _append?: Maybe<Job_Offer_Append_Input>;
  _delete_at_path?: Maybe<Job_Offer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Job_Offer_Delete_Elem_Input>;
  _delete_key?: Maybe<Job_Offer_Delete_Key_Input>;
  _inc?: Maybe<Job_Offer_Inc_Input>;
  _prepend?: Maybe<Job_Offer_Prepend_Input>;
  _set?: Maybe<Job_Offer_Set_Input>;
  where: Job_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Offer_By_PkArgs = {
  _append?: Maybe<Job_Offer_Append_Input>;
  _delete_at_path?: Maybe<Job_Offer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Job_Offer_Delete_Elem_Input>;
  _delete_key?: Maybe<Job_Offer_Delete_Key_Input>;
  _inc?: Maybe<Job_Offer_Inc_Input>;
  _prepend?: Maybe<Job_Offer_Prepend_Input>;
  _set?: Maybe<Job_Offer_Set_Input>;
  pk_columns: Job_Offer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_RatingArgs = {
  _inc?: Maybe<Job_Rating_Inc_Input>;
  _set?: Maybe<Job_Rating_Set_Input>;
  where: Job_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Rating_By_PkArgs = {
  _inc?: Maybe<Job_Rating_Inc_Input>;
  _set?: Maybe<Job_Rating_Set_Input>;
  pk_columns: Job_Rating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Rating_ReasonArgs = {
  _set?: Maybe<Job_Rating_Reason_Set_Input>;
  where: Job_Rating_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Rating_Reason_By_PkArgs = {
  _set?: Maybe<Job_Rating_Reason_Set_Input>;
  pk_columns: Job_Rating_Reason_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_TypeArgs = {
  _inc?: Maybe<Job_Type_Inc_Input>;
  _set?: Maybe<Job_Type_Set_Input>;
  where: Job_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Type_By_PkArgs = {
  _inc?: Maybe<Job_Type_Inc_Input>;
  _set?: Maybe<Job_Type_Set_Input>;
  pk_columns: Job_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Location_NameArgs = {
  _set?: Maybe<Location_Name_Set_Input>;
  where: Location_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Name_By_PkArgs = {
  _set?: Maybe<Location_Name_Set_Input>;
  pk_columns: Location_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Offered_JobArgs = {
  _inc?: Maybe<Offered_Job_Inc_Input>;
  _set?: Maybe<Offered_Job_Set_Input>;
  where: Offered_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Offered_Job_By_PkArgs = {
  _inc?: Maybe<Offered_Job_Inc_Input>;
  _set?: Maybe<Offered_Job_Set_Input>;
  pk_columns: Offered_Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _inc?: Maybe<Profile_Inc_Input>;
  _set?: Maybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_By_PkArgs = {
  _inc?: Maybe<Profile_Inc_Input>;
  _set?: Maybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: Maybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: Maybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_FileArgs = {
  _inc?: Maybe<User_File_Inc_Input>;
  _set?: Maybe<User_File_Set_Input>;
  where: User_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_File_By_PkArgs = {
  _inc?: Maybe<User_File_Inc_Input>;
  _set?: Maybe<User_File_Set_Input>;
  pk_columns: User_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Job_RelationArgs = {
  _inc?: Maybe<User_Job_Relation_Inc_Input>;
  _set?: Maybe<User_Job_Relation_Set_Input>;
  where: User_Job_Relation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Job_Relation_By_PkArgs = {
  _inc?: Maybe<User_Job_Relation_Inc_Input>;
  _set?: Maybe<User_Job_Relation_Set_Input>;
  pk_columns: User_Job_Relation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Job_Relation_StateArgs = {
  _set?: Maybe<User_Job_Relation_State_Set_Input>;
  where: User_Job_Relation_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Job_Relation_State_By_PkArgs = {
  _set?: Maybe<User_Job_Relation_State_Set_Input>;
  pk_columns: User_Job_Relation_State_Pk_Columns_Input;
};

/** columns and relationships of "offered_job" */
export type Offered_Job = {
  __typename?: 'offered_job';
  /** An object relationship */
  JobType: Job_Type;
  jobID: Scalars['Int'];
  offerID: Scalars['Int'];
};

/** aggregated selection of "offered_job" */
export type Offered_Job_Aggregate = {
  __typename?: 'offered_job_aggregate';
  aggregate?: Maybe<Offered_Job_Aggregate_Fields>;
  nodes: Array<Offered_Job>;
};

/** aggregate fields of "offered_job" */
export type Offered_Job_Aggregate_Fields = {
  __typename?: 'offered_job_aggregate_fields';
  avg?: Maybe<Offered_Job_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Offered_Job_Max_Fields>;
  min?: Maybe<Offered_Job_Min_Fields>;
  stddev?: Maybe<Offered_Job_Stddev_Fields>;
  stddev_pop?: Maybe<Offered_Job_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Offered_Job_Stddev_Samp_Fields>;
  sum?: Maybe<Offered_Job_Sum_Fields>;
  var_pop?: Maybe<Offered_Job_Var_Pop_Fields>;
  var_samp?: Maybe<Offered_Job_Var_Samp_Fields>;
  variance?: Maybe<Offered_Job_Variance_Fields>;
};


/** aggregate fields of "offered_job" */
export type Offered_Job_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Offered_Job_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "offered_job" */
export type Offered_Job_Aggregate_Order_By = {
  avg?: Maybe<Offered_Job_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Offered_Job_Max_Order_By>;
  min?: Maybe<Offered_Job_Min_Order_By>;
  stddev?: Maybe<Offered_Job_Stddev_Order_By>;
  stddev_pop?: Maybe<Offered_Job_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Offered_Job_Stddev_Samp_Order_By>;
  sum?: Maybe<Offered_Job_Sum_Order_By>;
  var_pop?: Maybe<Offered_Job_Var_Pop_Order_By>;
  var_samp?: Maybe<Offered_Job_Var_Samp_Order_By>;
  variance?: Maybe<Offered_Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "offered_job" */
export type Offered_Job_Arr_Rel_Insert_Input = {
  data: Array<Offered_Job_Insert_Input>;
  on_conflict?: Maybe<Offered_Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Offered_Job_Avg_Fields = {
  __typename?: 'offered_job_avg_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "offered_job" */
export type Offered_Job_Avg_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "offered_job". All fields are combined with a logical 'AND'. */
export type Offered_Job_Bool_Exp = {
  JobType?: Maybe<Job_Type_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Offered_Job_Bool_Exp>>>;
  _not?: Maybe<Offered_Job_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Offered_Job_Bool_Exp>>>;
  jobID?: Maybe<Int_Comparison_Exp>;
  offerID?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "offered_job" */
export enum Offered_Job_Constraint {
  /** unique or primary key constraint */
  OfferedJobPkey = 'offered_job_pkey'
}

/** input type for incrementing integer column in table "offered_job" */
export type Offered_Job_Inc_Input = {
  jobID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "offered_job" */
export type Offered_Job_Insert_Input = {
  JobType?: Maybe<Job_Type_Obj_Rel_Insert_Input>;
  jobID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Offered_Job_Max_Fields = {
  __typename?: 'offered_job_max_fields';
  jobID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "offered_job" */
export type Offered_Job_Max_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Offered_Job_Min_Fields = {
  __typename?: 'offered_job_min_fields';
  jobID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "offered_job" */
export type Offered_Job_Min_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** response of any mutation on the table "offered_job" */
export type Offered_Job_Mutation_Response = {
  __typename?: 'offered_job_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Offered_Job>;
};

/** input type for inserting object relation for remote table "offered_job" */
export type Offered_Job_Obj_Rel_Insert_Input = {
  data: Offered_Job_Insert_Input;
  on_conflict?: Maybe<Offered_Job_On_Conflict>;
};

/** on conflict condition type for table "offered_job" */
export type Offered_Job_On_Conflict = {
  constraint: Offered_Job_Constraint;
  update_columns: Array<Offered_Job_Update_Column>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};

/** ordering options when selecting data from "offered_job" */
export type Offered_Job_Order_By = {
  JobType?: Maybe<Job_Type_Order_By>;
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** primary key columns input for table: "offered_job" */
export type Offered_Job_Pk_Columns_Input = {
  jobID: Scalars['Int'];
  offerID: Scalars['Int'];
};

/** select columns of table "offered_job" */
export enum Offered_Job_Select_Column {
  /** column name */
  JobId = 'jobID',
  /** column name */
  OfferId = 'offerID'
}

/** input type for updating data in table "offered_job" */
export type Offered_Job_Set_Input = {
  jobID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Offered_Job_Stddev_Fields = {
  __typename?: 'offered_job_stddev_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "offered_job" */
export type Offered_Job_Stddev_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Offered_Job_Stddev_Pop_Fields = {
  __typename?: 'offered_job_stddev_pop_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "offered_job" */
export type Offered_Job_Stddev_Pop_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Offered_Job_Stddev_Samp_Fields = {
  __typename?: 'offered_job_stddev_samp_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "offered_job" */
export type Offered_Job_Stddev_Samp_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Offered_Job_Sum_Fields = {
  __typename?: 'offered_job_sum_fields';
  jobID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "offered_job" */
export type Offered_Job_Sum_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** update columns of table "offered_job" */
export enum Offered_Job_Update_Column {
  /** column name */
  JobId = 'jobID',
  /** column name */
  OfferId = 'offerID'
}

/** aggregate var_pop on columns */
export type Offered_Job_Var_Pop_Fields = {
  __typename?: 'offered_job_var_pop_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "offered_job" */
export type Offered_Job_Var_Pop_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Offered_Job_Var_Samp_Fields = {
  __typename?: 'offered_job_var_samp_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "offered_job" */
export type Offered_Job_Var_Samp_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Offered_Job_Variance_Fields = {
  __typename?: 'offered_job_variance_fields';
  jobID?: Maybe<Scalars['Float']>;
  offerID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "offered_job" */
export type Offered_Job_Variance_Order_By = {
  jobID?: Maybe<Order_By>;
  offerID?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  /** An object relationship */
  Cv?: Maybe<User_File>;
  /** An array relationship */
  JobLocations: Array<Job_Location>;
  /** An aggregated array relationship */
  JobLocations_aggregate: Job_Location_Aggregate;
  /** An array relationship */
  JobPreferences: Array<Job_Field>;
  /** An aggregated array relationship */
  JobPreferences_aggregate: Job_Field_Aggregate;
  /** An array relationship */
  JobRatings: Array<Job_Rating>;
  /** An aggregated array relationship */
  JobRatings_aggregate: Job_Rating_Aggregate;
  /** An array relationship */
  JobRelations: Array<User_Job_Relation>;
  /** An aggregated array relationship */
  JobRelations_aggregate: User_Job_Relation_Aggregate;
  /** An object relationship */
  LatestCv?: Maybe<Cvs>;
  created_at: Scalars['timestamptz'];
  education: Education_Level_Enum;
  email: Scalars['String'];
  fcm_token?: Maybe<Scalars['String']>;
  /** An array relationship */
  fcm_tokens: Array<Fcm_Token>;
  /** An aggregated array relationship */
  fcm_tokens_aggregate: Fcm_Token_Aggregate;
  hash: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  required_salary?: Maybe<Scalars['Int']>;
  role: Roles_Enum;
  surname: Scalars['String'];
};


/** columns and relationships of "profile" */
export type ProfileJobLocationsArgs = {
  distinct_on?: Maybe<Array<Job_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Location_Order_By>>;
  where?: Maybe<Job_Location_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Location_Order_By>>;
  where?: Maybe<Job_Location_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobPreferencesArgs = {
  distinct_on?: Maybe<Array<Job_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Field_Order_By>>;
  where?: Maybe<Job_Field_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobPreferences_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Field_Order_By>>;
  where?: Maybe<Job_Field_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobRatingsArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobRatings_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobRelationsArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileJobRelations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileFcm_TokensArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileFcm_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  avg?: Maybe<Profile_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
  stddev?: Maybe<Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Sum_Fields>;
  var_pop?: Maybe<Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Var_Samp_Fields>;
  variance?: Maybe<Profile_Variance_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile" */
export type Profile_Aggregate_Order_By = {
  avg?: Maybe<Profile_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Max_Order_By>;
  min?: Maybe<Profile_Min_Order_By>;
  stddev?: Maybe<Profile_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Sum_Order_By>;
  var_pop?: Maybe<Profile_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profile" */
export type Profile_Arr_Rel_Insert_Input = {
  data: Array<Profile_Insert_Input>;
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Avg_Fields = {
  __typename?: 'profile_avg_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile" */
export type Profile_Avg_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  Cv?: Maybe<User_File_Bool_Exp>;
  JobLocations?: Maybe<Job_Location_Bool_Exp>;
  JobPreferences?: Maybe<Job_Field_Bool_Exp>;
  JobRatings?: Maybe<Job_Rating_Bool_Exp>;
  JobRelations?: Maybe<User_Job_Relation_Bool_Exp>;
  LatestCv?: Maybe<Cvs_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Profile_Bool_Exp>>>;
  _not?: Maybe<Profile_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Profile_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  education?: Maybe<Education_Level_Enum_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fcm_token?: Maybe<String_Comparison_Exp>;
  fcm_tokens?: Maybe<Fcm_Token_Bool_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  required_salary?: Maybe<Int_Comparison_Exp>;
  role?: Maybe<Roles_Enum_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint */
  ProfilePkey = 'profile_pkey'
}

/** input type for incrementing integer column in table "profile" */
export type Profile_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  required_salary?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  Cv?: Maybe<User_File_Obj_Rel_Insert_Input>;
  JobLocations?: Maybe<Job_Location_Arr_Rel_Insert_Input>;
  JobPreferences?: Maybe<Job_Field_Arr_Rel_Insert_Input>;
  JobRatings?: Maybe<Job_Rating_Arr_Rel_Insert_Input>;
  JobRelations?: Maybe<User_Job_Relation_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  education?: Maybe<Education_Level_Enum>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  fcm_tokens?: Maybe<Fcm_Token_Arr_Rel_Insert_Input>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  required_salary?: Maybe<Scalars['Int']>;
  role?: Maybe<Roles_Enum>;
  surname?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  required_salary?: Maybe<Scalars['Int']>;
  surname?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "profile" */
export type Profile_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcm_token?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  required_salary?: Maybe<Scalars['Int']>;
  surname?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "profile" */
export type Profile_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcm_token?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Profile>;
};

/** input type for inserting object relation for remote table "profile" */
export type Profile_Obj_Rel_Insert_Input = {
  data: Profile_Insert_Input;
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** on conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns: Array<Profile_Update_Column>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** ordering options when selecting data from "profile" */
export type Profile_Order_By = {
  Cv?: Maybe<User_File_Order_By>;
  JobLocations_aggregate?: Maybe<Job_Location_Aggregate_Order_By>;
  JobPreferences_aggregate?: Maybe<Job_Field_Aggregate_Order_By>;
  JobRatings_aggregate?: Maybe<Job_Rating_Aggregate_Order_By>;
  JobRelations_aggregate?: Maybe<User_Job_Relation_Aggregate_Order_By>;
  LatestCv?: Maybe<Cvs_Order_By>;
  created_at?: Maybe<Order_By>;
  education?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcm_token?: Maybe<Order_By>;
  fcm_tokens_aggregate?: Maybe<Fcm_Token_Aggregate_Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
};

/** primary key columns input for table: "profile" */
export type Profile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Education = 'education',
  /** column name */
  Email = 'email',
  /** column name */
  FcmToken = 'fcm_token',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RequiredSalary = 'required_salary',
  /** column name */
  Role = 'role',
  /** column name */
  Surname = 'surname'
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  education?: Maybe<Education_Level_Enum>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  required_salary?: Maybe<Scalars['Int']>;
  role?: Maybe<Roles_Enum>;
  surname?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Profile_Stddev_Fields = {
  __typename?: 'profile_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile" */
export type Profile_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Stddev_Pop_Fields = {
  __typename?: 'profile_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile" */
export type Profile_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Stddev_Samp_Fields = {
  __typename?: 'profile_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile" */
export type Profile_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Sum_Fields = {
  __typename?: 'profile_sum_fields';
  id?: Maybe<Scalars['Int']>;
  required_salary?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "profile" */
export type Profile_Sum_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** update columns of table "profile" */
export enum Profile_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Education = 'education',
  /** column name */
  Email = 'email',
  /** column name */
  FcmToken = 'fcm_token',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RequiredSalary = 'required_salary',
  /** column name */
  Role = 'role',
  /** column name */
  Surname = 'surname'
}

/** aggregate var_pop on columns */
export type Profile_Var_Pop_Fields = {
  __typename?: 'profile_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile" */
export type Profile_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Var_Samp_Fields = {
  __typename?: 'profile_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile" */
export type Profile_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Variance_Fields = {
  __typename?: 'profile_variance_fields';
  id?: Maybe<Scalars['Float']>;
  required_salary?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile" */
export type Profile_Variance_Order_By = {
  id?: Maybe<Order_By>;
  required_salary?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** perform the action: "GetAllUserData" */
  GetAllUserData?: Maybe<GetAllUserDataOutput>;
  /** fetch data from the table: "cvs" */
  cvs: Array<Cvs>;
  /** fetch aggregated fields from the table: "cvs" */
  cvs_aggregate: Cvs_Aggregate;
  /** fetch data from the table: "education_level" */
  education_level: Array<Education_Level>;
  /** fetch aggregated fields from the table: "education_level" */
  education_level_aggregate: Education_Level_Aggregate;
  /** fetch data from the table: "education_level" using primary key columns */
  education_level_by_pk?: Maybe<Education_Level>;
  /** fetch data from the table: "employer" */
  employer: Array<Employer>;
  /** fetch data from the table: "employer_active_counts" */
  employer_active_counts: Array<Employer_Active_Counts>;
  /** fetch aggregated fields from the table: "employer_active_counts" */
  employer_active_counts_aggregate: Employer_Active_Counts_Aggregate;
  /** fetch aggregated fields from the table: "employer" */
  employer_aggregate: Employer_Aggregate;
  /** fetch data from the table: "employer" using primary key columns */
  employer_by_pk?: Maybe<Employer>;
  /** fetch data from the table: "error_logs" */
  error_logs: Array<Error_Logs>;
  /** fetch aggregated fields from the table: "error_logs" */
  error_logs_aggregate: Error_Logs_Aggregate;
  /** fetch data from the table: "error_logs" using primary key columns */
  error_logs_by_pk?: Maybe<Error_Logs>;
  /** fetch data from the table: "fcm_token" */
  fcm_token: Array<Fcm_Token>;
  /** fetch aggregated fields from the table: "fcm_token" */
  fcm_token_aggregate: Fcm_Token_Aggregate;
  /** fetch data from the table: "fcm_token" using primary key columns */
  fcm_token_by_pk?: Maybe<Fcm_Token>;
  /** fetch data from the table: "job_field" */
  job_field: Array<Job_Field>;
  /** fetch aggregated fields from the table: "job_field" */
  job_field_aggregate: Job_Field_Aggregate;
  /** fetch data from the table: "job_field" using primary key columns */
  job_field_by_pk?: Maybe<Job_Field>;
  /** fetch data from the table: "job_location" */
  job_location: Array<Job_Location>;
  /** fetch aggregated fields from the table: "job_location" */
  job_location_aggregate: Job_Location_Aggregate;
  /** fetch data from the table: "job_location" using primary key columns */
  job_location_by_pk?: Maybe<Job_Location>;
  /** fetch data from the table: "job_offer" */
  job_offer: Array<Job_Offer>;
  /** fetch aggregated fields from the table: "job_offer" */
  job_offer_aggregate: Job_Offer_Aggregate;
  /** fetch data from the table: "job_offer" using primary key columns */
  job_offer_by_pk?: Maybe<Job_Offer>;
  /** fetch data from the table: "job_offer_view" */
  job_offer_view: Array<Job_Offer_View>;
  /** fetch aggregated fields from the table: "job_offer_view" */
  job_offer_view_aggregate: Job_Offer_View_Aggregate;
  /** fetch data from the table: "job_rating" */
  job_rating: Array<Job_Rating>;
  /** fetch aggregated fields from the table: "job_rating" */
  job_rating_aggregate: Job_Rating_Aggregate;
  /** fetch data from the table: "job_rating" using primary key columns */
  job_rating_by_pk?: Maybe<Job_Rating>;
  /** fetch data from the table: "job_rating_counts" */
  job_rating_counts: Array<Job_Rating_Counts>;
  /** fetch aggregated fields from the table: "job_rating_counts" */
  job_rating_counts_aggregate: Job_Rating_Counts_Aggregate;
  /** fetch data from the table: "job_rating_reason" */
  job_rating_reason: Array<Job_Rating_Reason>;
  /** fetch aggregated fields from the table: "job_rating_reason" */
  job_rating_reason_aggregate: Job_Rating_Reason_Aggregate;
  /** fetch data from the table: "job_rating_reason" using primary key columns */
  job_rating_reason_by_pk?: Maybe<Job_Rating_Reason>;
  /** fetch data from the table: "job_relation_states" */
  job_relation_states: Array<Job_Relation_States>;
  /** fetch aggregated fields from the table: "job_relation_states" */
  job_relation_states_aggregate: Job_Relation_States_Aggregate;
  /** fetch data from the table: "job_type" */
  job_type: Array<Job_Type>;
  /** fetch aggregated fields from the table: "job_type" */
  job_type_aggregate: Job_Type_Aggregate;
  /** fetch data from the table: "job_type" using primary key columns */
  job_type_by_pk?: Maybe<Job_Type>;
  /** fetch data from the table: "location_name" */
  location_name: Array<Location_Name>;
  /** fetch aggregated fields from the table: "location_name" */
  location_name_aggregate: Location_Name_Aggregate;
  /** fetch data from the table: "location_name" using primary key columns */
  location_name_by_pk?: Maybe<Location_Name>;
  /** fetch data from the table: "offered_job" */
  offered_job: Array<Offered_Job>;
  /** fetch aggregated fields from the table: "offered_job" */
  offered_job_aggregate: Offered_Job_Aggregate;
  /** fetch data from the table: "offered_job" using primary key columns */
  offered_job_by_pk?: Maybe<Offered_Job>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** perform the action: "refreshEmployerActiveCounts" */
  refreshEmployerActiveCounts?: Maybe<SampleActionOutput>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** perform the action: "sendNotification" */
  sendNotification?: Maybe<SampleActionOutput>;
  /** fetch data from the table: "user_file" */
  user_file: Array<User_File>;
  /** fetch aggregated fields from the table: "user_file" */
  user_file_aggregate: User_File_Aggregate;
  /** fetch data from the table: "user_file" using primary key columns */
  user_file_by_pk?: Maybe<User_File>;
  /** fetch data from the table: "user_job_relation" */
  user_job_relation: Array<User_Job_Relation>;
  /** fetch aggregated fields from the table: "user_job_relation" */
  user_job_relation_aggregate: User_Job_Relation_Aggregate;
  /** fetch data from the table: "user_job_relation" using primary key columns */
  user_job_relation_by_pk?: Maybe<User_Job_Relation>;
  /** fetch data from the table: "user_job_relation_state" */
  user_job_relation_state: Array<User_Job_Relation_State>;
  /** fetch aggregated fields from the table: "user_job_relation_state" */
  user_job_relation_state_aggregate: User_Job_Relation_State_Aggregate;
  /** fetch data from the table: "user_job_relation_state" using primary key columns */
  user_job_relation_state_by_pk?: Maybe<User_Job_Relation_State>;
};


/** query root */
export type Query_RootCvsArgs = {
  distinct_on?: Maybe<Array<Cvs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cvs_Order_By>>;
  where?: Maybe<Cvs_Bool_Exp>;
};


/** query root */
export type Query_RootCvs_AggregateArgs = {
  distinct_on?: Maybe<Array<Cvs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cvs_Order_By>>;
  where?: Maybe<Cvs_Bool_Exp>;
};


/** query root */
export type Query_RootEducation_LevelArgs = {
  distinct_on?: Maybe<Array<Education_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Education_Level_Order_By>>;
  where?: Maybe<Education_Level_Bool_Exp>;
};


/** query root */
export type Query_RootEducation_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Education_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Education_Level_Order_By>>;
  where?: Maybe<Education_Level_Bool_Exp>;
};


/** query root */
export type Query_RootEducation_Level_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootEmployerArgs = {
  distinct_on?: Maybe<Array<Employer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Order_By>>;
  where?: Maybe<Employer_Bool_Exp>;
};


/** query root */
export type Query_RootEmployer_Active_CountsArgs = {
  distinct_on?: Maybe<Array<Employer_Active_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Active_Counts_Order_By>>;
  where?: Maybe<Employer_Active_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootEmployer_Active_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Employer_Active_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Active_Counts_Order_By>>;
  where?: Maybe<Employer_Active_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootEmployer_AggregateArgs = {
  distinct_on?: Maybe<Array<Employer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Order_By>>;
  where?: Maybe<Employer_Bool_Exp>;
};


/** query root */
export type Query_RootEmployer_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootError_LogsArgs = {
  distinct_on?: Maybe<Array<Error_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Error_Logs_Order_By>>;
  where?: Maybe<Error_Logs_Bool_Exp>;
};


/** query root */
export type Query_RootError_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Error_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Error_Logs_Order_By>>;
  where?: Maybe<Error_Logs_Bool_Exp>;
};


/** query root */
export type Query_RootError_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootFcm_TokenArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** query root */
export type Query_RootFcm_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** query root */
export type Query_RootFcm_Token_By_PkArgs = {
  fcm_token_id: Scalars['Int'];
};


/** query root */
export type Query_RootJob_FieldArgs = {
  distinct_on?: Maybe<Array<Job_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Field_Order_By>>;
  where?: Maybe<Job_Field_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Field_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Field_Order_By>>;
  where?: Maybe<Job_Field_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Field_By_PkArgs = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};


/** query root */
export type Query_RootJob_LocationArgs = {
  distinct_on?: Maybe<Array<Job_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Location_Order_By>>;
  where?: Maybe<Job_Location_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Location_Order_By>>;
  where?: Maybe<Job_Location_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Location_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootJob_OfferArgs = {
  distinct_on?: Maybe<Array<Job_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_Order_By>>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_Order_By>>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Offer_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootJob_Offer_ViewArgs = {
  distinct_on?: Maybe<Array<Job_Offer_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_View_Order_By>>;
  where?: Maybe<Job_Offer_View_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Offer_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Offer_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_View_Order_By>>;
  where?: Maybe<Job_Offer_View_Bool_Exp>;
};


/** query root */
export type Query_RootJob_RatingArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Rating_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootJob_Rating_CountsArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Counts_Order_By>>;
  where?: Maybe<Job_Rating_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Rating_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Counts_Order_By>>;
  where?: Maybe<Job_Rating_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Rating_ReasonArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Reason_Order_By>>;
  where?: Maybe<Job_Rating_Reason_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Rating_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Reason_Order_By>>;
  where?: Maybe<Job_Rating_Reason_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Rating_Reason_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootJob_Relation_StatesArgs = {
  distinct_on?: Maybe<Array<Job_Relation_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Relation_States_Order_By>>;
  where?: Maybe<Job_Relation_States_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Relation_States_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Relation_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Relation_States_Order_By>>;
  where?: Maybe<Job_Relation_States_Bool_Exp>;
};


/** query root */
export type Query_RootJob_TypeArgs = {
  distinct_on?: Maybe<Array<Job_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Type_Order_By>>;
  where?: Maybe<Job_Type_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Type_Order_By>>;
  where?: Maybe<Job_Type_Bool_Exp>;
};


/** query root */
export type Query_RootJob_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLocation_NameArgs = {
  distinct_on?: Maybe<Array<Location_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Name_Order_By>>;
  where?: Maybe<Location_Name_Bool_Exp>;
};


/** query root */
export type Query_RootLocation_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Name_Order_By>>;
  where?: Maybe<Location_Name_Bool_Exp>;
};


/** query root */
export type Query_RootLocation_Name_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootOffered_JobArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** query root */
export type Query_RootOffered_Job_AggregateArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** query root */
export type Query_RootOffered_Job_By_PkArgs = {
  jobID: Scalars['Int'];
  offerID: Scalars['Int'];
};


/** query root */
export type Query_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** query root */
export type Query_RootProfile_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** query root */
export type Query_RootProfile_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** query root */
export type Query_RootRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** query root */
export type Query_RootRoles_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootSendNotificationArgs = {
  notification_body: Scalars['String'];
  notification_title: Scalars['String'];
};


/** query root */
export type Query_RootUser_FileArgs = {
  distinct_on?: Maybe<Array<User_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_File_Order_By>>;
  where?: Maybe<User_File_Bool_Exp>;
};


/** query root */
export type Query_RootUser_File_AggregateArgs = {
  distinct_on?: Maybe<Array<User_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_File_Order_By>>;
  where?: Maybe<User_File_Bool_Exp>;
};


/** query root */
export type Query_RootUser_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUser_Job_RelationArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Job_Relation_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Job_Relation_By_PkArgs = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};


/** query root */
export type Query_RootUser_Job_Relation_StateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_State_Order_By>>;
  where?: Maybe<User_Job_Relation_State_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Job_Relation_State_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_State_Order_By>>;
  where?: Maybe<User_Job_Relation_State_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Job_Relation_State_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  value: Scalars['String'];
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "roles" */
export type Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Roles_Max_Order_By>;
  min?: Maybe<Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "roles" */
export type Roles_Arr_Rel_Insert_Input = {
  data: Array<Roles_Insert_Input>;
  on_conflict?: Maybe<Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Roles_Bool_Exp>>>;
  _not?: Maybe<Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Roles_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

export enum Roles_Enum {
  Admin = 'ADMIN',
  User = 'USER',
  WebAdmin = 'WEB_ADMIN'
}

/** expression to compare columns of type roles_enum. All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<Roles_Enum>;
  _in?: Maybe<Array<Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Roles_Enum>;
  _nin?: Maybe<Array<Roles_Enum>>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "roles" */
export type Roles_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "roles" */
export type Roles_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  on_conflict?: Maybe<Roles_On_Conflict>;
};

/** on conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns: Array<Roles_Update_Column>;
  where?: Maybe<Roles_Bool_Exp>;
};

/** ordering options when selecting data from "roles" */
export type Roles_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "roles" */
export type Roles_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Value = 'value'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** perform the action: "GetAllUserData" */
  GetAllUserData?: Maybe<GetAllUserDataOutput>;
  /** fetch data from the table: "cvs" */
  cvs: Array<Cvs>;
  /** fetch aggregated fields from the table: "cvs" */
  cvs_aggregate: Cvs_Aggregate;
  /** fetch data from the table: "education_level" */
  education_level: Array<Education_Level>;
  /** fetch aggregated fields from the table: "education_level" */
  education_level_aggregate: Education_Level_Aggregate;
  /** fetch data from the table: "education_level" using primary key columns */
  education_level_by_pk?: Maybe<Education_Level>;
  /** fetch data from the table: "employer" */
  employer: Array<Employer>;
  /** fetch data from the table: "employer_active_counts" */
  employer_active_counts: Array<Employer_Active_Counts>;
  /** fetch aggregated fields from the table: "employer_active_counts" */
  employer_active_counts_aggregate: Employer_Active_Counts_Aggregate;
  /** fetch aggregated fields from the table: "employer" */
  employer_aggregate: Employer_Aggregate;
  /** fetch data from the table: "employer" using primary key columns */
  employer_by_pk?: Maybe<Employer>;
  /** fetch data from the table: "error_logs" */
  error_logs: Array<Error_Logs>;
  /** fetch aggregated fields from the table: "error_logs" */
  error_logs_aggregate: Error_Logs_Aggregate;
  /** fetch data from the table: "error_logs" using primary key columns */
  error_logs_by_pk?: Maybe<Error_Logs>;
  /** fetch data from the table: "fcm_token" */
  fcm_token: Array<Fcm_Token>;
  /** fetch aggregated fields from the table: "fcm_token" */
  fcm_token_aggregate: Fcm_Token_Aggregate;
  /** fetch data from the table: "fcm_token" using primary key columns */
  fcm_token_by_pk?: Maybe<Fcm_Token>;
  /** fetch data from the table: "job_field" */
  job_field: Array<Job_Field>;
  /** fetch aggregated fields from the table: "job_field" */
  job_field_aggregate: Job_Field_Aggregate;
  /** fetch data from the table: "job_field" using primary key columns */
  job_field_by_pk?: Maybe<Job_Field>;
  /** fetch data from the table: "job_location" */
  job_location: Array<Job_Location>;
  /** fetch aggregated fields from the table: "job_location" */
  job_location_aggregate: Job_Location_Aggregate;
  /** fetch data from the table: "job_location" using primary key columns */
  job_location_by_pk?: Maybe<Job_Location>;
  /** fetch data from the table: "job_offer" */
  job_offer: Array<Job_Offer>;
  /** fetch aggregated fields from the table: "job_offer" */
  job_offer_aggregate: Job_Offer_Aggregate;
  /** fetch data from the table: "job_offer" using primary key columns */
  job_offer_by_pk?: Maybe<Job_Offer>;
  /** fetch data from the table: "job_offer_view" */
  job_offer_view: Array<Job_Offer_View>;
  /** fetch aggregated fields from the table: "job_offer_view" */
  job_offer_view_aggregate: Job_Offer_View_Aggregate;
  /** fetch data from the table: "job_rating" */
  job_rating: Array<Job_Rating>;
  /** fetch aggregated fields from the table: "job_rating" */
  job_rating_aggregate: Job_Rating_Aggregate;
  /** fetch data from the table: "job_rating" using primary key columns */
  job_rating_by_pk?: Maybe<Job_Rating>;
  /** fetch data from the table: "job_rating_counts" */
  job_rating_counts: Array<Job_Rating_Counts>;
  /** fetch aggregated fields from the table: "job_rating_counts" */
  job_rating_counts_aggregate: Job_Rating_Counts_Aggregate;
  /** fetch data from the table: "job_rating_reason" */
  job_rating_reason: Array<Job_Rating_Reason>;
  /** fetch aggregated fields from the table: "job_rating_reason" */
  job_rating_reason_aggregate: Job_Rating_Reason_Aggregate;
  /** fetch data from the table: "job_rating_reason" using primary key columns */
  job_rating_reason_by_pk?: Maybe<Job_Rating_Reason>;
  /** fetch data from the table: "job_relation_states" */
  job_relation_states: Array<Job_Relation_States>;
  /** fetch aggregated fields from the table: "job_relation_states" */
  job_relation_states_aggregate: Job_Relation_States_Aggregate;
  /** fetch data from the table: "job_type" */
  job_type: Array<Job_Type>;
  /** fetch aggregated fields from the table: "job_type" */
  job_type_aggregate: Job_Type_Aggregate;
  /** fetch data from the table: "job_type" using primary key columns */
  job_type_by_pk?: Maybe<Job_Type>;
  /** fetch data from the table: "location_name" */
  location_name: Array<Location_Name>;
  /** fetch aggregated fields from the table: "location_name" */
  location_name_aggregate: Location_Name_Aggregate;
  /** fetch data from the table: "location_name" using primary key columns */
  location_name_by_pk?: Maybe<Location_Name>;
  /** fetch data from the table: "offered_job" */
  offered_job: Array<Offered_Job>;
  /** fetch aggregated fields from the table: "offered_job" */
  offered_job_aggregate: Offered_Job_Aggregate;
  /** fetch data from the table: "offered_job" using primary key columns */
  offered_job_by_pk?: Maybe<Offered_Job>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** perform the action: "refreshEmployerActiveCounts" */
  refreshEmployerActiveCounts?: Maybe<SampleActionOutput>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** perform the action: "sendNotification" */
  sendNotification?: Maybe<SampleActionOutput>;
  /** fetch data from the table: "user_file" */
  user_file: Array<User_File>;
  /** fetch aggregated fields from the table: "user_file" */
  user_file_aggregate: User_File_Aggregate;
  /** fetch data from the table: "user_file" using primary key columns */
  user_file_by_pk?: Maybe<User_File>;
  /** fetch data from the table: "user_job_relation" */
  user_job_relation: Array<User_Job_Relation>;
  /** fetch aggregated fields from the table: "user_job_relation" */
  user_job_relation_aggregate: User_Job_Relation_Aggregate;
  /** fetch data from the table: "user_job_relation" using primary key columns */
  user_job_relation_by_pk?: Maybe<User_Job_Relation>;
  /** fetch data from the table: "user_job_relation_state" */
  user_job_relation_state: Array<User_Job_Relation_State>;
  /** fetch aggregated fields from the table: "user_job_relation_state" */
  user_job_relation_state_aggregate: User_Job_Relation_State_Aggregate;
  /** fetch data from the table: "user_job_relation_state" using primary key columns */
  user_job_relation_state_by_pk?: Maybe<User_Job_Relation_State>;
};


/** subscription root */
export type Subscription_RootCvsArgs = {
  distinct_on?: Maybe<Array<Cvs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cvs_Order_By>>;
  where?: Maybe<Cvs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCvs_AggregateArgs = {
  distinct_on?: Maybe<Array<Cvs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cvs_Order_By>>;
  where?: Maybe<Cvs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEducation_LevelArgs = {
  distinct_on?: Maybe<Array<Education_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Education_Level_Order_By>>;
  where?: Maybe<Education_Level_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEducation_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Education_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Education_Level_Order_By>>;
  where?: Maybe<Education_Level_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEducation_Level_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmployerArgs = {
  distinct_on?: Maybe<Array<Employer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Order_By>>;
  where?: Maybe<Employer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmployer_Active_CountsArgs = {
  distinct_on?: Maybe<Array<Employer_Active_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Active_Counts_Order_By>>;
  where?: Maybe<Employer_Active_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmployer_Active_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Employer_Active_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Active_Counts_Order_By>>;
  where?: Maybe<Employer_Active_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmployer_AggregateArgs = {
  distinct_on?: Maybe<Array<Employer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employer_Order_By>>;
  where?: Maybe<Employer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmployer_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootError_LogsArgs = {
  distinct_on?: Maybe<Array<Error_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Error_Logs_Order_By>>;
  where?: Maybe<Error_Logs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootError_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Error_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Error_Logs_Order_By>>;
  where?: Maybe<Error_Logs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootError_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootFcm_TokenArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFcm_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFcm_Token_By_PkArgs = {
  fcm_token_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootJob_FieldArgs = {
  distinct_on?: Maybe<Array<Job_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Field_Order_By>>;
  where?: Maybe<Job_Field_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Field_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Field_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Field_Order_By>>;
  where?: Maybe<Job_Field_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Field_By_PkArgs = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootJob_LocationArgs = {
  distinct_on?: Maybe<Array<Job_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Location_Order_By>>;
  where?: Maybe<Job_Location_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Location_Order_By>>;
  where?: Maybe<Job_Location_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Location_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootJob_OfferArgs = {
  distinct_on?: Maybe<Array<Job_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_Order_By>>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_Order_By>>;
  where?: Maybe<Job_Offer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Offer_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootJob_Offer_ViewArgs = {
  distinct_on?: Maybe<Array<Job_Offer_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_View_Order_By>>;
  where?: Maybe<Job_Offer_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Offer_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Offer_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Offer_View_Order_By>>;
  where?: Maybe<Job_Offer_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_RatingArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Order_By>>;
  where?: Maybe<Job_Rating_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Rating_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootJob_Rating_CountsArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Counts_Order_By>>;
  where?: Maybe<Job_Rating_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Rating_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Counts_Order_By>>;
  where?: Maybe<Job_Rating_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Rating_ReasonArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Reason_Order_By>>;
  where?: Maybe<Job_Rating_Reason_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Rating_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Rating_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Rating_Reason_Order_By>>;
  where?: Maybe<Job_Rating_Reason_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Rating_Reason_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootJob_Relation_StatesArgs = {
  distinct_on?: Maybe<Array<Job_Relation_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Relation_States_Order_By>>;
  where?: Maybe<Job_Relation_States_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Relation_States_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Relation_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Relation_States_Order_By>>;
  where?: Maybe<Job_Relation_States_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_TypeArgs = {
  distinct_on?: Maybe<Array<Job_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Type_Order_By>>;
  where?: Maybe<Job_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Type_Order_By>>;
  where?: Maybe<Job_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootJob_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLocation_NameArgs = {
  distinct_on?: Maybe<Array<Location_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Name_Order_By>>;
  where?: Maybe<Location_Name_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLocation_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Name_Order_By>>;
  where?: Maybe<Location_Name_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLocation_Name_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootOffered_JobArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOffered_Job_AggregateArgs = {
  distinct_on?: Maybe<Array<Offered_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offered_Job_Order_By>>;
  where?: Maybe<Offered_Job_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOffered_Job_By_PkArgs = {
  jobID: Scalars['Int'];
  offerID: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRoles_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSendNotificationArgs = {
  notification_body: Scalars['String'];
  notification_title: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUser_FileArgs = {
  distinct_on?: Maybe<Array<User_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_File_Order_By>>;
  where?: Maybe<User_File_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_File_AggregateArgs = {
  distinct_on?: Maybe<Array<User_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_File_Order_By>>;
  where?: Maybe<User_File_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUser_Job_RelationArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Job_Relation_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_Order_By>>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Job_Relation_By_PkArgs = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUser_Job_Relation_StateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_State_Order_By>>;
  where?: Maybe<User_Job_Relation_State_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Job_Relation_State_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Job_Relation_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Job_Relation_State_Order_By>>;
  where?: Maybe<User_Job_Relation_State_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Job_Relation_State_By_PkArgs = {
  value: Scalars['String'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type TokenOutput = {
  __typename?: 'tokenOutput';
  accessToken: Scalars['String'];
};

/** columns and relationships of "user_file" */
export type User_File = {
  __typename?: 'user_file';
  created_at: Scalars['timestamptz'];
  file_type: Scalars['String'];
  file_uri: Scalars['String'];
  id: Scalars['Int'];
  original_file_name: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "user_file" */
export type User_File_Aggregate = {
  __typename?: 'user_file_aggregate';
  aggregate?: Maybe<User_File_Aggregate_Fields>;
  nodes: Array<User_File>;
};

/** aggregate fields of "user_file" */
export type User_File_Aggregate_Fields = {
  __typename?: 'user_file_aggregate_fields';
  avg?: Maybe<User_File_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_File_Max_Fields>;
  min?: Maybe<User_File_Min_Fields>;
  stddev?: Maybe<User_File_Stddev_Fields>;
  stddev_pop?: Maybe<User_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_File_Stddev_Samp_Fields>;
  sum?: Maybe<User_File_Sum_Fields>;
  var_pop?: Maybe<User_File_Var_Pop_Fields>;
  var_samp?: Maybe<User_File_Var_Samp_Fields>;
  variance?: Maybe<User_File_Variance_Fields>;
};


/** aggregate fields of "user_file" */
export type User_File_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_File_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_file" */
export type User_File_Aggregate_Order_By = {
  avg?: Maybe<User_File_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_File_Max_Order_By>;
  min?: Maybe<User_File_Min_Order_By>;
  stddev?: Maybe<User_File_Stddev_Order_By>;
  stddev_pop?: Maybe<User_File_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_File_Stddev_Samp_Order_By>;
  sum?: Maybe<User_File_Sum_Order_By>;
  var_pop?: Maybe<User_File_Var_Pop_Order_By>;
  var_samp?: Maybe<User_File_Var_Samp_Order_By>;
  variance?: Maybe<User_File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_file" */
export type User_File_Arr_Rel_Insert_Input = {
  data: Array<User_File_Insert_Input>;
  on_conflict?: Maybe<User_File_On_Conflict>;
};

/** aggregate avg on columns */
export type User_File_Avg_Fields = {
  __typename?: 'user_file_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_file" */
export type User_File_Avg_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_file". All fields are combined with a logical 'AND'. */
export type User_File_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_File_Bool_Exp>>>;
  _not?: Maybe<User_File_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_File_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_type?: Maybe<String_Comparison_Exp>;
  file_uri?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  original_file_name?: Maybe<String_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profileID?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_file" */
export enum User_File_Constraint {
  /** unique or primary key constraint */
  UserFilePkey = 'user_file_pkey'
}

/** input type for incrementing integer column in table "user_file" */
export type User_File_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_file" */
export type User_File_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Scalars['String']>;
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_File_Max_Fields = {
  __typename?: 'user_file_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Scalars['String']>;
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_file" */
export type User_File_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  file_type?: Maybe<Order_By>;
  file_uri?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_file_name?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_File_Min_Fields = {
  __typename?: 'user_file_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Scalars['String']>;
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_file" */
export type User_File_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  file_type?: Maybe<Order_By>;
  file_uri?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_file_name?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_file" */
export type User_File_Mutation_Response = {
  __typename?: 'user_file_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_File>;
};

/** input type for inserting object relation for remote table "user_file" */
export type User_File_Obj_Rel_Insert_Input = {
  data: User_File_Insert_Input;
  on_conflict?: Maybe<User_File_On_Conflict>;
};

/** on conflict condition type for table "user_file" */
export type User_File_On_Conflict = {
  constraint: User_File_Constraint;
  update_columns: Array<User_File_Update_Column>;
  where?: Maybe<User_File_Bool_Exp>;
};

/** ordering options when selecting data from "user_file" */
export type User_File_Order_By = {
  created_at?: Maybe<Order_By>;
  file_type?: Maybe<Order_By>;
  file_uri?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_file_name?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profileID?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_file" */
export type User_File_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_file" */
export enum User_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  FileUri = 'file_uri',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalFileName = 'original_file_name',
  /** column name */
  ProfileId = 'profileID'
}

/** input type for updating data in table "user_file" */
export type User_File_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Scalars['String']>;
  file_uri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  original_file_name?: Maybe<Scalars['String']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_File_Stddev_Fields = {
  __typename?: 'user_file_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_file" */
export type User_File_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_File_Stddev_Pop_Fields = {
  __typename?: 'user_file_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_file" */
export type User_File_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_File_Stddev_Samp_Fields = {
  __typename?: 'user_file_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_file" */
export type User_File_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_File_Sum_Fields = {
  __typename?: 'user_file_sum_fields';
  id?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_file" */
export type User_File_Sum_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** update columns of table "user_file" */
export enum User_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  FileUri = 'file_uri',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalFileName = 'original_file_name',
  /** column name */
  ProfileId = 'profileID'
}

/** aggregate var_pop on columns */
export type User_File_Var_Pop_Fields = {
  __typename?: 'user_file_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_file" */
export type User_File_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_File_Var_Samp_Fields = {
  __typename?: 'user_file_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_file" */
export type User_File_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_File_Variance_Fields = {
  __typename?: 'user_file_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_file" */
export type User_File_Variance_Order_By = {
  id?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** columns and relationships of "user_job_relation" */
export type User_Job_Relation = {
  __typename?: 'user_job_relation';
  /** An object relationship */
  JobOffer?: Maybe<Job_Offer_View>;
  /** An object relationship */
  Profile: Profile;
  created_at?: Maybe<Scalars['timestamptz']>;
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
  state?: Maybe<User_Job_Relation_State_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "user_job_relation" */
export type User_Job_Relation_Aggregate = {
  __typename?: 'user_job_relation_aggregate';
  aggregate?: Maybe<User_Job_Relation_Aggregate_Fields>;
  nodes: Array<User_Job_Relation>;
};

/** aggregate fields of "user_job_relation" */
export type User_Job_Relation_Aggregate_Fields = {
  __typename?: 'user_job_relation_aggregate_fields';
  avg?: Maybe<User_Job_Relation_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Job_Relation_Max_Fields>;
  min?: Maybe<User_Job_Relation_Min_Fields>;
  stddev?: Maybe<User_Job_Relation_Stddev_Fields>;
  stddev_pop?: Maybe<User_Job_Relation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Job_Relation_Stddev_Samp_Fields>;
  sum?: Maybe<User_Job_Relation_Sum_Fields>;
  var_pop?: Maybe<User_Job_Relation_Var_Pop_Fields>;
  var_samp?: Maybe<User_Job_Relation_Var_Samp_Fields>;
  variance?: Maybe<User_Job_Relation_Variance_Fields>;
};


/** aggregate fields of "user_job_relation" */
export type User_Job_Relation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Job_Relation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_job_relation" */
export type User_Job_Relation_Aggregate_Order_By = {
  avg?: Maybe<User_Job_Relation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Job_Relation_Max_Order_By>;
  min?: Maybe<User_Job_Relation_Min_Order_By>;
  stddev?: Maybe<User_Job_Relation_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Job_Relation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Job_Relation_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Job_Relation_Sum_Order_By>;
  var_pop?: Maybe<User_Job_Relation_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Job_Relation_Var_Samp_Order_By>;
  variance?: Maybe<User_Job_Relation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_job_relation" */
export type User_Job_Relation_Arr_Rel_Insert_Input = {
  data: Array<User_Job_Relation_Insert_Input>;
  on_conflict?: Maybe<User_Job_Relation_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Job_Relation_Avg_Fields = {
  __typename?: 'user_job_relation_avg_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_job_relation" */
export type User_Job_Relation_Avg_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_job_relation". All fields are combined with a logical 'AND'. */
export type User_Job_Relation_Bool_Exp = {
  JobOffer?: Maybe<Job_Offer_View_Bool_Exp>;
  Profile?: Maybe<Profile_Bool_Exp>;
  _and?: Maybe<Array<Maybe<User_Job_Relation_Bool_Exp>>>;
  _not?: Maybe<User_Job_Relation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Job_Relation_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  jobID?: Maybe<Int_Comparison_Exp>;
  profileID?: Maybe<Int_Comparison_Exp>;
  state?: Maybe<User_Job_Relation_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_job_relation" */
export enum User_Job_Relation_Constraint {
  /** unique or primary key constraint */
  UserJobRelationPkey = 'user_job_relation_pkey'
}

/** input type for incrementing integer column in table "user_job_relation" */
export type User_Job_Relation_Inc_Input = {
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_job_relation" */
export type User_Job_Relation_Insert_Input = {
  Profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
  state?: Maybe<User_Job_Relation_State_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Job_Relation_Max_Fields = {
  __typename?: 'user_job_relation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_job_relation" */
export type User_Job_Relation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Job_Relation_Min_Fields = {
  __typename?: 'user_job_relation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_job_relation" */
export type User_Job_Relation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_job_relation" */
export type User_Job_Relation_Mutation_Response = {
  __typename?: 'user_job_relation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Job_Relation>;
};

/** input type for inserting object relation for remote table "user_job_relation" */
export type User_Job_Relation_Obj_Rel_Insert_Input = {
  data: User_Job_Relation_Insert_Input;
  on_conflict?: Maybe<User_Job_Relation_On_Conflict>;
};

/** on conflict condition type for table "user_job_relation" */
export type User_Job_Relation_On_Conflict = {
  constraint: User_Job_Relation_Constraint;
  update_columns: Array<User_Job_Relation_Update_Column>;
  where?: Maybe<User_Job_Relation_Bool_Exp>;
};

/** ordering options when selecting data from "user_job_relation" */
export type User_Job_Relation_Order_By = {
  JobOffer?: Maybe<Job_Offer_View_Order_By>;
  Profile?: Maybe<Profile_Order_By>;
  created_at?: Maybe<Order_By>;
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_job_relation" */
export type User_Job_Relation_Pk_Columns_Input = {
  jobID: Scalars['Int'];
  profileID: Scalars['Int'];
};

/** select columns of table "user_job_relation" */
export enum User_Job_Relation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  JobId = 'jobID',
  /** column name */
  ProfileId = 'profileID',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_job_relation" */
export type User_Job_Relation_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
  state?: Maybe<User_Job_Relation_State_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "user_job_relation_state" */
export type User_Job_Relation_State = {
  __typename?: 'user_job_relation_state';
  value: Scalars['String'];
};

/** aggregated selection of "user_job_relation_state" */
export type User_Job_Relation_State_Aggregate = {
  __typename?: 'user_job_relation_state_aggregate';
  aggregate?: Maybe<User_Job_Relation_State_Aggregate_Fields>;
  nodes: Array<User_Job_Relation_State>;
};

/** aggregate fields of "user_job_relation_state" */
export type User_Job_Relation_State_Aggregate_Fields = {
  __typename?: 'user_job_relation_state_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Job_Relation_State_Max_Fields>;
  min?: Maybe<User_Job_Relation_State_Min_Fields>;
};


/** aggregate fields of "user_job_relation_state" */
export type User_Job_Relation_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Job_Relation_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_job_relation_state" */
export type User_Job_Relation_State_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Job_Relation_State_Max_Order_By>;
  min?: Maybe<User_Job_Relation_State_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_job_relation_state" */
export type User_Job_Relation_State_Arr_Rel_Insert_Input = {
  data: Array<User_Job_Relation_State_Insert_Input>;
  on_conflict?: Maybe<User_Job_Relation_State_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_job_relation_state". All fields are combined with a logical 'AND'. */
export type User_Job_Relation_State_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Job_Relation_State_Bool_Exp>>>;
  _not?: Maybe<User_Job_Relation_State_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Job_Relation_State_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_job_relation_state" */
export enum User_Job_Relation_State_Constraint {
  /** unique or primary key constraint */
  UserJobRelationStatePkey = 'user_job_relation_state_pkey'
}

export enum User_Job_Relation_State_Enum {
  Dislike = 'DISLIKE',
  Later = 'LATER',
  Like = 'LIKE'
}

/** expression to compare columns of type user_job_relation_state_enum. All fields are combined with logical 'AND'. */
export type User_Job_Relation_State_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Job_Relation_State_Enum>;
  _in?: Maybe<Array<User_Job_Relation_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Job_Relation_State_Enum>;
  _nin?: Maybe<Array<User_Job_Relation_State_Enum>>;
};

/** input type for inserting data into table "user_job_relation_state" */
export type User_Job_Relation_State_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Job_Relation_State_Max_Fields = {
  __typename?: 'user_job_relation_state_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_job_relation_state" */
export type User_Job_Relation_State_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Job_Relation_State_Min_Fields = {
  __typename?: 'user_job_relation_state_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_job_relation_state" */
export type User_Job_Relation_State_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_job_relation_state" */
export type User_Job_Relation_State_Mutation_Response = {
  __typename?: 'user_job_relation_state_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Job_Relation_State>;
};

/** input type for inserting object relation for remote table "user_job_relation_state" */
export type User_Job_Relation_State_Obj_Rel_Insert_Input = {
  data: User_Job_Relation_State_Insert_Input;
  on_conflict?: Maybe<User_Job_Relation_State_On_Conflict>;
};

/** on conflict condition type for table "user_job_relation_state" */
export type User_Job_Relation_State_On_Conflict = {
  constraint: User_Job_Relation_State_Constraint;
  update_columns: Array<User_Job_Relation_State_Update_Column>;
  where?: Maybe<User_Job_Relation_State_Bool_Exp>;
};

/** ordering options when selecting data from "user_job_relation_state" */
export type User_Job_Relation_State_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_job_relation_state" */
export type User_Job_Relation_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_job_relation_state" */
export enum User_Job_Relation_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_job_relation_state" */
export type User_Job_Relation_State_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "user_job_relation_state" */
export enum User_Job_Relation_State_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type User_Job_Relation_Stddev_Fields = {
  __typename?: 'user_job_relation_stddev_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_job_relation" */
export type User_Job_Relation_Stddev_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Job_Relation_Stddev_Pop_Fields = {
  __typename?: 'user_job_relation_stddev_pop_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_job_relation" */
export type User_Job_Relation_Stddev_Pop_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Job_Relation_Stddev_Samp_Fields = {
  __typename?: 'user_job_relation_stddev_samp_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_job_relation" */
export type User_Job_Relation_Stddev_Samp_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Job_Relation_Sum_Fields = {
  __typename?: 'user_job_relation_sum_fields';
  jobID?: Maybe<Scalars['Int']>;
  profileID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_job_relation" */
export type User_Job_Relation_Sum_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** update columns of table "user_job_relation" */
export enum User_Job_Relation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  JobId = 'jobID',
  /** column name */
  ProfileId = 'profileID',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type User_Job_Relation_Var_Pop_Fields = {
  __typename?: 'user_job_relation_var_pop_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_job_relation" */
export type User_Job_Relation_Var_Pop_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Job_Relation_Var_Samp_Fields = {
  __typename?: 'user_job_relation_var_samp_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_job_relation" */
export type User_Job_Relation_Var_Samp_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Job_Relation_Variance_Fields = {
  __typename?: 'user_job_relation_variance_fields';
  jobID?: Maybe<Scalars['Float']>;
  profileID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_job_relation" */
export type User_Job_Relation_Variance_Order_By = {
  jobID?: Maybe<Order_By>;
  profileID?: Maybe<Order_By>;
};


export type GetJobOffersQueryVariables = Exact<{
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  dislikes?: Maybe<Order_By>;
  laters?: Maybe<Order_By>;
  likes?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Order_By>;
  like?: Maybe<Scalars['String']>;
  title?: Maybe<Order_By>;
}>;


export type GetJobOffersQuery = (
  { __typename?: 'query_root' }
  & { job_offer: Array<(
    { __typename?: 'job_offer' }
    & Pick<Job_Offer, 'id' | 'employerID' | 'education' | 'description' | 'created_at' | 'city' | 'location' | 'original_offer_url' | 'salary' | 'title'>
    & { Employer: (
      { __typename?: 'employer' }
      & Pick<Employer, 'email' | 'name' | 'phone' | 'id' | 'created_at'>
    ), JobRelationStates?: Maybe<(
      { __typename?: 'job_relation_states' }
      & Pick<Job_Relation_States, 'dislikes' | 'laters' | 'likes'>
    )> }
  )> }
);

export type GetOfferQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOfferQuery = (
  { __typename?: 'query_root' }
  & { job_offer_by_pk?: Maybe<(
    { __typename?: 'job_offer' }
    & Pick<Job_Offer, 'id' | 'description' | 'education' | 'city' | 'location' | 'salary' | 'title' | 'original_offer_url' | 'employerID' | 'created_at' | 'should_ask_for_feedback' | 'additional_job_data' | 'employer_company_data' | 'job_offer_details'>
  )> }
);

export type GetOfferUsersQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetOfferUsersQuery = (
  { __typename?: 'query_root' }
  & { user_job_relation: Array<(
    { __typename?: 'user_job_relation' }
    & Pick<User_Job_Relation, 'state'>
    & { Profile: (
      { __typename?: 'profile' }
      & Pick<Profile, 'name' | 'email' | 'surname' | 'education' | 'required_salary' | 'id'>
    ) }
  )> }
);

export type ArchiveJobOfferMutationVariables = Exact<{
  jobID: Scalars['Int'];
}>;


export type ArchiveJobOfferMutation = (
  { __typename?: 'mutation_root' }
  & { update_job_offer_by_pk?: Maybe<(
    { __typename?: 'job_offer' }
    & Pick<Job_Offer, 'id'>
  )> }
);

export type ChangeShouldAskForFeedbackMutationVariables = Exact<{
  id: Scalars['Int'];
  should_ask_for_feedback: Scalars['Boolean'];
}>;


export type ChangeShouldAskForFeedbackMutation = (
  { __typename?: 'mutation_root' }
  & { update_job_offer_by_pk?: Maybe<(
    { __typename?: 'job_offer' }
    & Pick<Job_Offer, 'id' | 'should_ask_for_feedback'>
  )> }
);

export type GetJobRatingCountsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetJobRatingCountsQuery = (
  { __typename?: 'query_root' }
  & { job_rating_counts: Array<(
    { __typename?: 'job_rating_counts' }
    & Pick<Job_Rating_Counts, 'dislikedSalary' | 'dislikedLocation' | 'dislikedJob' | 'id'>
  )> }
);

export type GetCvsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCvsQuery = (
  { __typename?: 'query_root' }
  & { user_job_relation: Array<(
    { __typename?: 'user_job_relation' }
    & { Profile: (
      { __typename?: 'profile' }
      & Pick<Profile, 'name' | 'surname' | 'id'>
      & { LatestCv?: Maybe<(
        { __typename?: 'cvs' }
        & Pick<Cvs, 'file_uri'>
      )> }
    ) }
  )> }
);

export type GetEmployerCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmployerCountsQuery = (
  { __typename?: 'query_root' }
  & { employer_active_counts: Array<(
    { __typename?: 'employer_active_counts' }
    & Pick<Employer_Active_Counts, 'active' | 'archived' | 'id'>
  )> }
);

export type DislikeReasonQueryVariables = Exact<{
  jobID?: Maybe<Scalars['Int']>;
  profileIDs?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DislikeReasonQuery = (
  { __typename?: 'query_root' }
  & { job_rating: Array<(
    { __typename?: 'job_rating' }
    & Pick<Job_Rating, 'reason' | 'profileID'>
  )> }
);

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignUpMutation = (
  { __typename?: 'mutation_root' }
  & { signUp?: Maybe<(
    { __typename?: 'authResponse' }
    & Pick<AuthResponse, 'accessToken' | 'refreshToken'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'mutation_root' }
  & { login?: Maybe<(
    { __typename?: 'authResponse' }
    & Pick<AuthResponse, 'accessToken' | 'refreshToken'>
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'mutation_root' }
  & { refreshToken?: Maybe<(
    { __typename?: 'tokenOutput' }
    & Pick<TokenOutput, 'accessToken'>
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'query_root' }
  & { profile: Array<(
    { __typename?: 'profile' }
    & Pick<Profile, 'id' | 'name' | 'email' | 'surname' | 'required_salary' | 'created_at' | 'education'>
    & { LatestCv?: Maybe<(
      { __typename?: 'cvs' }
      & Pick<Cvs, 'original_file_name'>
    )>, JobPreferences: Array<(
      { __typename?: 'job_field' }
      & { JobType: (
        { __typename?: 'job_type' }
        & Pick<Job_Type, 'job_field'>
      ) }
    )>, JobLocations: Array<(
      { __typename?: 'job_location' }
      & Pick<Job_Location, 'location'>
    )> }
  )> }
);


export const GetJobOffersDocument = gql`
    query GetJobOffers($email: order_by, $name: order_by, $phone: order_by, $dislikes: order_by, $laters: order_by, $likes: order_by, $limit: Int, $offset: Int, $created_at: order_by, $like: String, $title: order_by) {
  job_offer(
    order_by: {Employer: {email: $email, name: $name, phone: $phone}, JobRelationStates: {dislikes: $dislikes, laters: $laters, likes: $likes}, created_at: $created_at, title: $title}
    limit: $limit
    offset: $offset
    where: {hidden: {_neq: true}, _or: [{Employer: {email: {_ilike: $like}}}, {Employer: {name: {_ilike: $like}}}, {city: {_ilike: $like}}, {description: {_ilike: $like}}, {title: {_ilike: $like}}]}
  ) {
    id
    employerID
    education
    description
    created_at
    city
    location
    original_offer_url
    salary
    title
    Employer {
      email
      name
      phone
      id
      created_at
    }
    JobRelationStates {
      dislikes
      laters
      likes
    }
  }
}
    `;

/**
 * __useGetJobOffersQuery__
 *
 * To run a query within a React component, call `useGetJobOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobOffersQuery({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      dislikes: // value for 'dislikes'
 *      laters: // value for 'laters'
 *      likes: // value for 'likes'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      created_at: // value for 'created_at'
 *      like: // value for 'like'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useGetJobOffersQuery(baseOptions?: Apollo.QueryHookOptions<GetJobOffersQuery, GetJobOffersQueryVariables>) {
        return Apollo.useQuery<GetJobOffersQuery, GetJobOffersQueryVariables>(GetJobOffersDocument, baseOptions);
      }
export function useGetJobOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobOffersQuery, GetJobOffersQueryVariables>) {
          return Apollo.useLazyQuery<GetJobOffersQuery, GetJobOffersQueryVariables>(GetJobOffersDocument, baseOptions);
        }
export type GetJobOffersQueryHookResult = ReturnType<typeof useGetJobOffersQuery>;
export type GetJobOffersLazyQueryHookResult = ReturnType<typeof useGetJobOffersLazyQuery>;
export type GetJobOffersQueryResult = Apollo.QueryResult<GetJobOffersQuery, GetJobOffersQueryVariables>;
export const GetOfferDocument = gql`
    query GetOffer($id: Int!) {
  job_offer_by_pk(id: $id) {
    id
    description
    education
    city
    location
    salary
    title
    original_offer_url
    employerID
    created_at
    should_ask_for_feedback
    additional_job_data
    employer_company_data
    job_offer_details
  }
}
    `;

/**
 * __useGetOfferQuery__
 *
 * To run a query within a React component, call `useGetOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferQuery(baseOptions: Apollo.QueryHookOptions<GetOfferQuery, GetOfferQueryVariables>) {
        return Apollo.useQuery<GetOfferQuery, GetOfferQueryVariables>(GetOfferDocument, baseOptions);
      }
export function useGetOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferQuery, GetOfferQueryVariables>) {
          return Apollo.useLazyQuery<GetOfferQuery, GetOfferQueryVariables>(GetOfferDocument, baseOptions);
        }
export type GetOfferQueryHookResult = ReturnType<typeof useGetOfferQuery>;
export type GetOfferLazyQueryHookResult = ReturnType<typeof useGetOfferLazyQuery>;
export type GetOfferQueryResult = Apollo.QueryResult<GetOfferQuery, GetOfferQueryVariables>;
export const GetOfferUsersDocument = gql`
    query GetOfferUsers($jobId: Int!) {
  user_job_relation(where: {jobID: {_eq: $jobId}}) {
    Profile {
      name
      email
      surname
      education
      required_salary
      id
    }
    state
  }
}
    `;

/**
 * __useGetOfferUsersQuery__
 *
 * To run a query within a React component, call `useGetOfferUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferUsersQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetOfferUsersQuery(baseOptions: Apollo.QueryHookOptions<GetOfferUsersQuery, GetOfferUsersQueryVariables>) {
        return Apollo.useQuery<GetOfferUsersQuery, GetOfferUsersQueryVariables>(GetOfferUsersDocument, baseOptions);
      }
export function useGetOfferUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferUsersQuery, GetOfferUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetOfferUsersQuery, GetOfferUsersQueryVariables>(GetOfferUsersDocument, baseOptions);
        }
export type GetOfferUsersQueryHookResult = ReturnType<typeof useGetOfferUsersQuery>;
export type GetOfferUsersLazyQueryHookResult = ReturnType<typeof useGetOfferUsersLazyQuery>;
export type GetOfferUsersQueryResult = Apollo.QueryResult<GetOfferUsersQuery, GetOfferUsersQueryVariables>;
export const ArchiveJobOfferDocument = gql`
    mutation ArchiveJobOffer($jobID: Int!) {
  update_job_offer_by_pk(pk_columns: {id: $jobID}, _set: {hidden: true}) {
    id
  }
}
    `;
export type ArchiveJobOfferMutationFn = Apollo.MutationFunction<ArchiveJobOfferMutation, ArchiveJobOfferMutationVariables>;

/**
 * __useArchiveJobOfferMutation__
 *
 * To run a mutation, you first call `useArchiveJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveJobOfferMutation, { data, loading, error }] = useArchiveJobOfferMutation({
 *   variables: {
 *      jobID: // value for 'jobID'
 *   },
 * });
 */
export function useArchiveJobOfferMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveJobOfferMutation, ArchiveJobOfferMutationVariables>) {
        return Apollo.useMutation<ArchiveJobOfferMutation, ArchiveJobOfferMutationVariables>(ArchiveJobOfferDocument, baseOptions);
      }
export type ArchiveJobOfferMutationHookResult = ReturnType<typeof useArchiveJobOfferMutation>;
export type ArchiveJobOfferMutationResult = Apollo.MutationResult<ArchiveJobOfferMutation>;
export type ArchiveJobOfferMutationOptions = Apollo.BaseMutationOptions<ArchiveJobOfferMutation, ArchiveJobOfferMutationVariables>;
export const ChangeShouldAskForFeedbackDocument = gql`
    mutation ChangeShouldAskForFeedback($id: Int!, $should_ask_for_feedback: Boolean!) {
  update_job_offer_by_pk(
    pk_columns: {id: $id}
    _set: {should_ask_for_feedback: $should_ask_for_feedback}
  ) {
    id
    should_ask_for_feedback
  }
}
    `;
export type ChangeShouldAskForFeedbackMutationFn = Apollo.MutationFunction<ChangeShouldAskForFeedbackMutation, ChangeShouldAskForFeedbackMutationVariables>;

/**
 * __useChangeShouldAskForFeedbackMutation__
 *
 * To run a mutation, you first call `useChangeShouldAskForFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeShouldAskForFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeShouldAskForFeedbackMutation, { data, loading, error }] = useChangeShouldAskForFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      should_ask_for_feedback: // value for 'should_ask_for_feedback'
 *   },
 * });
 */
export function useChangeShouldAskForFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<ChangeShouldAskForFeedbackMutation, ChangeShouldAskForFeedbackMutationVariables>) {
        return Apollo.useMutation<ChangeShouldAskForFeedbackMutation, ChangeShouldAskForFeedbackMutationVariables>(ChangeShouldAskForFeedbackDocument, baseOptions);
      }
export type ChangeShouldAskForFeedbackMutationHookResult = ReturnType<typeof useChangeShouldAskForFeedbackMutation>;
export type ChangeShouldAskForFeedbackMutationResult = Apollo.MutationResult<ChangeShouldAskForFeedbackMutation>;
export type ChangeShouldAskForFeedbackMutationOptions = Apollo.BaseMutationOptions<ChangeShouldAskForFeedbackMutation, ChangeShouldAskForFeedbackMutationVariables>;
export const GetJobRatingCountsDocument = gql`
    query GetJobRatingCounts($id: Int!) {
  job_rating_counts(where: {id: {_eq: $id}}) {
    dislikedSalary
    dislikedLocation
    dislikedJob
    id
  }
}
    `;

/**
 * __useGetJobRatingCountsQuery__
 *
 * To run a query within a React component, call `useGetJobRatingCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobRatingCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobRatingCountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobRatingCountsQuery(baseOptions: Apollo.QueryHookOptions<GetJobRatingCountsQuery, GetJobRatingCountsQueryVariables>) {
        return Apollo.useQuery<GetJobRatingCountsQuery, GetJobRatingCountsQueryVariables>(GetJobRatingCountsDocument, baseOptions);
      }
export function useGetJobRatingCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobRatingCountsQuery, GetJobRatingCountsQueryVariables>) {
          return Apollo.useLazyQuery<GetJobRatingCountsQuery, GetJobRatingCountsQueryVariables>(GetJobRatingCountsDocument, baseOptions);
        }
export type GetJobRatingCountsQueryHookResult = ReturnType<typeof useGetJobRatingCountsQuery>;
export type GetJobRatingCountsLazyQueryHookResult = ReturnType<typeof useGetJobRatingCountsLazyQuery>;
export type GetJobRatingCountsQueryResult = Apollo.QueryResult<GetJobRatingCountsQuery, GetJobRatingCountsQueryVariables>;
export const GetCvsDocument = gql`
    query GetCvs($id: Int!) {
  user_job_relation(where: {jobID: {_eq: $id}, state: {_eq: LIKE}}) {
    Profile {
      LatestCv {
        file_uri
      }
      name
      surname
      id
    }
  }
}
    `;

/**
 * __useGetCvsQuery__
 *
 * To run a query within a React component, call `useGetCvsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCvsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCvsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCvsQuery(baseOptions: Apollo.QueryHookOptions<GetCvsQuery, GetCvsQueryVariables>) {
        return Apollo.useQuery<GetCvsQuery, GetCvsQueryVariables>(GetCvsDocument, baseOptions);
      }
export function useGetCvsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCvsQuery, GetCvsQueryVariables>) {
          return Apollo.useLazyQuery<GetCvsQuery, GetCvsQueryVariables>(GetCvsDocument, baseOptions);
        }
export type GetCvsQueryHookResult = ReturnType<typeof useGetCvsQuery>;
export type GetCvsLazyQueryHookResult = ReturnType<typeof useGetCvsLazyQuery>;
export type GetCvsQueryResult = Apollo.QueryResult<GetCvsQuery, GetCvsQueryVariables>;
export const GetEmployerCountsDocument = gql`
    query GetEmployerCounts {
  employer_active_counts {
    active
    archived
    id
  }
}
    `;

/**
 * __useGetEmployerCountsQuery__
 *
 * To run a query within a React component, call `useGetEmployerCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployerCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployerCountsQuery, GetEmployerCountsQueryVariables>) {
        return Apollo.useQuery<GetEmployerCountsQuery, GetEmployerCountsQueryVariables>(GetEmployerCountsDocument, baseOptions);
      }
export function useGetEmployerCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployerCountsQuery, GetEmployerCountsQueryVariables>) {
          return Apollo.useLazyQuery<GetEmployerCountsQuery, GetEmployerCountsQueryVariables>(GetEmployerCountsDocument, baseOptions);
        }
export type GetEmployerCountsQueryHookResult = ReturnType<typeof useGetEmployerCountsQuery>;
export type GetEmployerCountsLazyQueryHookResult = ReturnType<typeof useGetEmployerCountsLazyQuery>;
export type GetEmployerCountsQueryResult = Apollo.QueryResult<GetEmployerCountsQuery, GetEmployerCountsQueryVariables>;
export const DislikeReasonDocument = gql`
    query DislikeReason($jobID: Int, $profileIDs: [Int!] = 10) {
  job_rating(
    where: {jobID: {_eq: $jobID}, positive: {_eq: false}, profileID: {_in: $profileIDs}}
  ) {
    reason
    profileID
  }
}
    `;

/**
 * __useDislikeReasonQuery__
 *
 * To run a query within a React component, call `useDislikeReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useDislikeReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDislikeReasonQuery({
 *   variables: {
 *      jobID: // value for 'jobID'
 *      profileIDs: // value for 'profileIDs'
 *   },
 * });
 */
export function useDislikeReasonQuery(baseOptions?: Apollo.QueryHookOptions<DislikeReasonQuery, DislikeReasonQueryVariables>) {
        return Apollo.useQuery<DislikeReasonQuery, DislikeReasonQueryVariables>(DislikeReasonDocument, baseOptions);
      }
export function useDislikeReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DislikeReasonQuery, DislikeReasonQueryVariables>) {
          return Apollo.useLazyQuery<DislikeReasonQuery, DislikeReasonQueryVariables>(DislikeReasonDocument, baseOptions);
        }
export type DislikeReasonQueryHookResult = ReturnType<typeof useDislikeReasonQuery>;
export type DislikeReasonLazyQueryHookResult = ReturnType<typeof useDislikeReasonLazyQuery>;
export type DislikeReasonQueryResult = Apollo.QueryResult<DislikeReasonQuery, DislikeReasonQueryVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!) {
  signUp(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    accessToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  profile(where: {role: {_eq: USER}}) {
    id
    name
    email
    surname
    required_salary
    created_at
    LatestCv {
      original_file_name
    }
    JobPreferences {
      JobType {
        job_field
      }
    }
    JobLocations {
      location
    }
    education
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;