import React, {useContext} from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import {AuthContext} from '../Providers/AuthContextProvider';

export function PrivateRoute({ children, ...rest }: RouteProps) {
  const authContext = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() =>
        authContext?.loggedIn ? (
          children
        ) : (
          <Redirect
            to="/login/?reason=unauthorized"
          />
        )
      }
    />
  );
}
