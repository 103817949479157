import React, {useEffect, useState} from 'react';
import {Row, Col, Spinner, Table, Accordion, Button} from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import {
  useChangeShouldAskForFeedbackMutation, useGetJobRatingCountsQuery,
  useGetOfferQuery,
  useGetOfferUsersQuery,
  User_Job_Relation_State_Enum
} from '../GraphQL/GeneratedGraphQLQueries';
import {LogError} from '../Utils/Logger';
import {UserTable} from './UserTable';
import {IJobOfferDetails} from '../Constants/Types';
import {DownloadButton} from './DownloadButton';

interface IOfferDetailsParams {
  id: string
}

export const OfferDetails = ({match}: RouteComponentProps<IOfferDetailsParams>) => {
  const [currentShouldAskForFeedback, setCurrentShouldAskForFeedback] = useState<boolean>(false);
  const [changingFeedbackOption, setChangingFeedbackOption] = useState<boolean>(false);

  const {data: offerData, loading: offerLoading, error: offerError} = useGetOfferQuery({variables: {
    id: +match.params.id
  }});

  const {data: usersData, loading: usersLoading, error: usersError} = useGetOfferUsersQuery({variables: {
    jobId: +match.params.id
  }});

  const {data: countsData, loading: countsLoading, error: countsError} = useGetJobRatingCountsQuery({variables: {
    id: +match.params.id
  }});

  const [changeShouldAskForFeedback] = useChangeShouldAskForFeedbackMutation();

  useEffect(() => {
    if (offerData?.job_offer_by_pk?.should_ask_for_feedback != null){
      setCurrentShouldAskForFeedback(offerData.job_offer_by_pk.should_ask_for_feedback);
    }
  }, [offerData]);

  if (offerLoading || usersLoading || countsLoading){
    return <Spinner animation={'border'}/>;
  }

  // TODO show modal or something
  if (offerError || usersError || countsError){
    alert('Failed to fetch offer details');
    LogError(offerError);
    LogError(usersError);
    LogError(countsError);
  }

  return (
    <Row className="ms-1">
      <Col>
        <Row>
          <h1>{offerData?.job_offer_by_pk?.title}</h1>
        </Row>
        <Row className="no-print">
          <Col className="mb-2">
            <Button
              variant={offerData?.job_offer_by_pk?.should_ask_for_feedback ? 'primary' : 'outline-primary'}
              disabled={changingFeedbackOption}
              onClick={async () => {
                setChangingFeedbackOption(true);
                try {
                  await changeShouldAskForFeedback({variables: {
                    id: offerData!.job_offer_by_pk!.id,
                    should_ask_for_feedback: !currentShouldAskForFeedback
                  }});
                  setCurrentShouldAskForFeedback(!currentShouldAskForFeedback);
                } catch (e) {
                  alert('Failed to update feedback option');
                  LogError(e);
                } finally {
                  setChangingFeedbackOption(false);
                }
              }}>
              {changingFeedbackOption && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
              {offerData?.job_offer_by_pk?.should_ask_for_feedback ? 'User will be asked for feedback ✔' : 'Ask user for feedback?'}
            </Button>
          </Col>
        </Row>
        <Row className="no-print">
          <Col className="mb-2">
            <DownloadButton id={+match.params.id}/>
            <Button className="ms-3" onClick={() => window.print()}>Print offer</Button>
          </Col>
        </Row>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Details
            </Accordion.Header>
            <Accordion.Body>
              <Table responsive>
                <tbody>
                  <tr>
                    <th className="offer_table_column">Description: </th>
                    <td>{offerData?.job_offer_by_pk?.description}</td>
                  </tr>
                  <tr>
                    <th>City: </th>
                    <td>{offerData?.job_offer_by_pk?.city}</td>
                  </tr>
                  <tr>
                    <th>Education: </th>
                    <td>{offerData?.job_offer_by_pk?.education}</td>
                  </tr>
                  <tr>
                    <th>Salary: </th>
                    <td>{offerData?.job_offer_by_pk?.salary}</td>
                  </tr>
                  <tr>
                    <th>Original URL: </th>
                    <td><a href={offerData?.job_offer_by_pk?.original_offer_url}>{offerData?.job_offer_by_pk?.original_offer_url}</a></td>
                  </tr>
                  <tr>
                    <th>Additional job data: </th>
                    <td>
                      <Table>
                        <tbody>
                          {Object.entries(offerData?.job_offer_by_pk?.additional_job_data)
                            .map((entry, index) => (
                              <tr key={index}>
                                <th>{entry[0]}</th>
                                <td>{entry[1] as string}</td>
                              </tr>))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                  {offerData?.job_offer_by_pk?.job_offer_details?.map?.((entry: IJobOfferDetails, index: number) => (
                    <tr key={index}>
                      <th>{entry.title}</th>
                      <td>
                        <Table>
                          <tbody>
                            {entry.contents.map((subEntry, index: number) => (
                              <tr key={index}>
                                <th>{subEntry.subtitle}</th>
                                <td>{subEntry.info}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th>Employer company data: </th>
                    <td>
                      <Table>
                        <tbody>
                          {Object.entries(offerData?.job_offer_by_pk?.employer_company_data)
                            .map((entry, index) => (
                              <tr key={index}>
                                <th>{entry[0]}</th>
                                <td>{entry[1] as string}</td>
                              </tr>))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <UserTable header="Like" users={usersData?.user_job_relation?.filter(
          (user) => user.state === User_Job_Relation_State_Enum.Like)
          .map(a => a.Profile) ?? []}/>
        <UserTable header="Dislike" users={usersData?.user_job_relation?.filter(
          (user) => user.state === User_Job_Relation_State_Enum.Dislike)
          .map(a => a.Profile) ?? []} disliked={true} jobID={+match.params.id}/>
        <UserTable header="Later" users={usersData?.user_job_relation?.filter(
          (user) => user.state === User_Job_Relation_State_Enum.Later)
          .map(a => a.Profile) ?? []}/>
        <h2>Disliked for:</h2>
        <Table responsive>
          <tbody>
            <tr>
              <th className="offer_table_column">Salary: </th>
              <td>{countsData?.job_rating_counts[0]?.dislikedSalary ?? 0}</td>
            </tr>
            <tr>
              <th className="offer_table_column">Location: </th>
              <td>{countsData?.job_rating_counts[0]?.dislikedLocation ?? 0}</td>
            </tr>
            <tr>
              <th className="offer_table_column">Job type: </th>
              <td>{countsData?.job_rating_counts[0]?.dislikedJob ?? 0}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col xs={2} className="no-print"/>
    </Row>
  );
};
