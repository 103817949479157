import React, {useContext} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {Login} from '../AuthPages/Login';
import {PrivateRoute} from './PrivateRoute';
import {NotFound} from '../MockPages/NotFound';
import {PrivateRouter} from './PrivateRouter';
import {AuthContext} from '../Providers/AuthContextProvider';

interface IRootRouterProps {
  showSidebar: boolean;
}

export const RootRouter = (props: IRootRouterProps) => {
  const authContext = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login/>
        </Route>
        <Route path='/auth'>
          <PrivateRoute>
            <PrivateRouter showSidebar={props.showSidebar}/>
          </PrivateRoute>
        </Route>
        <Route exact path='/' render={() => {
          return (authContext?.loggedIn ? <Redirect to={'/auth/offers'}/> : <Redirect to={'/login'} />);
        }}/>
        <Route>
          <NotFound/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
