export enum order_by {
    EMAIL = 'email',
    PHONE = 'phone',
    NAME = 'name',
    LIKES = 'likes',
    DISLIKES = 'dislikes',
    LATERS = 'laters',
    CREATED_AT = 'created_at',
    TITLE = 'title',
}

export interface IJobOfferDetails {
   title: string;
   contents: [{
       info: string;
       subtitle: string;
   }]
}
