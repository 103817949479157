import React, {useContext, useState} from 'react';
import {Button, Container, Navbar} from 'react-bootstrap';
import {RootRouter} from './Components/Router/RootRouter';
import {AuthContext} from './Components/Providers/AuthContextProvider';


const App: React.FC = () => {
  const authContext = useContext(AuthContext);

  const [showSidebar, setShowSidebar] = useState<boolean>();

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md" sticky="top" role='navigation' className="no-print">
        <Navbar.Brand href="/" className={'ms-2 '}>
          <div>Sqilla WebAdmin</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShowSidebar(!showSidebar)} className={'mb-2 mt-2 me-2'}/>
        {
          authContext?.loggedIn ?
            <Navbar.Collapse>
              <div className={'d-flex justify-content-end ms-auto me-4'}>
                <Button variant="outline-danger" className={'md'} onClick={async () => {
                  try {
                    await authContext.logout();
                  } catch (e) {
                    alert('Logout failed: ' + e);
                  }
                }}>Logout</Button>
              </div>
            </Navbar.Collapse>
            :
            null
        }
      </Navbar>
      <Container fluid className={'custom_container'}>
        <RootRouter showSidebar={!!showSidebar}/>
      </Container>
    </>
  );
};

export default App;
