import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {NotFound} from '../MockPages/NotFound';
import {Col, Collapse, Nav} from 'react-bootstrap';
import {Offers} from '../Offers';
import {OfferDetails} from '../OfferDetails';
import {Users} from '../Users';

interface IPrivateRouterProps {
  showSidebar: boolean;
}

export const PrivateRouter = (props: IPrivateRouterProps) => {
  const location = useLocation();
  return (
    <div>
      <Collapse in={props.showSidebar}>
        <Col md={3} lg={2} className={'d-md-block bg-light sidebar'}>
          <div className={'position-sticky pt-3'}>
            <Nav className={'flex-column'}>
              <Nav.Item>
                <Nav.Link href='/auth/offers' active={location.pathname === '/auth/offers'}><div>Offers</div></Nav.Link>
                <Nav.Link href='/auth/users' active={location.pathname === '/auth/users'}><div>Users</div></Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Collapse>
      <Col md={9} lg={10} className={'ms-sm-auto pe-0 ps-0'}>
        <Switch>
          <Route path='/auth/offers'>
            <Route path='/auth/offers/:id' component={OfferDetails}/>
            <Route path='/auth/offers' component={Offers}>
            </Route>
            <Route exact path='/auth/'>
              <Redirect to={'/auth/offers'}/>
            </Route>
          </Route>
          <Route path='/auth/users' component={Users}/>
          <Route>
            <NotFound/>
          </Route>
        </Switch>
      </Col>
    </div>
  );
};
