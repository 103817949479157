export const RegionsMap: Map<string, string> = new Map([
  ['BANSKA_BYSTRICA', 'Banská Bystrica'],
  ['BANSKA_STIAVNICA', 'Banská Štiavnica'],
  ['BARDEJOV', 'Bardejov'],
  ['BANOVCE_NAD_BEBRAVOU', 'Bánovce nad Bebravou'],
  ['BREZNO', 'Brezno'],
  ['BRATISLAVA', 'Bratislava'],
  ['BYTCA', 'Bytča'],
  ['CADCA', 'Čadca'],
  ['DETVA', 'Detva'],
  ['DOLNY_KUBIN', 'Dolný Kubín'],
  ['DUNAJSKA_STREDA', 'Dunajská Streda'],
  ['GALANTA', 'Galanta'],
  ['GELNICA', 'Gelnica'],
  ['HLOHOVEC', 'Hlohovec'],
  ['HUMENNE', 'Humenné'],
  ['ILAVA', 'Ilava'],
  ['KEZMAROK', 'Kežmarok'],
  ['KOMARNO', 'Komárno'],
  ['KOSICE', 'Košice'],
  ['KOSICE_OKOLIE', 'Košice - okolie'],
  ['KRUPINA', 'Krupina'],
  ['KYSUCKE_NOVE_MESTO', 'Kysucké Nové Mesto'],
  ['LEVICE', 'Levice'],
  ['LEVOCA', 'Levoča'],
  ['LIPTOVSKY_MIKULAS', 'Liptovský Mikuláš'],
  ['LUCENEC', 'Lučenec'],
  ['MALACKY', 'Malacky'],
  ['MARTIN', 'Martin'],
  ['MEDZILABORCE', 'Medzilaborce'],
  ['MICHALOVCE', 'Michalovce'],
  ['MYJAVA', 'Myjava'],
  ['NAMESTOVO', 'Námestovo'],
  ['NITRA', 'Nitra'],
  ['NOVE_MESTO_NAD_VAHOM', 'Nové Mesto nad Váhom'],
  ['NOVE_ZAMKY', 'Nové Zámky'],
  ['PARTIZANSKE', 'Partizánske'],
  ['PEZINOK', 'Pezinok'],
  ['PIESTANY', 'Piešťany'],
  ['POLTAR', 'Poltár'],
  ['POPRAD', 'Poprad'],
  ['POVAZSKA_BYSTRICA', 'Považská Bystrica'],
  ['PRESOV', 'Prešov'],
  ['PRIEVIDZA', 'Prievidza'],
  ['PUCHOV', 'Púchov'],
  ['REVUCA', 'Revúca'],
  ['RIMAVSKA_SOBOTA', 'Rimavská Sobota'],
  ['ROZNAVA', 'Rožňava'],
  ['RUZOMBEROK', 'Ružomberok'],
  ['SABINOV', 'Sabinov'],
  ['SENEC', 'Senec'],
  ['SENICA', 'Senica'],
  ['SKALICA', 'Skalica'],
  ['SNINA', 'Snina'],
  ['SOBRANCE', 'Sobrance'],
  ['SPISSKA_NOVA_VES', 'Spišská Nová Ves'],
  ['STARA_LUBOVNA', 'Stará Ľubovňa'],
  ['STROPKOV', 'Stropkov'],
  ['SVIDNIK', 'Svidník'],
  ['SALA', 'Šaľa'],
  ['TOPOLCANY', 'Topoľčany'],
  ['TREBISOV', 'Trebišov'],
  ['TRENCIN', 'Trenčín'],
  ['TRNAVA', 'Trnava'],
  ['TURCIANSKE_TEPLICE', 'Turčianske Teplice'],
  ['TVRDOSIN', 'Tvrdošín'],
  ['VELKY_KRTIS', 'Veľký Krtíš'],
  ['VRANOV_NAD_TOPLOU', 'Vranov nad Topľou'],
  ['ZLATE_MORAVCE', 'Zlaté Moravce'],
  ['ZVOLEN', 'Zvolen'],
  ['ZARNOVICA', 'Žarnovica'],
  ['ZIAR_NAD_HRONOM', 'Žiar nad Hronom'],
  ['ZILINA', 'Žilina'],
]);
