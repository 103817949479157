import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Col, FormControl, Modal, Spinner, Table} from 'react-bootstrap';
import {
  Employer_Active_Counts,
  GetJobOffersDocument,
  GetJobOffersQuery, GetJobOffersQueryVariables,
  Order_By,
  useArchiveJobOfferMutation, useGetEmployerCountsQuery,
} from '../GraphQL/GeneratedGraphQLQueries';
import {LogError} from '../Utils/Logger';
import {ArrowDown, ArrowUp} from 'react-bootstrap-icons';
import {order_by} from '../Constants/Types';
import moment from 'moment';
import {useApolloClient} from '@apollo/client';
import {Link, RouteComponentProps} from 'react-router-dom';

const FETCH_LIMIT = 15;

export const Offers = ({match}: RouteComponentProps) => {
  const apolloClient = useApolloClient();
  const [offers, setOffers] = useState<GetJobOffersQuery['job_offer']>([]);
  const [loading, setLoading] = useState<boolean>();
  const [employerCounts, setEmployerCounts] = useState<Map<number, Employer_Active_Counts>>();

  const [orderBy, setOrderBy] = useState<order_by>(order_by.CREATED_AT);
  const [orderDirection, setOrderDirection] = useState<Order_By>(Order_By.Desc);
  const [search, setSearch] = useState<string>('');

  const [modalOfferID, setModalOfferId] = useState<number>();
  const [modalVisible, setModalVisible] = useState<boolean>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [fetchedAll, setFetchedAll] = useState<boolean>();

  const [archiveJobOffer, {loading: archiveLoading, error: archiveError}] = useArchiveJobOfferMutation();

  const scrollPosition = useRef<number>(0);

  const {data: employerCountsData} = useGetEmployerCountsQuery();

  const scrollRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    window.onpopstate = () => {
      scrollRef.current?.scrollTo(0, scrollPosition.current);
    };
  }, [scrollRef]);

  useEffect(() => {
    const employerMap = new Map<number, Employer_Active_Counts>();
    if(employerCountsData){
      for (const countItem of employerCountsData.employer_active_counts){
        employerMap.set(countItem.id!, countItem);
      }
      setEmployerCounts(employerMap);
    }
  }, [employerCountsData]);

  const fetchOffers = async (currentOffers: GetJobOffersQuery['job_offer'], page: number) => {
    setLoading(true);
    try{
      const variables = {
        limit: FETCH_LIMIT,
        offset: FETCH_LIMIT*page,
        like: !search ? null : '%' + search + '%'
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variables[orderBy.valueOf()] = orderDirection;
      const res = await apolloClient.query<GetJobOffersQuery, GetJobOffersQueryVariables>({
        query: GetJobOffersDocument,
        variables
      });
      if(res.error){
        LogError(res.error);
        return;
      }
      if(res.data.job_offer.length < FETCH_LIMIT){
        setFetchedAll(true);
      }else{
        setFetchedAll(false);
      }
      setOffers([...currentOffers, ...(res.data.job_offer)]);
      setCurrentPage(page + 1);
    }catch (e) {
      LogError(e);
    } finally {
      setLoading(false);
    }
  };

  const switchOrder = useCallback(
    (newOrdering: order_by, oldOrdering: order_by, oldOrderDirection: Order_By) => {
      if(newOrdering === oldOrdering && oldOrderDirection === Order_By.Asc){
        return Order_By.Desc;
      }
      return Order_By.Asc;
    }, []);

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    scrollPosition.current = e.target.scrollTop;
    if (bottom && !fetchedAll){
      fetchOffers(offers, currentPage);
    }
  };

  const archiveJob = async () => {
    try{
      await archiveJobOffer({
        variables: {
          jobID: modalOfferID ?? -1
        }});
      setOffers([...offers.filter(offer => offer.id !== modalOfferID)]);
      setModalOfferId(-1);
      setModalVisible(false);
    } catch (e) {
      LogError(e);
      LogError('GraphqlError: ', archiveError);
    }
  };

  const renderArrowIcon = useCallback((currentColumn: order_by, globalOrdering: order_by, orderDirection: Order_By) => {
    if (currentColumn === globalOrdering){
      if(orderDirection === Order_By.Asc){
        return <ArrowUp/>;
      }
      if(orderDirection === Order_By.Desc){
        return <ArrowDown/>;
      }
    }
    return null;
  }, []);

  useEffect(() => {
    fetchOffers([], 0);
  }, [orderBy, orderDirection]);

  if(!match.isExact){
    return null;
  }

  return (<div className="ms-2 scrollable_container" onScroll={handleScroll} ref={scrollRef}>
    <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
      <Modal.Header>
        <Modal.Title>Archive job offer</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to archive this offer?</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setModalVisible(false)}>Cancel</Button>
        <Button variant='danger' onClick={archiveJob}>{archiveLoading ? <Spinner animation='border'/> : <div>Archive</div> }</Button>
      </Modal.Footer>
    </Modal>
    <div className={'row my-5 custom_row'}>
      <Col>
        <form className='row custom_row' onSubmit={(e) => {
          e.preventDefault();
          fetchOffers([], 0);
        }}>
          <Col xs={9}>
            <FormControl placeholder='Search' value={search} onChange={(e) => {
              setSearch(e.target.value);
            }}/>
          </Col>
          <Col xs={3}>
            <Button type="submit" name="submit">{loading ? <Spinner animation={'border'} /> : 'Search'}</Button>
          </Col>
        </form>
      </Col>
    </div>
    <div className={'row custom_row'}>
      <div className="col-12">
        <Table>
          <thead>
            <tr>
              {
                [{
                  label: 'created at',
                  column: order_by.CREATED_AT
                },{
                  label: 'name',
                  column: order_by.TITLE
                },{
                  label: 'employer (active + archived)',
                  column: order_by.NAME
                },{
                  label: 'phone',
                  column: order_by.PHONE
                },{
                  label: 'email',
                  column: order_by.EMAIL
                },{
                  label: 'like',
                  column: order_by.LIKES
                },{
                  label: 'dislike',
                  column: order_by.DISLIKES
                },{
                  label: 'later',
                  column: order_by.LATERS
                }].map(entry => {
                  return (
                    <th key={entry.label}>
                      <a href='#' onClick={async(e) => {
                        e.preventDefault();
                        if(loading || !employerCounts){
                          return;
                        }
                        setOrderBy(entry.column);
                        setOrderDirection(switchOrder(entry.column, orderBy, orderDirection));
                      }}>{entry.label}</a>
                      {
                        renderArrowIcon(entry.column, orderBy, orderDirection)
                      }
                    </th>
                  );
                })
              }
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer, index) => {
              const employerData = !employerCounts ? null : employerCounts.get(offer.Employer.id);
              return (<tr key={'Entry: ' + index}>
                <td>{moment(offer.created_at).format('D.M.YYYY')}</td>
                <td><Link to={'/auth/offers/' + offer.id}>{offer.title}</Link></td>
                <td>
                  {offer.Employer.name + (!employerData ? '' : `(${employerData!.active} + ${employerData!.archived})`)}
                </td>
                <td>{offer.Employer.phone}</td>
                <td>{offer.Employer.email}</td>
                <td>{offer.JobRelationStates?.likes ?? 0}</td>
                <td>{offer.JobRelationStates?.dislikes ?? 0}</td>
                <td>{offer.JobRelationStates?.laters ?? 0}</td>
                <td><Button onClick={() => {
                  setModalOfferId(offer.id);
                  setModalVisible(true);
                }
                }>Archive</Button></td>
              </tr>);
            })}
          </tbody>
        </Table>
      </div>
    </div>
    <div className={'d-flex justify-content-center'}>
      {!fetchedAll
        ?
        <Button onClick={() => {
          if(loading || !employerCounts){
            return;
          }
          fetchOffers(offers, currentPage);
        }}>
          {(loading || !employerCounts)
            ?
            <Spinner animation={'border'}/>
            :
            <div>Load more</div>}
        </Button>
        :
        <div>No more offers to load</div>}
    </div>
  </div>
  );
};
